<div class="app-header header-shadow" *ngIf="authService.isLoggedIn">
  <div class="app-header__logo">
    <div class="">
      <img src="assets/images/logo.png" alt="Styli">
    </div>
    <div class="header__pane ml-auto">
      <div>
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <div>
      <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-header__menu">
    <span>
      <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
        <span class="btn-icon-wrapper">
          <em class="fa fa-ellipsis-v fa-w-6"></em>
        </span>
      </button>
    </span>
  </div>
  <div class="app-header__content">
    <div class="app-header-right">
      <div class="header-btn-lg pr-0">
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading"> {{this.apiService.pods}} </div>
            </div>
            <div class="widget-content-left">
              <img width="42" src="{{this.apiService.flag}}" alt="{{user.email}}">
            </div>
            <div class="widget-content-left ml-3">
              <div class="btn-group">
                <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn">
                  <img width="42" class="rounded-circle"
                    src="{{user?.photoURL ? user?.photoURL : 'assets/images/avatars/user.png'}}" alt="{{user.email}}">
                  <em class="fa fa-angle-down ml-2 opacity-8" style="color: black;"></em>
                </a>
                <div tabindex="-1" role="menu" aria-hidden="true"
                  class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                  <div class="dropdown-menu-header">
                    <div class="dropdown-menu-header-inner">
                      <a class="nav-link" (click)="authService.SignOut()">Logout</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-content-left  ml-3 header-user-info">
              <div class="widget-heading"> {{user.displayName || user?.name}} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="app-main" *ngIf="authService.isLoggedIn">
  <div class="app-sidebar sidebar-shadow">
    <div class="app-header__logo">
      <div class="logo-src">
        <img src="assets/images/logo-inverse.png" alt="Logo" width="70px" height="22px" />
      </div>
      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
          <span class="btn-icon-wrapper">
            <em class="fa fa-ellipsis-v fa-w-6"></em>
          </span>
        </button>
      </span>
    </div>
    <div class="scrollbar-sidebar ps ps--active-y">
      <div class="app-sidebar__inner">
        <ul class="vertical-nav-menu metismenu">
          <li class="app-sidebar__heading">Dashboard</li>
          <li>
            <a href="javascript:void(0);" [routerLink]="['dashboard']" [routerLinkActive]="['mm-active']">
              <em class="metismenu-icon pe-7s-rocket"></em>
              Analytics
            </a>
          </li>
          <li class="app-sidebar__heading">Promotion</li>
          <li>
            <a href="#" aria-expanded="true">
              <em class="metismenu-icon pe-7s-gift"></em>
              Coupon
              <em class="metismenu-state-icon pe-7s-angle-down caret-left"></em>
            </a>
            <ul class="mm-collapse mm-show">
              <li *ngFor="let menu of menus">
                <a href="javascript:void(0);" *ngIf="menu.url !=''" [routerLink]="[menu.url]"
                  [routerLinkActive]="['mm-active']"><em class="metismenu-icon"></em>&nbsp;{{menu.title}}</a>
                <a href="javascript:void(0);" *ngIf="menu.url ==''"><em
                    class="metismenu-icon"></em>&nbsp;{{menu.title}}</a>
              </li>
              <li>
                <a href="{{apiService.remoteConfigUrl}}" target="_blank" rel="noopener"><em class="metismenu-icon"></em>&nbsp; Edit PDP
                  Coupon</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <em class="metismenu-icon pe-7s-settings"></em>
              Settings
              <em class="metismenu-state-icon pe-7s-angle-down caret-left"></em>
            </a>
            <ul class="mm-collapse mm-show">
              <li>
                <a href="javascript:void(0);" [routerLink]="['settings']" [routerLinkActive]="['mm-active']"><em
                    class="metismenu-icon"></em>&nbsp; Error Message</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <em class="metismenu-icon pe-7s-clock"></em>
              Logs
              <em class="metismenu-state-icon pe-7s-angle-down caret-left"></em>
            </a>
            <ul class="mm-collapse mm-show">
              <li>
                <a href="javascript:void(0);" [routerLink]="['activity/log']" [routerLinkActive]="['mm-active']"><em
                    class="metismenu-icon"></em>&nbsp; Activity Logs</a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="['activity/userlog']" [routerLinkActive]="['mm-active']"><em
                    class="metismenu-icon"></em>&nbsp; Activity Logs(Approvers)</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" [routerLink]="['influencer']" [routerLinkActive]="['mm-active']">
              <em class="metismenu-icon pe-7s-id"></em>
              Influencer Options
            </a>
          </li>
          <li>
            <a href="#">
              <em class="metismenu-icon pe-7s-users"></em>
              Users
              <em class="metismenu-state-icon pe-7s-angle-down caret-left"></em>
            </a>
            <ul class="mm-collapse mm-show">
              <li>
                <a href="javascript:void(0);" [routerLink]="['users']" [routerLinkActive]="['mm-active']"><em
                    class="metismenu-icon"></em>&nbsp; Approver List</a>
              </li>
            </ul>
          </li>
          <li class="app-sidebar__heading">Admin Config</li>
          <li>
            <a href="javascript:void(0);" [routerLink]="['adminconfig']" [routerLinkActive]="['mm-active']">
              <em class="metismenu-icon pe-7s-id"></em>
              Admin Config
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
  <div class="app-main__outer">
    <router-outlet></router-outlet>
    <!--div class="app-wrapper-footer">
      <div class="app-footer">
        <div class="app-footer__inner">
          <div class="app-footer-left">
            &copy; Styli Promo V 1.0
          </div>

        </div>
      </div>
    </div-->
  </div>

</div>
<div *ngIf="!authService.isLoggedIn">
  <router-outlet></router-outlet>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>