import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css'],
})
export class ConfigComponent implements OnInit {
  master = 'Master';
  public settingsForm: any[] = [];
  processed = false;
  idToken = '';
  multiple_auto_applied = 0;
  message_config: any = {
    auto: { en: '', ar: '', status: true },
    manual: { en: '', ar: '', status: true },
  };
  global_condition_for_coupon: any = {};
  public couponIds = [];
  public coupon_conditions:any = [];
  public couponList = [];
  public selectedItems = [];
  public dropdownSettings = {};
  public selectedIds = [];
  public excludeManualCoupons:any = [];
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}

  async ngOnInit() {
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    this.apiService.getAllCouponData(this.idToken).subscribe((data) => {
      if (data.code == 200) {
        this.couponList = data.data;
      }
    });
    this.apiService.getAllConfigData(this.idToken).subscribe((data) => {
      if (data.code == 200) {
        for (let configdata of data.data) {
          this[configdata.config_key] = configdata.config_value;
        }
      }
      if(Object.keys(this.global_condition_for_coupon).length > 0){
        const {condition, selected_items, exclude_auto_coupons, exclude_manual_coupons} = this.global_condition_for_coupon;
        this.coupon_conditions = condition;
        this.selectedItems = selected_items;
        this.excludeManualCoupons = exclude_manual_coupons;
        this.selectedIds = exclude_auto_coupons;
      }
      this.loader.hide();
    });
   this.dropdownSettings = {
      singleSelection: false,
      text:"Select Auto Coupons",
      enableSearchFilter: true,
      enableFilterSelectAll: false,
      classes:"myclass custom-class"
    };
  }

  async changeValue(value: any) {
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    this.apiService
      .updateConfigData('multiple_auto_applied', value, this.idToken)
      .subscribe((data) => {
        if (data.code == 200) {
          this.toastrService.success('Updated Successfully!!');
        } else {
          this.toastrService.error('Something went wrong!!');
        }
        this.loader.hide();
      },
      (error) => {
          this.toastrService.error(error);
          this.loader.hide();
          // this.toastrService.error(data.message);
      });
  }
  async saveCouponTypeNameConfig() {
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    this.apiService
      .updateConfigData('message_config', this.message_config, this.idToken)
      .subscribe((data) => {
        if (data.code == 200) {
          this.toastrService.success('Updated Successfully!!');
        } else {
          this.toastrService.error('Something went wrong!!');
        }
        this.loader.hide();
      },
      (error) => {
          this.toastrService.error(error);
          this.loader.hide();
          // this.toastrService.error(data.message);
      });
  }
  async saveGlobalConfig(){
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    const configData = {
      condition: this.coupon_conditions,
      exclude_auto_coupons: this.selectedIds,
      exclude_manual_coupons: this.excludeManualCoupons,
      selected_items: this.selectedItems
    }
    this.apiService
      .updateConfigData('global_condition_for_coupon', configData, this.idToken)
      .subscribe((data) => {
        if (data.code == 200) {
          this.toastrService.success('Updated Successfully!!');
        } else {
          this.toastrService.error('Something went wrong!!');
        }
        this.loader.hide();
    },
    (error) => {
        this.toastrService.error(error);
        this.loader.hide();
        // this.toastrService.error(data.message);
    });
  }
  getConditions(cond:any){
    this.coupon_conditions = cond;
  }
  onItemSelect(item:any){
    console.log(item);
    this.getCouponIds();
  }
  OnItemDeSelect(item:any){
    console.log(item);
    this.getCouponIds();
  }
  onSelectAll(items: any){
    console.log(items);
    this.getCouponIds();
  }
  onDeSelectAll(items: any){
    console.log(items);
    this.getCouponIds();
  }
  getCouponIds(){
    if(this.selectedItems.length > 0){
      this.selectedIds = this.selectedItems.map(value => value.id);
      //console.log("selected Ids", this.selectedIds);
    }else{
      this.selectedIds = [];
    }
  }
}
