import { Injectable,NgZone, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Coupon } from './model/coupon';
import { Redeem } from './model/redeem';
import { User } from './model/user';
import { Influencer } from './model/influencer';
import { throwError, Observable, interval } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { AdminConfig } from './model/adminConfig';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiNjServer;
  public remoteConfigUrl;
  constructor(
    @Inject('environment')
    environment,
    private httpClient: HttpClient,
    public authService: AuthService,
    public ngZone: NgZone,
    private toastrService: ToastrService,
    private loader: NgxSpinnerService
  ) {
    this.apiNjServer = environment.production
      ? this.apiServers(environment.apiNjServer)
      : environment.apiNjServer;
    this.remoteConfigUrl = environment.remoteConfig;
    interval(5000 * 60).subscribe((_x) => {
      this.authService.GetToken();
    });
  }

  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'Approvals',
      url: 'approvals',
      icon: 'pe-7s-home',
      active: false,
      type: 'dropdown',
      badge: {
        text: 'New ',
        class: 'badge-warning',
      },
      submenus: [],
    },
    {
      title: 'Coupon List',
      url: 'coupon',
      icon: 'pe-7s-home',
      active: false,
      type: 'dropdown',
      badge: {
        text: 'New ',
        class: 'badge-warning',
      },
      submenus: [],
    },
    {
      title: 'Redeem History',
      url: 'redeem/history',
      icon: 'pe-7s-folder',
      active: false,
      type: 'dropdown',
      badge: {
        text: '3',
        class: 'badge-danger',
      },
      submenus: [],
    },
    {
      title: 'Bulk Coupon Update',
      url: 'bulk/coupon/update',
      icon: 'pe-7s-upload',
      active: false,
      type: 'dropdown',
      badge: {
        text: 'New ',
        class: 'badge-warning',
      },
      submenus: [],
    },
    {
      title: 'Coupon Config',
      url: 'config',
      icon: 'pe-7s-upload',
      active: false,
      type: 'dropdown',
      badge: {
        text: 'New ',
        class: 'badge-warning',
      },
      submenus: [],
    },
    {
      title: 'Bulk Coupon Status',
      url: 'bulk-coupon',
      icon: 'pe-7s-upload',
      active: false,
      type: 'dropdown',
      badge: {
        text: 'New ',
        class: 'badge-warning',
      },
      submenus: [],
    },
  ];
  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }
  getApprovalAccess(): Observable<Coupon> {
    const token = '';
    const postdata = {};
    postdata['user_email'] = this.userData?.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v1/approver', postdata, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  get userData() {
    return this.authService.getJsonCookie('styli_sso_user');
  }
  get couponRuleType() {
    return { 0: 'Bag Value', 1: 'Attribute Based', 2: 'Bank Offer' };
  }
  getWebsiteData(): Observable<Coupon> {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v4/getWebsites')
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  get websiteList() {
    return { 1: 'Main Website', 3: 'Styli - UAE', 4: 'Styli - Kuwait' };
  }
  get websites() {
    return [
      { id: 1, title: 'Main Website' },
      { id: 3, title: 'Styli - UAE' },
      { id: 4, title: 'Styli - Kuwait' },
    ];
  }
  get storeList() {
    return [
      {
        id: 1,
        website_id: 1,
        name: 'KSA English Store',
        language: 'English',
        langcode: 'en',
      },
      {
        id: 3,
        website_id: 1,
        name: 'KSA Arabic Store',
        language: 'Arabic',
        langcode: 'ar',
      },
      {
        id: 7,
        website_id: 3,
        name: 'UAE English Store',
        language: 'English',
        langcode: 'en',
      },
      {
        id: 11,
        website_id: 3,
        name: 'UAE Arabic Store',
        language: 'Arabic',
        langcode: 'ar',
      },
      {
        id: 12,
        website_id: 4,
        name: 'Kuwait English Store',
        language: 'English',
        langcode: 'en',
      },
      {
        id: 13,
        website_id: 4,
        name: 'Kuwait Arabic Store',
        language: 'Arabic',
        langcode: 'ar',
      },
    ];
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }
  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
  getAccessToken() {
    let user = this.authService.getJsonCookie('styli_sso_user');
    let token = user?.token;
    return token ? this.encodeString(token) : '';
  }
  encodeString(s) {
    return s.replace(/[A-Za-z]/g, function (c) {
      return String.fromCharCode(
        c.charCodeAt(0) + (c.toUpperCase() <= 'M' ? 13 : -13)
      );
    });
  }
  public reqHeader(token = '') {
    const user = this.authService.getJsonCookie('styli_sso_user');
    token = user?.token || token;
    let idToken =
      token.trim() !== '' ? this.encodeString(token) : this.getAccessToken();
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    });
    return header;
  }

  getDate(value: any) {
    let s: any = value.toString().replace(/\s/g, 'T') + '.000Z';
    let a: any = s.split(/[^0-9]/);
    let d: any = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    return d;
  }
  createCoupon(coupon, token?): Observable<Coupon> {
    coupon['user_email'] = this.userData.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/coupon/create', coupon, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getCouponById(coupon_id, token?): Observable<Coupon> {
    const couponDetail = { couponId: coupon_id };
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/coupon', couponDetail, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getNoOfCouponCanCreate(couponData = {}, token?): Observable<Coupon> {
    return this.httpClient
      .post<Coupon>(
        this.apiNjServer + '/v4/coupon/getPossibleNumberOfCombination',
        couponData,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAllCoupons(
    filtersReqData = {},
    filters = [],
    token = ''
  ): Observable<Coupon[]> {
    let cond = '';
    if (filters) {
      for (let key in filters) {
        let value = filters[key];
        if ((!Number.isInteger(value) && value.trim() != '') || value != '') {
          cond += '&' + key + '=' + value;
        }
      }
    }
    return this.httpClient
      .post<Coupon[]>(
        this.apiNjServer + '/v4/allCoupon?show_all=1' + cond,
        filtersReqData,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAllApproveCoupons(
    filtersReqData = {},
    filters = [],
    token = ''
  ): Observable<Coupon[]> {
    const postData = {};
    postData['user_email'] = this.userData.email;
    let cond = '';
    if (filters) {
      for (let key in filters) {
        let value = filters[key];
        if ((!Number.isInteger(value) && value.trim() != '') || value != '') {
          cond += '&' + key + '=' + value;
        }
      }
    }
    postData['filtersReqData'] = filtersReqData;
    return this.httpClient
      .post<Coupon[]>(
        this.apiNjServer + '/v4/allApproveCoupon?show_all=1' + cond,
        postData,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }

  updateCoupon(coupon_id, coupon, token?): Observable<Coupon> {
    coupon['user_email'] = this.userData.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/coupon/' + coupon_id, coupon, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAllRedeemData(
    reqData = {},
    filters = [],
    token = ''
  ): Observable<Redeem[]> {
    let cond = '';
    let data = reqData;
    if (filters) {
      let i = 0;
      for (let key in filters) {
        let value = filters[key];
        if (value != '') {
          let conjuction = i == 0 ? '?' : '&';
          if (key == 'page' || value.trim() != '') {
            cond += conjuction + key + '=' + value;
          }
          i++;
        }
      }
    }
    let apiUrl = this.apiNjServer + '/v4/coupon/redeem/history' + cond;
    return this.httpClient
      .post<Redeem[]>(apiUrl, data, { headers: this.reqHeader(token) })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getCouponCodes(token?): Observable<Coupon[]> {
    return this.httpClient
      .get<Coupon[]>(this.apiNjServer + '/v1/get-coupon-codes', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getRedeemedUsers(token?): Observable<Redeem[]> {
    return this.httpClient
      .get<Redeem[]>(this.apiNjServer + '/v1/get-users', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  deleteCoupon(coupon_id, token?) {
    return this.httpClient
      .delete<Coupon>(this.apiNjServer + '/v4/remove/coupon/' + coupon_id, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getProductCategoryList(token?): Observable<Coupon[]> {
    return this.httpClient
      .get<Coupon[]>(this.apiNjServer + '/v4/getProductCategoryList', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getDashboardData(token?): Observable<Coupon> {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v4/dashboard', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getHttpMessage(token?): Observable<Coupon> {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v4/http-messages', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getHttpMessageOfCode(code, token?): Observable<Coupon> {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v1/http-message/' + code, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getConfigData(config_key, token?): Observable<Coupon> {
    let data = { config_key: config_key };
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/getConfig', data, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAllConfigData(token?): Observable<Coupon> {
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/getConfig', '', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAllCouponData(token?): Observable<Coupon> {
    const data = { couponType: 1 };
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/search/coupon', data, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  updateConfigData(config_key, config_value, token?): Observable<Coupon> {
    let data = { config_key: config_key, config_value: config_value };
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/promo/global/config', data, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getActivityLogs(
    filtersReqData = {},
    filters = [],
    token?
  ): Observable<Coupon[]> {
    let cond = '';
    if (filters) {
      let i = 0;
      for (let key in filters) {
        let value = filters[key];
        if (value != '') {
          let conjuction = i == 0 ? '?' : '&';
          if (key == 'page' || value.trim() != '') {
            cond += conjuction + key + '=' + value;
          }
          i++;
        }
      }
    }
    return this.httpClient
      .post<Coupon[]>(
        this.apiNjServer + '/v4/activity/log' + cond,
        filtersReqData,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  saveHttpMessage(data, token?): Observable<Coupon> {
    data['user_email'] = this.userData.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/http-messages', data, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  errorHandler(error){
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error?.message;
    } else {
      errorMessage = `Error Code: ${error?.status}\nMessage: ${error?.error?.message}`;
    }
    if(error?.error?.code === 403){
      errorMessage='You do not have adequate permission to access this Page. Please contact your administrator.'
    }
    this.toastrService.error('Something went wrong', errorMessage);
    this.loader.hide();
    return throwError(errorMessage);
  }
  downloadFile(
    data,
    filename = 'data',
    csvHeader = ['code', 'discount', 'price_slabs']
  ) {
    filename = filename + '_' + new Date();
    let csvData = this.ConvertToCSV(data, csvHeader);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
  getRelatedCoupon(auto_gen_key, token?): Observable<Coupon> {
    let coupon = {
      auto_gen_key: auto_gen_key,
    };
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/related/coupon', coupon, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  exportCoupon(token?): Observable<Coupon> {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v4/export/coupon', {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  bulkUpdate(coupons, token?, preview = 1): Observable<Coupon> {
    let record = {
      coupon: coupons,
      user_email: this.userData.email,
      in_preview: preview,
    };
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/coupon/bulk/update', record, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  bulkUpdateCouponType(coupons, token?, preview = 1): Observable<Coupon> {
    let record = {
      coupon: coupons,
      user_email: this.userData.email,
      in_preview: preview,
    };
    return this.httpClient
      .post<Coupon>(
        this.apiNjServer + '/v1/bulk/coupon/update-coupon-type',
        record,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  public searchArray(findkey, findvalue, getkey, myArray, returnData) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][findkey] === findvalue) {
        if (!returnData.includes(myArray[i][getkey].toString())) {
          returnData.push(myArray[i][getkey].toString());
          this.searchArray(
            findkey,
            myArray[i][getkey],
            getkey,
            myArray,
            returnData
          );
        }
      }
    }
    return returnData;
  }
  public extractColumn(arr, column) {
    return arr.map((x) => x[column]);
  }
  deleteBulkCoupon(postdata, token?): Observable<Coupon> {
    postdata['user_email'] = this.userData.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/coupon/bulk/delete', postdata, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }

  public getTimelist(from) {
    return [
      { text: 'Select', value: from ? '00:00' : '23:59' },
      { text: '00:30', value: '00:30' },
      { text: '01:00', value: '01:00' },
      { text: '01:30', value: '01:30' },
      { text: '02:00', value: '02:00' },
      { text: '02:30', value: '02:30' },
      { text: '03:00', value: '03:00' },
      { text: '03:30', value: '03:30' },
      { text: '04:00', value: '04:00' },
      { text: '04:30', value: '04:30' },
      { text: '05:00', value: '05:00' },
      { text: '05:30', value: '05:30' },
      { text: '06:00', value: '06:00' },
      { text: '06:30', value: '06:30' },
      { text: '07:00', value: '07:00' },
      { text: '07:30', value: '07:30' },
      { text: '08:00', value: '08:00' },
      { text: '08:30', value: '08:30' },
      { text: '09:00', value: '09:00' },
      { text: '09:30', value: '09:30' },
      { text: '10:00', value: '10:00' },
      { text: '10:30', value: '10:30' },
      { text: '11:00', value: '11:00' },
      { text: '11:30', value: '11:30' },
      { text: '12:00', value: '12:00' },
      { text: '12:30', value: '12:30' },
      { text: '13:00', value: '13:00' },
      { text: '13:30', value: '13:30' },
      { text: '14:00', value: '14:00' },
      { text: '14:30', value: '14:30' },
      { text: '15:00', value: '15:00' },
      { text: '15:30', value: '15:30' },
      { text: '16:00', value: '16:00' },
      { text: '16:30', value: '16:30' },
      { text: '17:00', value: '17:00' },
      { text: '17:30', value: '17:30' },
      { text: '18:00', value: '18:00' },
      { text: '18:30', value: '18:30' },
      { text: '19:00', value: '19:00' },
      { text: '19:30', value: '19:30' },
      { text: '20:00', value: '20:00' },
      { text: '20:30', value: '20:30' },
      { text: '21:00', value: '21:00' },
      { text: '21:30', value: '21:30' },
      { text: '22:00', value: '22:00' },
      { text: '22:30', value: '22:30' },
      { text: '23:00', value: '23:00' },
      { text: '23:30', value: '23:30' },
    ];
  }

  getAutoGenCoupons(filters = [], token = ''): Observable<Coupon[]> {
    let cond = '';
    if (filters) {
      for (let key in filters) {
        let value = filters[key];
        if ((!Number.isInteger(value) && value.trim() != '') || value != '') {
          cond += '&' + key + '=' + value;
        }
      }
    }
    return this.httpClient
      .post<Coupon[]>(
        this.apiNjServer + '/v4/autoGenCoupons?show_all=1' + cond,
        {},
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }

  approveCoupon(coupon_id, token?) {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v4/approve/coupon/' + coupon_id, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  approveBulkCoupon(postdata, token?): Observable<Coupon> {
    postdata['user_email'] = this.userData.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/coupon/bulk/approve', postdata, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAllUserData(reqData = {}, filters = [], token = ''): Observable<User[]> {
    let cond = '';
    let data = { reqData: reqData };
    data['user_email'] = this.userData.email;
    if (filters) {
      let i = 0;
      for (let key in filters) {
        let value = filters[key];
        if (value != '') {
          let conjuction = i == 0 ? '?' : '&';
          if (key == 'page' || value.trim() != '') {
            cond += conjuction + key + '=' + value;
          }
          i++;
        }
      }
    }
    let apiUrl = this.apiNjServer + '/v4/users/approverList' + cond;
    return this.httpClient
      .post<User[]>(apiUrl, data, { headers: this.reqHeader(token) })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  createUser(user, token?): Observable<Coupon> {
    user['creator_mail'] = this.userData.email;
    return this.httpClient
      .post<Coupon>(this.apiNjServer + '/v4/user/create', user, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  deleteUser(user_id, token?) {
    return this.httpClient
      .delete<User>(this.apiNjServer + '/v4/remove/user/' + user_id, {
        headers: this.reqHeader(token),
      })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getActivityLogsUser(
    filtersReqData = {},
    filters = [],
    token?
  ): Observable<User[]> {
    let cond = '';
    if (filters) {
      let i = 0;
      for (let key in filters) {
        let value = filters[key];
        if (value != '') {
          let conjuction = i == 0 ? '?' : '&';
          if (key == 'page' || value.trim() != '') {
            cond += conjuction + key + '=' + value;
          }
          i++;
        }
      }
    }
    return this.httpClient
      .post<User[]>(
        this.apiNjServer + '/v4/activity/userlog' + cond,
        filtersReqData,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }

  public get locations() {
    return [
      { label: 'GCC', value: 'GULF', key: 0 },
      { label: 'INDIA', value: 'IN', key: 1 },
    ];
  }

  public get location() {
    return localStorage.getItem('LOCATION');
  }

  public set location(value: string) {
    localStorage.setItem('LOCATION', value);
  }

  public apiServers(url) {
    if (this.location && this.location !== 'GULF') {
      return `//${this.location.toLocaleLowerCase()}-${url.substring(2)}`;
    }
    return url;
  }

  public get flag() {
    return localStorage.getItem('LOCATION') == 'IN'
      ? 'https://storage.googleapis.com/dev-bucket.stylifashion.com/flags/flag_in.png'
      : 'https://storage.googleapis.com/dev-bucket.stylifashion.com/GCC_Flag.svg';
  }

  public get pods() {
    return localStorage.getItem('LOCATION') == 'IN' ? 'IN' : 'GCC';
  }

  getAllInfluencerData(
    reqData = {},
    filters = [],
    token = ''
  ): Observable<Influencer[]> {
    let cond = '';
    let data = { reqData: reqData };
    data['user_email'] = this.userData.email;
    if (filters) {
      let i = 0;
      for (let key in filters) {
        let value = filters[key];
        if (value != '') {
          let conjuction = i == 0 ? '?' : '&';
          if (key == 'page' || value.trim() != '') {
            cond += conjuction + key + '=' + value;
          }
          i++;
        }
      }
    }
    let apiUrl = this.apiNjServer + '/v4/influencer/influencerOptions' + cond;
    return this.httpClient
      .post<Influencer[]>(apiUrl, data, { headers: this.reqHeader(token) })
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  deleteInfluencerOptions(influencer_id, token = '') {
    return this.httpClient
      .delete<Influencer>(
        this.apiNjServer +
        '/v4/influencer/influencerOptionRemove/' +
        influencer_id,
        { headers: this.reqHeader(token) }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  createInfluencerOptions(influencerdata, token = ''): Observable<Influencer> {
    influencerdata['creator_mail'] = this.userData.email;
    return this.httpClient
      .post<Influencer>(
        this.apiNjServer + '/v4/influencer/influencerOptionCreate',
        influencerdata,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getInfluencerConfig(): Observable<Coupon> {
    return this.httpClient
      .get<Coupon>(this.apiNjServer + '/v4/getInfluencerConfig')
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
  getAdminConfig(token = ''): Observable<AdminConfig> {
    return this.httpClient
    .get<AdminConfig>(this.apiNjServer + '/v4/getAdminConfig',
    {
      headers: this.reqHeader(token),
    }
    )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }))
  }
  updateAdminConfig(storedetails, token = ''): Observable<AdminConfig> {;
    return this.httpClient
      .post<AdminConfig>(
        this.apiNjServer + '/v4/updateAdminConfig',
        storedetails,
        {
          headers: this.reqHeader(token),
        }
      )
      .pipe(catchError(error => { 
        return this.errorHandler(error);
      }));
  }
}
