import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bulk-coupon',
  templateUrl: './bulk-coupon.component.html',
  styleUrls: ['./bulk-coupon.component.css']
})
export class BulkCouponComponent implements OnInit {

  public totalItems: number = 0;
  public itemsPerPage: number = 10;
  public idToken = '';
  public config = {
    itemsPerPage: this.itemsPerPage,
    currentPage: 0,
    totalItems: this.totalItems,
  };
  public coupons: any[] = [];
  public filters: any = {};

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private loader: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.loader.show();
    let page = 1;
    if (this.filters['page'] !== undefined) {
      page = parseInt(this.filters['page']);
    }
    this.idToken = await this.authService.GetToken();
    this.apiService
      .getAutoGenCoupons(this.filters, this.idToken)
      .subscribe((data: any[]) => {
        this.coupons = [];
        data['data'].forEach((cdata) => {

          this.coupons.push(cdata);
        });
        this.loader.hide();
        this.totalItems = data['total'];
        this.config = {
          itemsPerPage: this.itemsPerPage,
          currentPage: page,
          totalItems: this.totalItems,
        };
      });
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.filters['page'] = event;
    this.ngOnInit();
  }
}
