import {
  Component,
  Input,
  OnInit,
  Output, EventEmitter ,OnChanges, SimpleChanges, ElementRef
} from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Coupon } from '../../../model/coupon';

@Component({
  selector: 'app-influencer-section',
  templateUrl: './influencer-section.component.html',
  styleUrls: [],
})
export class InfluencerSectionComponent implements OnInit{
  @Input('influencer_info') influencer_info: any = {};
  @Input() isValid: any;
  public influencerConfig: any = {};
  @Output() influencerConditions = new EventEmitter<any>();
  constructor(
    private apiService: ApiService
  ) { }
  async ngOnInit() {
    this.apiService.getInfluencerConfig().subscribe((idata: Coupon) => {
      this.influencerConfig= idata.influencerConfig;
      this.influencerConfig.category = idata?.influencerConfig?.category.sort((a, b) => a.value.localeCompare(b.value));
      this.influencerConfig.partner = idata?.influencerConfig?.partner.sort((a, b) => a.value.localeCompare(b.value));
      this.influencerConfig.platform = idata?.influencerConfig?.platform.sort((a, b) => a.value.localeCompare(b.value));
    });
  }
}