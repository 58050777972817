import {
  ViewChild,
  Component,
  OnInit,
  Input,
  TemplateRef,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { Coupon } from '../model/coupon';
@Component({
  selector: 'app-condition-form',
  templateUrl: './condition-form.component.html',
  styleUrls: ['./condition-form.component.css'],
})
export class ConditionFormComponent implements OnInit {
  @Input('master') masterName = '';
  @Input('coupon_conditions') coupon_conditions: any[] = [];
  @Output() couponConditions = new EventEmitter<any>();
  @ViewChild('treeview')
  public tree: TreeViewComponent;
  public conditions: any[] = [
    {
      id: 1,
      entity: 'categoryId',
      conjuction: 'is',
      attributes: '',
    },
  ];
  public entities: any[] = [
    { value: 'categoryId', text: 'Category' },
    { value: 'brandName', text: 'Brand' },
    { value: 'soldBy', text: 'Sold By' },
    { value: 'completeOrder', text: 'Completed Order' },
    { value: 'collection', text: 'Collection' },
  ];
  public conjuctions: string[] = ['is', 'isnot'];
  public data: Object[] = [{ id: 3, name: 'MEN', hasChild: true }];
  public field: Object = {
    dataSource: this.data,
    id: 'id',
    parentID: 'pid',
    text: 'name',
    hasChildren: 'hasChild',
  };
  public allcategories: any = [];
  // set the CheckBox to the TreeView
  public showCheckBox: boolean = true;
  //set the checknodes to the TreeView
  public checkedNodes: string[];
  public current_node = 0;
  public current_index = 0;
  modalRef: BsModalRef;
  idToken: string = '';
  constructor(
    private el: ElementRef,
    private apiService: ApiService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.idToken = await this.authService.GetToken();
    this.apiService
      .getProductCategoryList(this.idToken)
      .subscribe((data: Coupon[]) => {
        this.field['dataSource'] = data['data'];
        this.allcategories = data['data'];
      });
  }
  public nodeChecked(args): void {
    //console.log(args);
    //alert("The checked node's id is: "+this.tree.checkedNodes);
  }
  public getCheckedNodes(event) {
    const selectedData = event.checkedNodes.filter(function (ele) {
      return ele != '';
    });
    this.coupon_conditions[this.current_node].conditions[
      this.current_index
    ].attributes = selectedData;
    this.couponConditions.emit(this.coupon_conditions);
  }
  public showCatLists(node, index, selected, template: TemplateRef<any>) {
    let selectedCat = this.parentCat(selected);
    this.current_node = node;
    this.current_index = index;
    this.checkedNodes = selectedCat;
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
    });
  }
  public parentCat(selected) {
    let selectedCat = [];
    if (typeof selected !== 'object' && selected != '') {
      let returndata = [];
      let splitCats = selected.split(',');
      for (let c = 0; c < splitCats.length; c++) {
        returndata.push(splitCats[c].toString());
        let catData = this.apiService.searchArray(
          'pid',
          parseInt(splitCats[c]),
          'id',
          this.allcategories,
          returndata
        );
        selectedCat = [...catData];
      }
    } else {
      selectedCat = selected;
    }
    return selectedCat;
  }
  addCondition(k) {
    this.coupon_conditions[k].conditions.push({
      id: this.coupon_conditions[k].conditions.length + 1,
      entity: 'categoryId',
      conjuction: 'is',
      attributes: '',
    });
    this.couponConditions.emit(this.coupon_conditions);
  }
  removeCondition(i: number, k: number) {
    this.coupon_conditions[k].conditions.splice(i, 1);
    if (this.coupon_conditions[k].conditions.length == 0) {
      this.coupon_conditions.splice(k, 1);
    }
    this.couponConditions.emit(this.coupon_conditions);
  }
  addNewClause(clause: string) {
    const clause_text = clause == 'must' ? 'Must' : 'Must Not';
    const cond = {
      clause: clause,
      clause_text: clause_text,
      conditions: [
        { id: 1, entity: 'categoryId', conjuction: 'is', attributes: '' },
      ],
    };
    this.coupon_conditions.push(cond);
    this.couponConditions.emit(this.coupon_conditions);
  }
}
