import {
  ViewChild,
  ViewChildren,
  Component,
  OnInit,
  QueryList,
  ElementRef,
} from '@angular/core';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { ApiService } from '../../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Coupon } from '../../model/coupon';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { find, pull } from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment';

@Component({
  selector: 'app-approve-form',
  templateUrl: './approve-form.component.html',
  styleUrls: ['./approve-form.component.css'],
})
export class ApproveFormComponent implements OnInit {
  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  @ViewChild(BsDatepickerDirective, { static: false })
  datepicker: BsDatepickerDirective;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  @ViewChild('tagInput') tagInputRef: ElementRef;
 
  coupondata: Coupon[];
  public coupon_id;
  public idToken = '';
  public processed: boolean = false;
 

  constructor(
    private el: ElementRef,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService
  ) {
    
  }
  
  async ngOnInit() {
    this.idToken = await this.authService.GetToken();
    this.activatedRoute.params.subscribe((params) => {
      this.coupon_id = params['coupon_id'];
    });
    if (this.coupon_id !== undefined) {
      this.processed = true;
      this.loader.show();
      this.apiService
        .getCouponById(this.coupon_id, this.idToken)
        .subscribe((data: Coupon) => {
          if (data.code == 200) {
            this.loader.hide();
            this.processed = false;
            let coupon = data.data;
            if(coupon.status == 0 && coupon.isApprovalRequired){
              Swal.fire({
                title: 'Are you sure?',
                text: 'Are you sure you want to approve this coupon?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
              }).then((result) => {
                if (result.value) {
                  this.loader.show();
                  this.apiService
                    .approveCoupon(this.coupon_id, this.idToken)
                    .subscribe((data: Coupon) => {
                      if (data.code == 200) {
                        this.loader.hide();
                        this.router.navigate(['approvals']);
                        this.toastrService.success(data.message);
                      } else if(data.code == 600){
                        this.loader.hide();
                        this.router.navigate(['coupon']);
                        this.toastrService.error(data.message);
                      }else {
                        this.loader.hide();
                        this.router.navigate(['approvals']);
                        this.toastrService.error(data.message);
                      }
                    });
                }else{
                  this.router.navigate(['coupon']);
                }
              });
            }
          }
            });
           
          } else {
            this.router.navigate(['coupon']);
          }
      
    }
  }
 