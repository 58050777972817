import {
  ViewChild,
  ViewChildren,
  Component,
  OnInit,
  QueryList,
  ElementRef,
} from '@angular/core';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { ApiService } from '../../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Coupon } from '../../model/coupon';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { find, pull } from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.css'],
})
export class CouponFormComponent implements OnInit {
  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;
  @ViewChild(BsDatepickerDirective, { static: false })
  datepicker: BsDatepickerDirective;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  @ViewChild('tagInput') tagInputRef: ElementRef;
  card_bins: string[] = [];
  coupon_image: string;
  coupondata: Coupon[];
  public coupon_id;
  public addEdit: string = 'Create';
  public button_name: string = 'Save';
  public couponForm: FormGroup;
  public idToken = '';
  minDate: Date;
  expMinDate: Date;
  public coupon_text = 'Please enter coupon code';
  public isValid: boolean = false;
  public processed: boolean = false;
  public showCardBin: boolean = false;
  code_format: any = [
    { value: 'alphanumeric', text: 'Alphanumeric' },
    { value: 'alphabetical', text: 'Alphabetical' },
    { value: 'numeric', text: 'Numeric' },
  ];
  public discountTypes: any = [];
  public discountOption: any = [
    { value: 0, text: 'FIXED' },
    { value: 1, text: 'PERCENT' },
  ];
  public bundleOption: any = [
    { value: 2, text: 'Fixed Price: Buy each N item For $X' },
    { value: 3, text: 'Percent Price: Buy X Get Y For Free' },
    { value: 4, text: 'Percent Price: Buy N At Y%' },
    { value: 5, text: 'Buy X Get Y for Z' },
  ];
  websiteSelect: any = [];
  selected_website: any = [];
  public stores = [];
  public terms_conditions: any = {};
  public offer_message: any = {};
  public descriptions: any = {};
  public badge_texts: any = {};
  public couponType = 0;
  duplicate = false;
  originalCoupon: string = '';
  public auto_gen_key: string = '';
  public couponRuleType: any = this.apiService.couponRuleType;
  public timeListTo: any = this.apiService.getTimelist(false);
  public timeListFrom: any = this.apiService.getTimelist(true);
  public coupon_conditions: any = [];
  public selected_coupon_type = 0;
  public cCouponType = 0;
  public valid_to: Date;
  public valid_from: Date;
  public select_valid_from_time: string = '00:00';
  public select_valid_to_time: string = '23:59';
  public disable_valid_to_time: boolean = true;
  public couponSpecific: boolean = false;
  errorMessage: string;

  constructor(
    private el: ElementRef,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService
  ) {
    this.minDate = new Date();
  }
  public websites: any;
  public storeIds: any;
  public autogenDisable = true;
  public validators = [];
  public discountTypeValues: any = [];
  public uploadCsv = false;
  public error_messages: any = [];
  public global_error_message = [];
  public no_of_coupon_can_create = 0;
  public influencer_info: any = {};
  async ngOnInit() {
    this.apiService.getWebsiteData().subscribe((wdata: Coupon) => {
      this.websites = wdata.weblist;
      this.storeIds = wdata.stores;
    });
    this.discountTypeValues[0] = 0;
    this.idToken = await this.authService.GetToken();
    this.apiService.getHttpMessage(this.idToken).subscribe((data: Coupon) => {
      let settingData = [];
      data?.data?.forEach((setting) => {
        let sdata = {
          code: setting.code,
          msg: JSON.parse(setting.msg),
        };
        settingData.push(sdata);
      });
      this.global_error_message = settingData;
    });
    this.activatedRoute.params.subscribe((params) => {
      if (params?.duplicate !== undefined) {
        if (params.duplicate == 'duplicate') {
          this.duplicate = true;
        } else {
          this.toastrService.error('Wrong url!!!');
          this.router.navigate(['coupon']);
        }
      }
      this.coupon_id = params['coupon_id'];
    });

    this.couponForm = this.formBuilder.group({
      coupon_name: ['', Validators.required],
      coupon_trade_type: [0, Validators.required],
      coupon_type: [0, Validators.required],
      coupon: [0],
      coupon_code: [''],
      card_bin: [''],
      coupon_image: [''],
      fileChanged: [false],
      auto_generate: [0],
      update_all_related: [0],
      website_id: [''],
      store_id: [''],
      description: [''],
      valid_from: ['', Validators.required],
      valid_from_time: [this.select_valid_from_time, Validators.required],
      valid_to: ['', [Validators.required]],
      valid_to_time: [this.select_valid_to_time, Validators.required],
      total_redemption_limit: [
        0,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      redemption_per_user: [
        0,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      status: [1],
      terms: [''],
      coupon_qty: [1],
      code_length: [8],
      code_format: [''],
      code_prefix: [''],
      code_suffix: [''],
      payment_type: [0],
      priority: [0, [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      coupon_bundle: [''],
      enable_badge: [0],
      is_visible: [0],
      offer_msg: [''],
      bank_offer_type: [0],
      onOsp: [false, [Validators.required]],
      is_gift_voucher: [false],
      rules: new FormArray([]),
      influencer_info: [''],
    });
    if (this.coupon_id !== undefined) {
      this.processed = true;
      if (!this.duplicate) {
        this.button_name = 'Update';
        this.addEdit = 'Edit';
      } else {
        this.addEdit = 'Duplicate';
      }
      this.loader.show();
      this.apiService
        .getCouponById(this.coupon_id, this.idToken)
        .subscribe((data: Coupon) => {
          if (data.code == 200) {
            this.loader.hide();
            this.processed = false;
            let coupon = data.data;
            if (typeof coupon.website_id !== 'undefined') {
              this.websiteSelect = coupon.website_id.map(function (e) {
                return e.toString();
              });
            }
            this.getStores();
            this.disableCouponCodeField(coupon?.auto_generate);
            if (typeof coupon.terms == 'string') {
              this.terms_conditions['default'] = coupon.terms;
            } else if (typeof coupon.terms == 'undefined') {
              this.terms_conditions['default'] = '';
            } else {
              this.terms_conditions = coupon.terms;
            }
            this.couponType = coupon.coupon_type;
            if (coupon.coupon_type != 1) {
              let bank_offer_type =
                coupon.coupon_type == 2
                  ? coupon?.bank_offer_type
                    ? coupon?.bank_offer_type
                    : 1
                  : 0;
              this.getDiscountTypes(coupon.coupon_type, bank_offer_type);
            }
            if (typeof coupon.description == 'string') {
              this.descriptions['default'] = coupon.description;
            } else if (typeof coupon.description == 'undefined') {
              this.descriptions['default'] = '';
            } else {
              this.descriptions = coupon.description;
            }
            if (typeof coupon?.offer_message == 'string') {
              this.offer_message['default'] = coupon?.offer_message;
            } else if (typeof coupon?.offer_message == 'undefined') {
              this.offer_message['default'] = '';
            } else {
              this.offer_message = coupon.offer_message;
            }
            this.storeIds.forEach((store) => {
              let key = store.id;
              if (typeof this.terms_conditions[key] === 'undefined') {
                this.terms_conditions[key] = '';
              }
              if (typeof this.descriptions[key] === 'undefined') {
                this.descriptions[key] = '';
              }
              if (typeof this.badge_texts[key] === 'undefined') {
                this.badge_texts[key] = '';
              }
              if (typeof this.offer_message[key] === 'undefined') {
                this.offer_message[key] = '';
              }
            });
            if (coupon.rules) {
              let i = 0;
              coupon.rules.forEach((x) => {
                if (x?.discount_qty_step === undefined) {
                  x.discount_qty_step = 0;
                }
                if (x?.at_first_discount_applied_to === undefined) {
                  x.at_first_discount_applied_to = 0;
                }
                if (x?.max_qty_discount === undefined) {
                  x.max_qty_discount = 0;
                }
                if (x?.buy_qty_for_discount === undefined) {
                  x.buy_qty_for_discount = 0;
                }
                if (x?.get_qty_for_discount === undefined) {
                  x.get_qty_for_discount = 0;
                }
                if (
                  x?.different_discount_needed_for_special_discount ===
                  undefined
                ) {
                  x.different_discount_needed_for_special_discount = 0;
                }
                if (
                  x?.discount_for_discounted_product === undefined ||
                  x?.discount_for_discounted_product === ''
                ) {
                  x.discount_for_discounted_product = 0;
                }
                if (
                  x?.cutoff_for_discount_for_discounted_product === undefined ||
                  x?.cutoff_for_discount_for_discounted_product === ''
                ) {
                  x.cutoff_for_discount_for_discounted_product = 0;
                }
                if (x?.different_discount_needed_for_new_user === undefined) {
                  x.different_discount_needed_for_new_user = 0;
                }
                if (x?.discount_for_discounted_product_new_user === undefined) {
                  x.discount_for_discounted_product_new_user = 0;
                }
                if (
                  x?.cutoff_for_discount_for_discounted_product_new_user ===
                  undefined
                ) {
                  x.cutoff_for_discount_for_discounted_product_new_user = 0;
                }
                if (x?.discount_limit_new_user === undefined) {
                  x.discount_limit_new_user = 0;
                }
                if (x?.discount_value_new_user === undefined) {
                  x.discount_value_new_user = 0;
                }
                if (
                  x?.discount_limit === undefined ||
                  x?.discount_limit === null ||
                  x?.discount_limit === ''
                ) {
                  x.discount_limit = 0;
                }
                this.ruleData.push(this.formBuilder.group(x));
                this.changeDiscountType(i, x.discount_type, false);
                i++;
              });
            }
            if (
              coupon?.coupon_trade_type === undefined ||
              coupon?.coupon_trade_type == ''
            ) {
              coupon.coupon_trade_type = 0;
            }
            this.couponForm.controls.coupon_trade_type.setValue(
              coupon?.coupon_trade_type
            );
            if (coupon?.onOsp === undefined || coupon?.onOsp == '') {
              coupon.onOsp = false;
            }
            this.couponForm.controls.onOsp.setValue(coupon?.onOsp);
            this.couponForm.controls.coupon_type.setValue(coupon?.coupon_type);
            this.selected_coupon_type = coupon?.coupon_type;
            this.cCouponType = coupon?.coupon_type;
            this.card_bins = coupon?.card_bin;
            this.coupon_image = coupon?.coupon_image;
            this.couponForm.controls.coupon_image.setValue(
              coupon?.coupon_image
            );
            //this.couponForm.controls.card_bin.setValue(coupon?.card_bin);
            let is_visible = coupon?.is_visible;
            if (coupon?.is_visible === undefined) {
              is_visible = 1;
            }
            this.couponForm.controls.is_visible.setValue(is_visible);
            let is_gift_voucher = coupon?.is_gift_voucher;
            if (coupon?.is_gift_voucher === undefined) {
              is_gift_voucher = true;
            }
            this.couponForm.controls.is_gift_voucher.setValue(is_gift_voucher);

            // if (typeof coupon.influencer_category !== 'undefined') {
            //   this.couponForm.controls.influencer_category.setValue(coupon.influencer_category);
            // }
            // if (typeof coupon.influencer_partner !== 'undefined') {
            //   this.couponForm.controls.influencer_partner.setValue(coupon.influencer_partner);
            // }
            // if (typeof coupon.influencer_platform !== 'undefined') {
            //   this.couponForm.controls.influencer_platform.setValue(coupon.influencer_platform);
            // }
            // if (typeof coupon.trade_discount !== 'undefined') {
            //   this.couponForm.controls.trade_discount.setValue(coupon.trade_discount);
            // }
            // let cancel_status = coupon?.cancel_status;
            // if (coupon?.cancel_status === undefined) {
            //   cancel_status = true;
            // }
            // this.couponForm.controls.cancel_status.setValue(cancel_status);

            if (!this.duplicate) {
              this.couponForm.controls.coupon_name.setValue(coupon.coupon_name);
              this.couponForm.controls.coupon_code.setValue(coupon.coupon_code);
              if (
                typeof coupon.auto_gen_key !== undefined &&
                coupon?.auto_gen_key != ''
              ) {
                this.auto_gen_key = coupon?.auto_gen_key;
              }
            } else {
              this.originalCoupon = coupon.coupon_name;
            }
            if (coupon.coupon == 1) {
              this.changeValidationOfCouponCode(1);
              this.couponForm.controls.coupon.setValue(coupon.coupon);
            }
            this.couponForm.controls.enable_badge.setValue(
              coupon?.enable_badge
            );
            this.badge_texts = coupon?.badge_text;
            this.couponForm.controls.description.setValue(
              this.descriptions?.default
            );
            this.couponForm.controls.offer_msg.setValue(
              this.offer_message?.default
            );
            const validFrom = coupon.valid_from.split('T');
            const validTo = coupon.valid_to.split('T');
            this.couponForm.controls.valid_from.setValue(validFrom[0]);
            this.couponForm.controls.valid_to.setValue(validTo[0]);

            if (
              typeof this.timeListFrom.find(
                (v) => v.value == validTo[1].substr(0, 5)
              ) != undefined
            ) {
              this.select_valid_from_time = validFrom[1].substr(0, 5);
              this.couponForm.controls.valid_from_time.setValue(
                validFrom[1].substr(0, 5)
              );
            }
            if (
              typeof this.timeListTo.find(
                (v) => v.value == validTo[1].substr(0, 5)
              ) != undefined
            ) {
              this.select_valid_to_time = validTo[1].substr(0, 5);
              this.couponForm.controls.valid_to_time.setValue(
                validTo[1].substr(0, 5)
              );
            }

            this.couponForm.controls.total_redemption_limit.setValue(
              coupon.total_redemption_limit
            );
            this.couponForm.controls.redemption_per_user.setValue(
              coupon.redemption_per_user
            );
            this.couponForm.controls.status.setValue(coupon.status);
            this.couponForm.controls.priority.setValue(coupon.priority);
            this.couponForm.controls.terms.setValue(
              this.terms_conditions?.default
            );
            this.coupon_conditions = coupon.custom_conditions;
            this.influencer_info = coupon.influencer_info || {};
            let error_messages_data = coupon?.error_messages
              ? coupon?.error_messages
              : '';
            if (error_messages_data != '') {
              for (let code in error_messages_data) {
                this.error_messages.push({
                  code: code,
                  msg: error_messages_data[code],
                });
              }
            }
          } else {
            this.router.navigate(['coupon']);
          }
        });
      this.couponForm.controls.valid_to.enable();
      this.disable_valid_to_time = null;
    } else {
      this.getDiscountTypes(0);
      this.addRule();
      this.disableCouponCodeField(0);
      this.couponForm.controls.valid_to.disable();
      this.disable_valid_to_time = true;
    }
  }
  addRule() {
    let bag_max_value = 0;
    let bag_min_value = 0;
    const ruleDataLength = this.ruleData.controls.length;
    if (ruleDataLength > 0) {
      if (
        this.ruleData.controls[ruleDataLength - 1]['controls'].bag_max_value
          .value > 0
      ) {
        bag_min_value =
          parseInt(
            this.ruleData.controls[ruleDataLength - 1]['controls'].bag_max_value
              .value
          ) + 1;
      } else {
        this.toastrService.error(
          'Last subtotal maximum value should greater than 0 for adding new rule!!'
        );
        return false;
      }
    }
    this.ruleData.push(
      this.formBuilder.group({
        bag_min_value: [
          bag_min_value,
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        bag_max_value: [
          bag_max_value,
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        discount_type: [
          0,
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        discount_value: [
          0,
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        discount_value_new_user: [
          0,
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        different_discount_needed_for_special_discount: [0],
        discount_for_discounted_product: [0, [Validators.pattern(/^[0-9]+$/)]],
        cutoff_for_discount_for_discounted_product: [
          0,
          [Validators.pattern(/^[0-9]+$/)],
        ],
        different_discount_needed_for_new_user: [0],
        discount_for_discounted_product_new_user: [
          0,
          [Validators.pattern(/^[0-9]+$/)],
        ],
        cutoff_for_discount_for_discounted_product_new_user: [
          0,
          [Validators.pattern(/^[0-9]+$/)],
        ],
        discount_limit: [
          { value: 0, disabled: true },
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        discount_limit_new_user: [
          { value: 0, disabled: true },
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        discount_qty_step: [1, [Validators.pattern(/^[0-9]+$/)]],
        at_first_discount_applied_to: [0],
        max_qty_discount: [0, [Validators.pattern(/^[0-9]+$/)]],
        buy_qty_for_discount: [1, [Validators.pattern(/^[0-9]+$/)]],
        get_qty_for_discount: [1, [Validators.pattern(/^[0-9]+$/)]],
      })
    );
  }
  get formValue() {
    return this.couponForm?.controls;
  }
  get ruleData() {
    return this.formValue.rules as FormArray;
  }
  removeRule(i) {
    let control = this.ruleData;
    control.removeAt(i);
  }
  changeDiscountType(i, type, change_value = true) {
    let control = this.ruleData;
    if (type == 0) {
      control.controls[i]['controls'].discount_limit.disable();
      control.controls[i]['controls'].discount_limit.setValue(0);
      control.controls[i]['controls'].discount_limit_new_user.disable();
      control.controls[i]['controls'].discount_limit_new_user.setValue(0);
    } else {
      control.controls[i]['controls'].discount_limit.enable();
      control.controls[i]['controls'].discount_limit_new_user.enable();
    }
    if (type == 0 || type == 1) {
      control.controls[i]['controls'].discount_qty_step.setValue(0);
    }
    if (change_value) {
      control.controls[i][
        'controls'
      ].different_discount_needed_for_special_discount.setValue(0);
      control.controls[i]['controls'].discount_for_discounted_product.setValue(
        0
      );
      control.controls[i][
        'controls'
      ].cutoff_for_discount_for_discounted_product.setValue(0);
      /*control.controls[i][
        'controls'
      ].different_discount_needed_for_new_user.setValue(0);*/
      control.controls[i][
        'controls'
      ].discount_for_discounted_product_new_user.setValue(0);
      control.controls[i][
        'controls'
      ].cutoff_for_discount_for_discounted_product_new_user.setValue(0);
      const status =
        this.couponType == 1 &&
          this.formValue.coupon.value &&
          this.formValue.onOsp.value &&
          (type == 3 || type == 4 || type == 5)
          ? true
          : false;
      this.getPriceType(status);
    }
    this.discountTypeValues[i] = type;
  }
  getPriceType(value) {
    this.formValue.onOsp.setValue(value);
  }
  validateDate() {
    const fromdate =
      moment(this.couponForm.controls['valid_from'].value).format(
        'YYYY-MM-DD'
      ) +
      'T' +
      this.couponForm.controls['valid_from_time'].value;
    const todate =
      moment(this.couponForm.controls['valid_to'].value).format('YYYY-MM-DD') +
      'T' +
      this.couponForm.controls['valid_to_time'].value;

    if (moment(todate).isBefore(fromdate)) {
      this.couponForm.get('valid_to').setErrors({ lessthanstart: true });
      return false;
    }
    return true;
  }
  checkUserRedemptionLimit() {
    if (
      parseInt(this.couponForm.controls['total_redemption_limit'].value) > 0 &&
      parseInt(this.couponForm.controls['redemption_per_user'].value) >
      parseInt(this.couponForm.controls['total_redemption_limit'].value)
    ) {
      this.couponForm
        .get('redemption_per_user')
        .setErrors({ lessthantotal: true });
      return false;
    }
    return true;
  }
  onValueChange(start_date) {
    if (start_date != null) {
      this.expMinDate = start_date;
      this.couponForm.controls.valid_to.enable();
      this.disable_valid_to_time = null;
    }
  }
  checkPrice(index = 0) {
    let error = false;
    this.isValid = true;
    let bag_min_value = parseInt(
      this.ruleData.controls[index]['controls'].bag_min_value.value
    );
    let bag_max_value = parseInt(
      this.ruleData.controls[index]['controls'].bag_max_value.value
    );
    if (bag_max_value > 0 && bag_max_value <= bag_min_value) {
      error = true;
      this.ruleData.controls[index]['controls'].bag_max_value.setErrors({
        greaterThanMinVal: true,
      });
    } else if (
      index > 0 &&
      parseInt(
        this.ruleData.controls[index - 1]['controls'].bag_max_value.value
      ) >= bag_min_value
    ) {
      error = true;
      this.ruleData.controls[index]['controls'].bag_min_value.setErrors({
        greaterThanPrevMaxVal: true,
      });
    } else {
      this.ruleData.controls[index]['controls'].bag_min_value.setErrors(null);
      this.ruleData.controls[index]['controls'].bag_max_value.setErrors(null);
    }
    if (!error) {
      this.isValid = false;
    } else {
      this.processed = false;
    }
  }
  async onSubmit(cdata) {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    this.isValid = true;
    this.processed = true;
    let validExpDate = this.validateDate();
    let userlimit = this.checkUserRedemptionLimit();
    let i = 0;
    let couponrules = [];
    let couponPercentLimit = false;
    let couponPercentLimitForNewUser = false;
    let isGiftConfig = false;

    cdata.rules.forEach((x) => {
      if (x.discount_type == 1) {
        if (
          parseInt(x?.discount_for_discounted_product) > 0 &&
          parseInt(x?.discount_for_discounted_product) >=
          parseInt(x.discount_value)
        ) {
          this.ruleData.controls[i][
            'controls'
          ]?.discount_for_discounted_product?.setErrors({
            lessthanMainDiscount: true,
          });
        } else {
          this.ruleData.controls[i][
            'controls'
          ]?.discount_for_discounted_product?.setErrors(null);
        }
        if (x.discount_value == 100) {
          couponPercentLimit = true;
        }
        if (
          parseInt(x?.discount_for_discounted_product_new_user) > 0 &&
          parseInt(x?.discount_for_discounted_product_new_user) >=
          parseInt(x.discount_value_new_user)
        ) {
          this.ruleData.controls[i][
            'controls'
          ]?.discount_for_discounted_product_new_user?.setErrors({
            lessthanMainDiscountNewUser: true,
          });
        } else {
          this.ruleData.controls[i][
            'controls'
          ]?.discount_for_discounted_product_new_user?.setErrors(null);
        }
        if (x.discount_value_new_user == 100) {
          couponPercentLimitForNewUser = true;
        }
      }
      if (x.discount_type == 3) {
        if (x.buy_qty_for_discount == 0) {
          this.ruleData.controls[i]['controls'].buy_qty_for_discount.setErrors({
            greaterThanZero: true,
          });
        }
        if (x.get_qty_for_discount == 0) {
          this.ruleData.controls[i]['controls'].get_qty_for_discount.setErrors({
            greaterThanZero: true,
          });
        }
      }
      if (
        (x.discount_type == 1 || x.discount_type == 3) &&
        x.discount_value > 100
      ) {
        this.ruleData.controls[i]['controls'].discount_value.setErrors({
          lessThanHundred: true,
        });
      } else {
        this.ruleData.controls[i]['controls'].discount_value.setErrors(null);
      }
      if (
        (x.discount_type == 1 || x.discount_type == 3) &&
        x.discount_value_new_user > 100
      ) {
        this.ruleData.controls[i]['controls'].discount_value_new_user.setErrors(
          {
            lessThanHundred: true,
          }
        );
      } else {
        this.ruleData.controls[i]['controls'].discount_value_new_user.setErrors(
          null
        );
      }
      if (
        i > 0 &&
        parseInt(x.bag_min_value) <=
        parseInt(
          this.ruleData.controls[i - 1]['controls'].bag_max_value.value
        )
      ) {
        this.ruleData.controls[i]['controls'].bag_min_value.setErrors({
          greaterThanPrevMaxVal: true,
        });
      } else if (
        i > 0 &&
        parseInt(
          this.ruleData.controls[i - 1]['controls'].bag_max_value.value
        ) == 0
      ) {
        this.ruleData.controls[i - 1]['controls'].bag_max_value.setErrors({
          shouldNotZeroForaddingMore: true,
        });
      } else {
        this.ruleData.controls[i]['controls'].bag_min_value.setErrors(null);
        if (i > 0) {
          this.ruleData.controls[i - 1]['controls'].bag_max_value.setErrors(
            null
          );
        }
      }
      if (
        parseInt(x.bag_max_value) > 0 &&
        parseInt(x.bag_min_value) > parseInt(x.bag_max_value)
      ) {
        this.ruleData.controls[i]['controls'].bag_max_value.setErrors({
          greaterThanMinVal: true,
        });
      } else {
        this.ruleData.controls[i]['controls'].bag_max_value.setErrors(null);
        let ruledata = {
          bag_min_value: x.bag_min_value,
          bag_max_value: x.bag_max_value,
          currency: '',
          discount_type: x.discount_type,
          discount_value: x.discount_value,
          discount_value_new_user: x.discount_value_new_user,
          different_discount_needed_for_special_discount:
            x?.different_discount_needed_for_special_discount,
          discount_for_discounted_product: x?.discount_for_discounted_product,
          cutoff_for_discount_for_discounted_product:
            x?.cutoff_for_discount_for_discounted_product,
          different_discount_needed_for_new_user:
            x?.different_discount_needed_for_new_user,
          discount_for_discounted_product_new_user:
            x?.discount_for_discounted_product_new_user,
          cutoff_for_discount_for_discounted_product_new_user:
            x?.cutoff_for_discount_for_discounted_product_new_user,
          discount_limit: x.discount_limit,
          discount_limit_new_user: x.discount_limit_new_user,
          rule_status: 1,
          discount_qty_step: x.discount_qty_step,
          at_first_discount_applied_to: x.at_first_discount_applied_to,
          max_qty_discount: x.max_qty_discount,
          buy_qty_for_discount: x.buy_qty_for_discount,
          get_qty_for_discount: x.get_qty_for_discount,
        };
        if (x._id && !this.duplicate) {
          ruledata['rule_id'] = x._id.$oid;
        }
        couponrules.push(ruledata);
      }
      i++;
    });
    if (this.uploadCsv && cdata.coupon_bundle == '') {
      this.formValue.coupon_bundle.setErrors({ required: true });
    } else {
      this.formValue.coupon_bundle.setErrors(null);
    }
    if (this.selected_website.length == 0) {
      this.formValue.website_id.setErrors({ required: true });
    } else {
      this.formValue.website_id.setErrors(null);
    }
    if (this.couponForm.valid && validExpDate && userlimit) {
      let description = {};
      for (let key in this.descriptions) {
        if (this.stores.indexOf(key) > -1) {
          description[key] = this.descriptions[key];
        }
      }
      this.descriptions = description;

      let terms_condition = {};

      for (let key in this.terms_conditions) {
        if (this.stores.indexOf(key) > -1) {
          terms_condition[key] = this.terms_conditions[key];
        }
      }
      this.terms_conditions = terms_condition;
      this.descriptions['default'] = cdata.description;
      this.terms_conditions['default'] = cdata.terms;

      let badge_texts = {};
      for (let key in this.badge_texts) {
        if (this.stores.indexOf(key) > -1) {
          badge_texts[key] = this.badge_texts[key];
        }
      }
      this.badge_texts = badge_texts;
      let badge_enable_status = 0;
      if (cdata?.auto_generate == 0 && this.couponType == 1) {
        badge_enable_status = cdata.enable_badge;
      }
      let filtered_error_msg = {};
      this.error_messages.forEach((error) => {
        if (error.code != '') {
          filtered_error_msg[error.code] = error.msg;
        }
      });
      let offer_messages = {};
      for (let key in this.offer_message) {
        if (
          this.stores.indexOf(key.toString()) > -1 ||
          this.stores.indexOf(parseInt(key)) > -1
        ) {
          offer_messages[key] = this.offer_message[key];
        }
      }
      this.offer_message = offer_messages;
      this.offer_message['default'] = cdata?.offer_msg;

      let couponData = {
        coupon_name: cdata.coupon_name,
        coupon_trade_type: cdata?.coupon_trade_type,
        coupon_type: this.couponType,
        coupon: cdata?.coupon,
        coupon_code:
          cdata?.coupon === undefined || cdata?.coupon == 0
            ? cdata?.coupon_code
            : '',
        description: this.descriptions,
        website_id: this.selected_website,
        store_id: this.stores,
        valid_from: this.formatDate(cdata.valid_from),
        valid_from_time: cdata.valid_from_time,
        valid_to: this.formatDate(cdata.valid_to),
        valid_to_time: cdata.valid_to_time,
        total_redemption_limit: cdata.total_redemption_limit,
        redemption_per_user: cdata.redemption_per_user,
        status: cdata.status,
        terms: this.terms_conditions,
        payment_type: cdata?.payment_type,
        custom_conditions: this.coupon_conditions,
        priority: cdata.priority,
        enable_badge: badge_enable_status,
        badge_text: this.badge_texts,
        is_visible: cdata?.is_visible,
        rules: couponrules,
        error_messages: filtered_error_msg,
        offer_message: offer_messages,
        bank_offer_type: cdata?.bank_offer_type,
        onOsp: cdata.onOsp,
        is_gift_voucher: cdata?.is_gift_voucher,
        influencer_info: this.influencer_info,
        // influencer_category:  cdata?.influencer_category,
        // influencer_partner:  cdata?.influencer_partner,
        // influencer_platform: cdata?.influencer_platform,
        // trade_discount: cdata?.trade_discount,
        // cancel_status: cdata?.cancel_status,
      };

      if (this.couponType == 2) {
        couponData['card_bin'] = this.card_bins;
        couponData['coupon_image'] = this.coupon_image;
        couponData['file_changed'] = cdata?.fileChanged;
      }
      if (
        cdata?.is_gift_voucher &&
        (this.couponType !== 0 ||
          parseInt(cdata.total_redemption_limit) !== 1 ||
          parseInt(cdata.redemption_per_user) !== 1 ||
          parseInt(couponrules[0].discount_type) !== 0)
      ) {
        const str =
          'Please follow below config \n Rule Type: Bag Value\n  Uses per Coupon: 1\n Uses per Customer: 1\n Rule Discount Type: FIXED';
        await Swal.fire({
          title: 'Gift voucher config is wrong',
          html: '<pre>' + str + '</pre>',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Re-Confirm',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            isGiftConfig = true;
          }
        });
      }
      if (couponPercentLimit) {
        await Swal.fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to create this coupon with 100% discount?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            couponPercentLimit = false;
          }
        });
      }
      if (couponPercentLimitForNewUser) {
        await Swal.fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to create this coupon with 100% discount?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            couponPercentLimitForNewUser = false;
          }
        });
      }
      if (
        !couponPercentLimit &&
        !couponPercentLimitForNewUser &&
        !isGiftConfig
      ) {
        this.loader.show();
        if (this.coupon_id !== undefined && !this.duplicate) {
          couponData['auto_gen_key'] = cdata?.auto_gen_key;
          couponData['update_all_related'] = cdata?.update_all_related;
          this.apiService
            .updateCoupon(this.coupon_id, couponData, userToken)
            .subscribe(
              async (data) => {
                if (data.code == 200) {
                  this.toastrService.success(data.message);
                  this.loader.hide();
                  if (data?.messageStatus) {
                    await Swal.fire({
                      title: 'Alert info',
                      text: data?.messageStatus,
                      type: 'warning',
                      showCancelButton: false,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Ok',
                      closeOnConfirm: true,
                    });
                  }
                } else if (data.code == 206) {
                  this.couponForm
                    .get('coupon_code')
                    .setErrors({ required: true });
                  this.coupon_text = data.message;
                  this.toastrService.error(data.message);
                } else {
                  this.toastrService.error(data.message);
                }
                this.loader.hide();
                this.processed = false;
              },
              (error) => {
                this.toastrService.error(error);
                this.loader.hide();
                this.processed = false;
                // this.toastrService.error(data.message);
              }
            );
        } else {
          couponData['auto_generate'] = cdata?.auto_generate;
          if (this.uploadCsv) {
            couponData['coupon_bundle'] = cdata?.coupon_bundle;
          } else {
            couponData['coupon_qty'] = cdata?.coupon_qty;
            couponData['code_length'] = cdata?.code_length;
            couponData['code_format'] = cdata?.code_format;
            couponData['code_prefix'] = cdata?.code_prefix;
            couponData['code_suffix'] = cdata?.code_suffix;
          }
          if (couponData['auto_generate']) {
            this.duplicate = true;
          }
          this.apiService.createCoupon(couponData, userToken).subscribe(
            async (data) => {
              if (data.code == 200) {
                this.toastrService.success(data.message);
                this.loader.hide();
                if (data?.messageStatus) {
                  await Swal.fire({
                    title: 'Alert info',
                    text: data?.messageStatus,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok',
                    closeOnConfirm: true,
                  }).then((result) => {
                    if (result.value) {
                      if (!this.duplicate) {
                        this.router.navigate(['coupon/edit/' + data.coupon_id]);
                      } else {
                        this.router.navigate(['coupon']);
                      }
                    }
                  });
                } else {
                  if (!this.duplicate) {
                    this.router.navigate(['coupon/edit/' + data.coupon_id]);
                  } else {
                    this.router.navigate(['coupon']);
                  }
                }
              } else if (data.code == 206) {
                if (!this.uploadCsv) {
                  this.couponForm
                    .get('coupon_code')
                    .setErrors({ required: true });
                }
                this.coupon_text = data.message;
                this.toastrService.error(data.message);
              } else if (data.code == 202) {
                Swal.fire({
                  title: 'Promo Alert?',
                  html: data.message,
                  icon: 'warning',
                });
              } else {
                this.toastrService.error(data.message);
              }
              this.loader.hide();
              this.processed = false;
            },
            (error) => {
              this.toastrService.error(error);
              this.loader.hide();
              this.processed = false;
              // this.toastrService.error(data.message);
            }
          );
        }
      } else {
        this.processed = false;
      }
    } else {
      this.processed = false;
      this.scrollToError();
    }
  }
  scrollToError(): void {
    for (const key of Object.keys(this.couponForm.controls)) {
      if (this.couponForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector(
          '[formcontrolname="' + key + '"]'
        );
        invalidControl.focus();
        break;
      }
    }
  }
  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  getStores() {
    let selected = [];
    this.websiteSelect.forEach((element) => {
      selected.push(parseInt(element));
    });
    this.selected_website = selected;
    let store_id = [];
    this.storeIds.forEach((x) => {
      if (this.selected_website.indexOf(x.website_id) > -1) {
        store_id.push(x.id);
      }
    });
    this.stores = store_id;
  }
  getTradeType(type) {
    this.formValue.coupon_trade_type.setValue(type);
  }

  async getCouponType(iType) {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'You are changing the type of coupon (Attribute based/ Bag value). The rules and conditions entered will be reset. Are you sure you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.cCouponType = iType;
        this.coupon_conditions = [];
        const type = parseInt(iType);
        let priority = 0;
        let noCoupon = type == 0 ? 0 : 1;
        if (type != 1) {
          priority = type == 0 ? 2 : 4;
          this.formValue.coupon.setValue(noCoupon);
          this.changeValidationOfCouponCode(noCoupon);
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });
          this.formValue.auto_generate.setValue(0);
        } else {
          priority = this.formValue.coupon.value === 1 ? 0 : 2;
        }
        this.formValue.priority.setValue(priority);
        this.getDiscountTypes(type);
        this.couponType = type;
        this.ruleData.controls = [];
        this.addRule();
        this.discountTypeValues[0] = 0;
        const status =
          type == 1 &&
            noCoupon &&
            this.formValue.onOsp.value &&
            (this.discountTypeValues[0] == 3 ||
              this.discountTypeValues[0] == 4 ||
              this.discountTypeValues[0] == 5)
            ? true
            : false;
        this.getPriceType(status);
      } else {
        this.selected_coupon_type = this.cCouponType;
      }
    });
  }
  getDiscountTypes(type, bank_offer_type = 0) {
    this.discountTypes = [...this.discountOption];

    if (type == 1 && this.formValue.coupon.value == 1) {
      this.discountTypes.push(...this.bundleOption);
    }
    if (type == 2) {
      bank_offer_type = bank_offer_type == 0 ? 1 : bank_offer_type;
      this.formValue.bank_offer_type.setValue(bank_offer_type);
      this.changeOfferType(bank_offer_type);
    } else {
      this.formValue.bank_offer_type.setValue(0);
    }
  }
  changeValidationOfCouponCode(coupon, ui = false) {
    let priority = 2;
    this.discountTypes = [...this.discountOption];

    if (this.couponType == 1 && coupon == 1) {
      this.discountTypes.push(...this.bundleOption);
    }
    if (ui) {
      this.ruleData.controls[0]['controls'].discount_type.setValue(0);
      this.discountTypeValues[0] = 0;
    }
    if (coupon == 1) {
      this.formValue.coupon_code.disable();
      this.formValue.coupon_code.setValidators([]);
      priority = coupon == 1 ? 0 : 2;
    } else {
      this.formValue.coupon_code.enable();
      this.formValue.coupon_code.setValidators([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]);
      this.formValue.auto_generate.setValue(0);
    }
    this.formValue.priority.setValue(priority);
    // annanta
    this.couponSpecific = coupon == 1 ? false : true;
    const status =
      this.couponType == 1 &&
        coupon &&
        this.formValue.onOsp.value &&
        (this.discountTypeValues[0] == 3 ||
          this.discountTypeValues[0] == 4 ||
          this.discountTypeValues[0] == 5)
        ? true
        : false;
    this.getPriceType(status);
  }

  public changeBundleType($event) {
    let is_auto_generate = 0;
    if ($event.target.checked) {
      this.checkboxes.forEach((element) => {
        if (element.nativeElement.value != $event.target.value) {
          element.nativeElement.checked = false;
        }
      });
      is_auto_generate = $event.target.value;
    }
    this.formValue.auto_generate.setValue(is_auto_generate);
    this.disableCouponCodeField(is_auto_generate);
  }
  public disableCouponCodeField(is_auto_generate = 0) {
    this.formValue.code_format.setValue('alphanumeric');
    if (!is_auto_generate || typeof is_auto_generate == undefined) {
      is_auto_generate = this.formValue.auto_generate.value;
    }
    if (is_auto_generate == 1) {
      this.uploadCsv = false;
      this.autogenDisable = false;
      this.validators = [
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        Validators.min(1),
      ];
      this.changeValidationOfCouponCode(1);
      this.formValue.coupon_qty.enable();
      this.formValue.code_length.enable();
      this.formValue.code_format.enable();
      this.formValue.code_prefix.enable();
      this.formValue.code_suffix.enable();
      this.formValue.coupon_bundle.disable();
    } else {
      if (is_auto_generate == 2) {
        this.uploadCsv = true;
        this.autogenDisable = false;
        this.changeValidationOfCouponCode(1);
        this.formValue.coupon_bundle.enable();
      } else {
        this.autogenDisable = true;
        this.uploadCsv = false;
        this.changeValidationOfCouponCode(0);
        this.formValue.coupon_bundle.disable();
      }
      this.validators = [];
      this.formValue.coupon_qty.disable();
      this.formValue.code_length.disable();
      this.formValue.code_format.disable();
      this.formValue.code_prefix.disable();
      this.formValue.code_suffix.disable();
    }
    this.formValue.coupon_qty.setValidators(this.validators);
    this.formValue.code_length.setValidators(this.validators);
    this.formValue.code_format.setValidators([]);
    this.formValue.code_prefix.setValidators([]);
    this.formValue.code_suffix.setValidators([]);
  }

  uploadCoupons($event: any): void {
    let files = $event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {
      this.errorMessage = '';
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        console.log("csvRecordsArray", csvRecordsArray.length)
        if (csvRecordsArray.length <= 1) {
          this.errorMessage = 'CSV file is empty, please add data and upload.';
          this.fileReset();
        }
        this.formValue.coupon_bundle.setValue(csvRecordsArray);
      };
      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      this.formValue.coupon_bundle.setErrors({ onlycsv: true });
      this.errorMessage = 'Only .csv files are allowed';
      this.fileReset();
    }
  }
  isValidCSVFile(file: any) {
    const fileName = file?.name;

    if (!fileName) {
      return false;
    }
    const parts = fileName.split('.');
    // Check the number of parts
    if (parts.length !== 2) {
      // More than one extension found, return false or throw an error
      return false;
    }
    return file.name.endsWith('.csv');
  }
  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
  }
  downloadAllRelated(auto_gen_key) {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    let couponData = [];
    this.loader.show();
    this.apiService
      .getRelatedCoupon(auto_gen_key, userToken)
      .subscribe((data: Coupon) => {
        if (data.code == 200) {
          couponData = data.data;
          this.apiService.downloadFile(couponData, 'coupon_data', [
            'code',
            'discount',
            'price_slabs',
          ]);
          this.loader.hide();
        } else {
          this.loader.hide();
          this.toastrService.error(data.message);
        }
      });
  }
  addErrorMessage() {
    this.error_messages.push({
      code: '',
      msg: { 1: '', 3: '', 7: '', 11: '' },
    });
  }
  removeErrorMessage(k) {
    this.error_messages.splice(k, 1);
  }
  getAllCouponRuleTypes() {
    return this.apiService.couponRuleType;
  }
  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.couponForm.controls.card_bin.value;
    if (event.code === 'Comma' || event.code === 'Space') {
      let bins = inputValue.split(',');
      if (bins.length > 0) {
        bins.forEach((bin) => {
          this.addTag(bin);
        });
      }
      this.couponForm.controls.card_bin.setValue('');
    }
  }
  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length >= 4 && tag.length <= 8 && !find(this.card_bins, tag)) {
      this.card_bins.push(tag);
    }
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.card_bins, tag);
    } else {
      this.card_bins.splice(-1);
    }
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.couponForm.controls.fileChanged.setValue(true);
      const reader = new FileReader();
      reader.onload = () => {
        this.coupon_image = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
  changeOfferType(offer_type = 0) {
    if (offer_type == 1) {
      this.showCardBin = true;
    } else {
      this.showCardBin = false;
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  async getCouponCount() {
    this.idToken = await this.authService.GetToken();
    const couponData = {
      code_length: this.formValue.code_length.value,
      code_format: this.formValue.code_format.value,
      code_prefix: this.formValue.code_prefix.value,
      code_suffix: this.formValue.code_suffix.value,
    };
    this.apiService
      .getNoOfCouponCanCreate(couponData, this.idToken)
      .subscribe((res: Coupon) => {
        if (res.code == 200) {
          this.no_of_coupon_can_create = res.no_of_coupon_can_created;
        } else {
          this.no_of_coupon_can_create = 0;
        }
      });
  }
  async checkCouponCount() {
    await this.getCouponCount();
    const request_qty = this.formValue.coupon_qty.value;
    if (request_qty > this.no_of_coupon_can_create) {
      this.formValue.coupon_qty.setErrors({ should_less_than: true });
    } else {
      this.formValue.coupon_qty.setErrors(null);
    }
  }
  getConditions(cond: any) {
    this.coupon_conditions = cond;
  }

  setSelectTime(from, value) {
    if (from) {
      this.select_valid_from_time = value;
    } else {
      this.select_valid_to_time = value;
    }
  }
  getInfluencerConditions(cond: any) {
    console.log('influencer_info_cond', cond);
    this.influencer_info = cond;
  }
}
