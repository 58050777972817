import { ViewChild, Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthService } from '../auth/auth.service';
import { Coupon } from './../model/coupon';

@Component({
  selector: 'app-update-coupon-type',
  templateUrl: './update-coupon-type.component.html',
  styleUrls: ['./update-coupon-type.component.css']
})
export class UpdateCouponTypeComponent implements OnInit {
  @ViewChild('csvReader') csvReader: any;
  public records: any[] = [];
  public error_records: any[] = [];
  public couponPercentLimit = false;
  idToken = '';
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}

  async ngOnInit() {
    this.idToken = await this.authService.GetToken();
  }
  bulkCouponUpdate($event: any) {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    let files = $event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      let updateRecords = [];
      let keys = [];

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        csvRecordsArray.forEach(function (csvRecords, index) {
          let cdata = {};
          let recordArray = [...csvRecords.split(',')];
          if (index == 0) {
            keys = [...recordArray];
          } else {
            recordArray.forEach(function (rowSplit, i) {
              cdata[keys[i]] = rowSplit;
            });
            updateRecords.push(cdata);
          }
        });
        this.loader.show();
        this.apiService
          .bulkUpdateCouponType(updateRecords, userToken, 1)
          .subscribe((data: Coupon) => {
            if (data.code == 200) {
              this.records = data.expected_data;
              this.error_records = data.error_data;
              this.loader.hide();
            } else {
              this.toastrService.error(data.message);
              this.loader.hide();
            }
          });
      };
      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      this.fileReset();
    }
  }
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }
  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
    this.error_records = [];
  }
  updateBulk() {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to bulk update these coupons?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.apiService
          .bulkUpdateCouponType(this.records, userToken, 0)
          .subscribe((data: Coupon) => {
            if (data.code == 200) {
              this.toastrService.success(data.message);
              this.fileReset();
            } else {
              this.toastrService.error(data.message);
            }
            this.loader.hide();
          });
      }
    });
  }
}
