import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Coupon } from '../model/coupon';
import { FormBuilder, FormGroup, FormArray, AbstractControl, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  public settingsForm:any[] = [];
  processed = false;
  idToken = "";
  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, private ActivatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private loader: NgxSpinnerService, private toastrService: ToastrService) { }
  public websites:any;
  public storeIds:any;
  async ngOnInit(){
    this.loader.show();
    this.apiService.getWebsiteData().subscribe((wdata: Coupon)=>{
      this.websites = wdata.websites;
      // console.log(this.websites);
      this.storeIds = wdata.stores;
    });
    this.idToken = await this.authService.GetToken();
    this.apiService.getHttpMessage(this.idToken).subscribe(data => {
      if(data.data.length > 0){
        let settingData = [];
        data.data.forEach(setting => {
          // console.log(typeof(setting.msg));
          let msgDisplay = setting.msg;
          if(typeof(setting.msg) == 'string'){
            msgDisplay = JSON.parse(setting.msg);
          }
          let sdata = {
            "_id" : setting._id,
            "code" : setting.code,
            "readonly" : true,
            "msg" : msgDisplay
          }
          settingData.push(sdata);
        });
        this.settingsForm = settingData;
      }else{
        this.addSetting();
      }
      this.loader.hide();
    });
    
  }
  addSetting(){
    this.settingsForm.push({
      _id: "",
      code: "",
      readonly : false,
      msg: {default:"", 1: "", 3: "", 7: "", 11: ""},
    });
  }
  onSubmit(){
    this.loader.show();
    const sdata = {"data" : this.settingsForm}
    this.apiService.saveHttpMessage(sdata, this.idToken).subscribe(data => {
      if(data.code == 200){
        this.toastrService.success(data.message);
      }else{
        this.toastrService.error(data.message);
      }
      this.loader.hide();
    });
  }
}
