export const environment = {
  production: true,
  apiServer: '//promo-api-dev.stylifashion.com',
  apiNjServer: '//promo-apinj-dev.stylishop.store',
  remoteConfig: '//dev-configpanel.web.app/home',
  authServiceUrl: 'https://dev-sentinel-api.stylifashion.com/api/v1/auth',
  firebaseConfig: {
    apiKey: 'AIzaSyCADXDR33XJC3gCS3iaFLWytXo2m4aB_OM',
    authDomain: 'searchvm-dev.firebaseapp.com',
    databaseURL: 'https://searchvm-dev.firebaseio.com',
    projectId: 'searchvm-dev',
    storageBucket: 'searchvm-dev.appspot.com',
    messagingSenderId: '679987494918',
    appId: '1:679987494918:web:4bca77d1cb415d55368387',
  },
};
