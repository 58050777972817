import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './menu/menu.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CouponComponent } from './coupon/coupon.component';
import { RedeemComponent } from './redeem/redeem.component';
import { CouponFormComponent } from './coupon/coupon-form/coupon-form.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LoginComponent } from './admin/login/login.component';
import { environment } from 'src/environments/environment';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingComponent } from './setting/setting.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { ImportCouponComponent } from './import-coupon/import-coupon.component';
import { ConfigComponent } from './setting/config/config.component';
import { ConditionFormComponent } from './condition-form/condition-form.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UpdateCouponTypeComponent } from './update-coupon-type/update-coupon-type.component';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BulkCouponComponent } from './bulk-coupon/bulk-coupon.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ApproveFormComponent } from './approvals/approve-form/approve-form.component';
import { UsersComponent } from './users/users.component';
import { ActivitylogUserComponent } from './activitylog/activitylog-user/activitylog-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfluencerComponent } from './influencer/influencer.component';
import { InfluencerSectionComponent } from './coupon/coupon-form/influencer-section/influencer-section.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminConfigComponent } from './admin-config/admin-config.component';
import { VerifyOtpComponent } from './admin/verify-otp/verify-otp.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    CouponComponent,
    RedeemComponent,
    CouponFormComponent,
    LoginComponent,
    DashboardComponent,
    SettingComponent,
    ActivitylogComponent,
    ImportCouponComponent,
    ConfigComponent,
    ConditionFormComponent,
    UpdateCouponTypeComponent,
    BulkCouponComponent,
    ApprovalsComponent,
    ApproveFormComponent,
    UsersComponent,
    ActivitylogUserComponent,
    InfluencerComponent,
    InfluencerSectionComponent,
    AdminConfigComponent,
    VerifyOtpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxSpinnerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    TreeViewModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      closeButton: true
    }),
    NgxPaginationModule,
    AngularMultiSelectModule,
    TimepickerModule.forRoot(),
    NgbModule,
    NgSelectModule
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: 'environment', useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
