<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>
          Influencer Option list
          <div class="page-title-subheading">Influencer Option list</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Influencer Option list</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Influencer Option list</h5>
          <h5 class="card-title text-right"  *ngIf="
          isWrite == true
        ">
            <button
              type="button"
              class="btn btn-primary"
              (click)="openModal(optionModal, {})"
            >
              <span class="btn-icon-wrapper pr-2 opacity-7">
                <em class="fa fa-plus fa-w-20"></em>
              </span>
              New Influencer Option
            </button>
          </h5>
          <table class="mb-0 table table-hover" aria-labelledby="influencer">
            <thead>
              <tr>
                <th scope="col" class="width-15">Type</th>
                <th scope="col" class="width-15">Name</th>
                <th scope="col">
                  Created Date
                </th>
                <th scope="col" *ngIf=" isWrite == true ">
                 Edit
                </th>
                <th scope="col"  *ngIf=" isWrite == true ">
                  Delete
                 </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select class="form-control" [(ngModel)]="reqData.type">
                    <option [value]=""></option>
                    <option [value]="0">Coupon Category</option>
                    <option [value]="1">Coupon Partner</option>
                    <option [value]="2">Platform</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="reqData.value"
                  />
                </td>
                <td>
                  <input
                    type="button"
                    class="btn"
                    value="Reset"
                    (click)="clearFilter()"
                  />
                  &nbsp;&nbsp;<input
                    type="button"
                    class="btn btn-primary"
                    value="Submit"
                    (click)="getFilteredValue()"
                  />
                </td>
              </tr>
              <tr *ngFor="let influencer of influencers | paginate: config">
                <td><span *ngIf="influencer.type == 0">Coupon Category</span>
                  <span *ngIf="influencer.type == 1">Coupon Partner</span>
                  <span *ngIf="influencer.type == 2">Platform</span></td>
                <td>{{ influencer.value }}</td>
                <td>
                  {{ influencer.created_at }}
                </td>
                <td *ngIf="
                isWrite == true
              ">
                 <em class="fa fa-edit" style="color:rgb(17, 69, 200)"  (click)="openModal(optionModal, influencer)"></em>
                 </td>
                 <td *ngIf="
                 isWrite == true
               ">
                  <a
                    href="javascript:void(0)"
                    title="Delete"
                    (click)="deleteInfluencerOptions(influencer)"
                    > <em class="fa fa-trash" style="color:red"></em></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          
          <div
            class="text-right"
            style="margin: 20px 0"
            *ngIf="totalItems > itemsPerPage"
          >
            <pagination-controls
              (pageChange)="pageChanged($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #optionModal let-modal>
  <div class="modal-header">
   <h5 class="modal-title" id="editCategoty">Influencer Option</h5>
   <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
   </button>
  </div>
  
  <div class="modal-body">
   <form [formGroup]="influencerForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
     <label for="type" class="col-sm-4 col-form-label">Type</label>
     <div class="col-sm-8">
      <select class="form-control" formControlName="type" id="type">
        <!-- <option [value]=""></option> -->
        <option [value]="0">Coupon Category</option>
        <option [value]="1">Coupon Partner</option>
        <option [value]="2">Platform</option>
      </select>
     </div>
    </div>
    <div class="form-group row">
      <label for="value" class="col-sm-4 col-form-label">Name</label>
      <div class="col-sm-8">
       <input type="text" class="form-control" formControlName="value" id="value">
      </div>
     </div>
    <div class="form-group row">
     <div class="col-sm-8">
      <input type="hidden" class="form-control" formControlName="influencer_id" id="influencer_id">
     </div>
    </div>
    
    <div class="modal-footer">
     <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
     <button type="submit" class="btn btn-primary">Save changes</button>
    </div>
   </form>
  </div>
 </ng-template>