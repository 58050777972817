import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {AuthService} from "../auth/auth.service";
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  menus = [];
  constructor(public apiService : ApiService, private loader: NgxSpinnerService, public authService : AuthService,private router: Router) { 
    this.menus = apiService.getMenuList(); 
    this.apiService
    .getApprovalAccess()
    .subscribe( data => {
      if (!data.showStatus) {
       this.menus.splice(0, 1);
      }
    });
  }
  user: any;
  ngOnInit() {
    this.user = this.apiService.userData;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Location changed, you can check user data here
        console.log('Window location changed:', event.url);
        this.user = this.apiService.userData;
      }
    });
  }
  getSideBarState() {
    return this.apiService.getSidebarState();
  }

  toggle(currentMenu) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage() {
    return this.apiService.hasBackgroundImage;
  }
}
