<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-car icon-gradient bg-mean-fruit"></em>
        </div>
        <div>
          Dashboard
          <div class="page-title-subheading">
            Promo service analytics dashboard
          </div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Dashboard</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-xl-4">
      <div class="card mb-3 widget-content bg-midnight-bloom">
        <div class="widget-content-wrapper text-white">
          <div class="widget-content-left">
            <div class="widget-heading">Total Coupons</div>
            <div class="widget-subheading">No. of available coupons</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers text-white">
              <span>{{ dashboardData.total_coupon }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-4">
      <div class="card mb-3 widget-content bg-arielle-smile">
        <div class="widget-content-wrapper text-white">
          <div class="widget-content-left">
            <div class="widget-heading">Total Active Coupons</div>
            <div class="widget-subheading">No. of active coupons</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers text-white">
              <span>{{ dashboardData.total_active_coupon }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-4">
      <div class="card mb-3 widget-content bg-grow-early">
        <div class="widget-content-wrapper text-white">
          <div class="widget-content-left">
            <div class="widget-heading">Total Redemption</div>
            <div class="widget-subheading">No. of coupon redeemed</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers text-white">
              <span>{{ dashboardData.total_redemption }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="main-card mb-3 card">
        <div class="card-header">Recently Added Coupons</div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="mb-0 table table-hover"
              aria-labelledby="Dashboard Coupon List"
            >
              <thead>
                <tr>
                  <th scope="col">Sl#</th>
                  <th scope="col">Coupon Name</th>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Valid From</th>
                  <th scope="col">Expired On</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let coupon of dashboardData.coupon; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ coupon?.coupon_name }}</td>
                  <th scope="row">{{ coupon.coupon_code }}</th>
                  <td>{{ getDate(coupon.valid_from) | date: "MMM d, y" }}</td>
                  <td>{{ getDate(coupon.valid_to) | date: "MMM d, y" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="dashboardData.warn_dis_value_coupon.length > 0">
    <div class="col-md-12">
      <div class="main-card mb-3 card">
        <div class="card-header text-danger">Coupons with 100% Discount</div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="mb-0 table table-hover"
              aria-labelledby="Coupons with 100% Discount"
            >
              <thead>
                <tr>
                  <th scope="col">Sl#</th>
                  <th scope="col">Coupon Name</th>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Valid From</th>
                  <th scope="col">Expired On</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let coupon of dashboardData.warn_dis_value_coupon;
                    let i = index
                  "
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ coupon?.coupon_name }}</td>
                  <th scope="row">{{ coupon.coupon_code }}</th>
                  <td>{{ getDate(coupon.valid_from) | date: "MMM d, y" }}</td>
                  <td>{{ getDate(coupon.valid_to) | date: "MMM d, y" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="main-card mb-3 card">
        <div class="card-header">Latest Redemptions</div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="mb-0 table table-hover"
              aria-labelledby="Latest Redemptions"
            >
              <thead>
                <tr>
                  <th scope="col">Sl#</th>
                  <th scope="col" class="width-15">Coupon Code</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Tracking ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody *ngIf="dashboardData.redemption">
                <tr
                  *ngFor="let redeem of dashboardData.redemption; let i = index"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ redeem.coupon_code }}</td>
                  <td>{{ redeem.user_id }}</td>
                  <td>{{ redeem.order_id }}</td>
                  <td>{{ redeem.tracking_id }}</td>
                  <td>
                    {{ getDate(redeem.redeem_date) | date: "MMM d, y H:m" }}
                  </td>
                  <td>
                    <span>{{
                      redeem.redeem_status == 1 ? "Success" : "Failure"
                    }}</span>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!dashboardData.redemption">
                <tr>
                  <td colspan="6">No data found!!!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
