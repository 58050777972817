<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <em class="pe-7s-drawer icon-gradient bg-happy-itmeo">
                    </em>
                </div>
                <div>Update Coupon Type
                    <div class="page-title-subheading">Bulk Update 
                    </div>
                </div>
            </div>
            <div class="page-title-actions">
                <nav class="" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['']">Home</a></li>
                        <li class="breadcrumb-item">Coupon</li>
                        <li class="breadcrumb-item">Update Coupon Type</li>
                    </ol>
                </nav>
            </div>    
        </div>
    </div>  
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="form-group row">
                        <label class="col-md-2 col-sm-12 col-xs-12 col-form-label">Import Coupons <span class="text-danger">*</span></label>
                        <div class="col-md-5 col-sm-12 col-xs-12">
                            <input type="file" #csvReader name="Upload CSV" (click)="csvReader.value = null" id="csvFileUpload" (change)="bulkCouponUpdate($event)" accept=".csv" />
                        </div>
                        <div class="col-md-5 text-right">
                            <a class="btn btn-info" href="/assets/update_sample.csv" download="update_sample.csv"><em class="pe-7s-download"></em> Download Sample Csv</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <div class="row" *ngIf="records.length > 0">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-body"><h5 class="card-title">Valid Data</h5>
                    <table class="mb-0 table table-hover" aria-labelledby="coupons">
                        <thead>
                            <tr>
                                <th scope="col">Coupon Code</th>
                                <th scope="col">Price Slabs</th>
                                <th scope="col">Discount Amount</th>
                                <th scope="col">Discount Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of records">
                                <td>{{record?.coupon_code}}</td>
                                <td>{{record?.price_slabs}}</td>
                                <td>
                                    {{record?.discount_amount}}
                                    <p class="error text-danger"> {{record?.discount_amount_exceed !== undefined ? record?.discount_amount_exceed : ""}} </p>
                                </td>
                                <td>{{record?.discount_type}}</td>
                            </tr>
                        </tbody>
                    </table>    
                </div>
                <div class="d-block text-right card-footer" >
                    <button type="submit" class="btn btn-primary btn-lg" (click)="updateBulk()">Update</button>
                </div>
            </div>
        </div>
    </div>     
    
    <div class="row" *ngIf="error_records.length > 0">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-body"><h5 class="card-title">InValid Data</h5>
                    <table class="mb-0 table table-hover" aria-labelledby="coupons">
                        <thead>
                            <tr>
                                <th scope="col">Coupon Code</th>
                                <th scope="col">Price Slabs</th>
                                <th scope="col">Discount Amount</th>
                                <th scope="col">Discount Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of error_records">
                                <td>
                                    {{record?.coupon_code}}
                                    <p class="error text-danger">{{record?.coupon_code_error !== undefined ? record?.coupon_code_error : ""}}</p>
                                </td>
                                <td>
                                    {{record?.price_slabs}}
                                    <p class="error text-danger">{{record?.price_slabs_error !== undefined ? record?.price_slabs_error : ""}}</p>
                                </td>
                                <td>
                                    {{record?.discount_amount}}
                                    <p class="error text-danger"> {{record?.discount_amount_exceed !== undefined ? record?.discount_amount_exceed : ""}} </p>
                                </td>
                                <td>
                                    {{record?.discount_type}}
                                    <p class="error text-danger">{{record?.discount_amount_error !== undefined ? record?.discount_amount_error : ""}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>    
                </div>
            </div>
        </div>
    </div> 

</div>    
