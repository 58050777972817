import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AdminConfig } from '../model/adminConfig';
import { AuthService } from '../auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

interface AdminConfigApiInterface {
  websiteCode: string;
  threshold: number;
  freeShippingEnabled: boolean;
  startDateAndTime: null | string;
  endDateAndTime: null | string;
}

interface RecordInterface {
  websiteCode: string;
  threshold: number;
  freeShippingEnabled: boolean;
  startDate: null | string;
  startTime: null | string;
  expiryDate: null | string;
  expiryTime: null | string;
  statusType: null | string;
}

@Component({
  selector: 'app-admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.css'],
})
export class AdminConfigComponent implements OnInit {
  public records: RecordInterface[] = [];
  public disable_valid_to_time: boolean | null = null;
  public timeListTo: any = this.apiService.getTimelist(false);
  public timeListFrom: any = this.apiService.getTimelist(true);
  public minDates: Date[] = [];
  public startDatesInit: boolean[] = [];
  public endDatesInit: boolean[] = [];
  public today: Date = new Date();
  public adminConfigForm: FormGroup;
  formValueChanged: boolean[] = [];
  startDateAndTime: string[] = [];
  endDateAndTime: string[] = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService,
    private fb: FormBuilder
  ) {
    // this.createForm();
    this.initFormValueChangeFlags();
  }
  async ngOnInit() {
    this.getAdminConfig();
  }

  initFormValueChangeFlags() {
    this.records.forEach(() => {
      this.formValueChanged.push(false);
    });
  }

  onConfigChange(index: number) {
    this.formValueChanged[index] = true;
  }

  handleKeydown(event: KeyboardEvent, index) {
    console.log('Key down:', event.key);
    if (['e', 'E', '+', '-'].includes(event.key)) {
      console.log('Key down:XX', event.key);
      event.preventDefault();
    }else{
      this.formValueChanged[index] = true;
    }
  }

  changeStatusType(index: number, value: string) {
    this.records[index].statusType = value;
  }

  async getAdminConfig() {
    let userToken =
      localStorage.getItem('userToken').trim() ??
      (await this.authService.GetToken());
    // this.loader.show();
    this.apiService.getAdminConfig(userToken).subscribe((data: AdminConfig) => {
      // this.loader.hide();
      if (data.code == 200) {
        this.records = data.response.map(this.convertToRecordInterface);
        this.createForm(this.records);
      } else {
        this.toastrService.error(data.message);
      }
    });
  }

  removeOrdinalSuffix(dateString: string): string {
    return dateString.replace(/(\d+)(st|nd|rd|th)/, '$1');
  }
  parseDate(dateString: string): Date {
    const dateWithoutOrdinal = this.removeOrdinalSuffix(dateString);
    return new Date(dateWithoutOrdinal);
  }

  createForm(records: RecordInterface[]) {
    this.adminConfigForm = this.fb.group({});
    records.forEach((child, index) => {
      const sDate =  this.parseDate(child.startDate);
      const eDate = this.parseDate(child.expiryDate);
      console.log(sDate, sDate.toLocaleDateString);
      console.log(eDate, eDate.toLocaleDateString);
      const group = this.fb.group({
        threshold: new FormControl(child.threshold || '', [
          Validators.required,
        ]),
        startDate: new FormControl(sDate, [Validators.required]),
        startTime: new FormControl(child.startTime, [Validators.required]),
        expiryDate: new FormControl(eDate, [Validators.required]),
        expiryTime: new FormControl(child.expiryTime, [Validators.required]),
        freeShippingEnabled: new FormControl(child.freeShippingEnabled || '', [
          Validators.required,
        ]),
        statusType: new FormControl(child.statusType || '0', [Validators.required]),
      });
      this.adminConfigForm.addControl('group' + index, group);
      this.minDates[index] = new Date(sDate);
    });
  }

  onStartDateChange(index: number) {
    const startDate = this.adminConfigForm.get([
      'group' + index,
      'startDate',
    ]).value;
    if (startDate) {
      this.minDates[index] = new Date(startDate);
    }
    const sDate =  this.parseDate(this.records[index].startDate);
    if(startDate.getTime() !== sDate.getTime() && this.startDatesInit[index] === true){
      this.formValueChanged[index] = true;
    }
    if(startDate.getTime() === sDate.getTime() && !this.startDatesInit[index]){
      this.startDatesInit[index] = true;
    } 
  }
  
  onEndDateChange(index: number) {
    const endDate = this.adminConfigForm.get([
      'group' + index,
      'expiryDate',
    ]).value;
    const eDate =  this.parseDate(this.records[index].expiryDate);
    if(endDate.getTime() !== eDate.getTime() && this.endDatesInit[index] === true){
      this.formValueChanged[index] = true;
    }
    if(endDate.getTime() === eDate.getTime() && !this.endDatesInit[index]){
      this.endDatesInit[index] = true;
    } 
  }

  async updateChanges(index: number) {
    const recStartDate = this.records[index].startDate;
    const recStartTime = this.records[index].startTime;
    const recExpiryDate = this.records[index].expiryDate;
    const recExpiryTime = this.records[index].expiryTime;

    const statusType = this.records[index].statusType;

    const startDate = this.adminConfigForm.get([
      'group' + index,
      'startDate',
    ]).value;
    const startTime = this.adminConfigForm.get([
      'group' + index,
      'startTime',
    ]).value;
    const expiryDate = this.adminConfigForm.get([
      'group' + index,
      'expiryDate',
    ]).value;
    const expiryTime = this.adminConfigForm.get([
      'group' + index,
      'expiryTime',
    ]).value;
    this.startDateAndTime[index] = this.formatDate(
      statusType === '1' ? startDate : recStartDate,
      statusType === '1' ? startTime : recStartTime
    );
    this.endDateAndTime[index] = this.formatDate(
      statusType === '1' ? expiryDate : recExpiryDate,
      statusType === '1' ? expiryTime : recExpiryTime
    );
    const threshold = this.adminConfigForm.get([
      'group' + index,
      'threshold',
    ]).value;
    const freeShippingEnabled = this.adminConfigForm.get([
      'group' + index,
      'freeShippingEnabled',
    ]).value;
    

    // this.resetValues(index);
    // this.onConfigChangeTest(index);
    let userToken =
      localStorage.getItem('userToken')?.trim() ??
      (await this.authService.GetToken());

    const reqData: Partial<AdminConfigApiInterface> = {
      websiteCode: this.records[index].websiteCode || undefined,
      threshold: threshold || undefined,
      freeShippingEnabled: statusType === '0' ? freeShippingEnabled : false,
      startDateAndTime: statusType === '1' && this.startDateAndTime[index] ? this.startDateAndTime[index] : '',
      endDateAndTime: statusType === '1' && this.endDateAndTime[index] ? this.endDateAndTime[index] : '',
    };
    this.apiService
      .updateAdminConfig(reqData, userToken)
      .subscribe((data: AdminConfig) => {
        if (data.code == 200) {
          this.toastrService.success(data.message);
          this.formValueChanged[index] = false;
        } else {
          this.toastrService.error(data.message);
        }
      });
  }

  resetValues(index: number) {
    this.adminConfigForm.get(['group' + index, 'startDate']).reset();
    this.adminConfigForm.get(['group' + index, 'startTime']).reset();
    this.adminConfigForm.get(['group' + index, 'expiryDate']).reset();
    this.adminConfigForm.get(['group' + index, 'expiryTime']).reset();
  }

  formatDate(givenDate: string, givenTime: string): string | undefined {
    if (!givenDate) return undefined;
    const date = new Date(givenDate);
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const dayNumber = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${dayOfWeek} ${month} ${dayNumber} ${year} ${givenTime}`;
    return formattedDate;
  }

  convertToDate(dateString: string | null): Date | null {
    let x = dateString ? new Date(dateString) : null;
    return x;
  }

  convertToRecordInterface(
    item: Partial<AdminConfigApiInterface>
  ): Partial<RecordInterface> {
    const startDateAndTime = item.startDateAndTime ?? '';
    const endDateAndTime = item.endDateAndTime ?? '';
    const statusType = startDateAndTime && endDateAndTime ? '1':'0';

    const startDate = startDateAndTime.split(',')[0]?.trim() ?? '';
    const expiryDate = endDateAndTime.split(',')[0]?.trim() ?? '';
    const startTime = startDateAndTime.split(',')[1]?.trim() ?? '';
    const expiryTime = endDateAndTime.split(',')[1]?.trim() ?? '';

    function convertTo24HourFormat(timeString: string): string {
      if (!timeString) return '';
      const [time, period] = timeString.split(' ');
      const [hours, minutes] = time.split(':').map(Number);
      const formattedHours =
        hours === 12 && period === 'AM'
          ? '00'
          : (hours + (period === 'PM' && hours < 12 ? 12 : 0))
              ?.toString()
              .padStart(2, '0');
      const formattedMinutes = minutes?.toString()?.padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    }

    const record: RecordInterface = {
      websiteCode: item.websiteCode ?? '',
      threshold: item.threshold ?? 0,
      freeShippingEnabled: item.freeShippingEnabled ?? false,
      startDate: startDate,
      startTime: convertTo24HourFormat(startTime),
      expiryDate: expiryDate,
      expiryTime: convertTo24HourFormat(expiryTime),
      statusType: statusType
    };
    return record;
  }


 
}
