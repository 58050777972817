import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Redeem } from "./../model/redeem";
import { Coupon } from "./../model/coupon";
import { NgxSpinnerService } from "ngx-spinner";
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.css']
})
export class RedeemComponent implements OnInit {

  redeems: Redeem[] = [];
  coupons: Coupon[] = [];
  redeemedUsers = [];
  couponLists = [];
  selectCoupon = "";
  selectCustomer = "";
  totalItems: number = 0;
  itemsPerPage: number = 20;
  config = {
    itemsPerPage: this.itemsPerPage,
    currentPage: 0,
    totalItems: this.totalItems
  };
  idToken = "";
  public filters:any = {};
  public reqData:any = {};
  constructor(private apiService: ApiService, private authService: AuthService, private loader: NgxSpinnerService) { }

  async ngOnInit(){
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    let page = 1;
    if(this.filters['page'] !== undefined){
      page = parseInt(this.filters['page']);
    }
    this.apiService.getAllRedeemData(this.reqData,this.filters,this.idToken).subscribe((data: Redeem[])=>{
      this.loader.hide();
      if(data['code'] == 200){
        console.log(data);
        this.redeems = data['data'];
        this.totalItems = data['total'];
        this.config = {
          itemsPerPage: this.itemsPerPage,
          currentPage: page,
          totalItems: this.totalItems
        };
      }
    });
  }
  getFilteredValue(){
    this.filters['page'] = 1;
    this.ngOnInit();
  }
  clearFilter(){
    this.filters = {};
    this.ngOnInit();
  }
  pageChanged(event){
    this.config.currentPage = event;
    this.filters['page'] = event;
    this.ngOnInit();
  }
  getDate(value:any){
    return this.apiService.getDate(value);
  }
  // getFilteredValue(filter_type, selectedValue){
  //   if(filter_type == "customer"){
  //     this.selectCoupon = "";
  //   }
  //   if(filter_type == "coupon"){
  //     this.selectCustomer = "";
  //   }
  //   this.loader.show();
  //   this.apiService.getAllRedeemData(this.idToken, filter_type, selectedValue).subscribe((data: Redeem[])=>{
  //     this.loader.hide();
  //     if(data['code'] == 200){
  //       this.redeems = data['data'];
  //     }
  //   });
  // }

}
