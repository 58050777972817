<div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"> </em>
          </div>
          <div>
            Coupon Bulk Update
            <div class="page-title-subheading">Bulk Update</div>
          </div>
        </div>
        <div class="page-title-actions">
          <nav class="" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0);" [routerLink]="['']">Home</a>
              </li>
              <li class="breadcrumb-item">Coupon</li>
              <li class="breadcrumb-item">Bulk Update</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-2 col-sm-12 col-xs-12 col-form-label"
                >Import Coupons <span class="text-danger">*</span></label
              >
              <div class="col-md-5 col-sm-12 col-xs-12">
                <input
                  type="file"
                  #csvReader
                  name="Upload CSV"
                  (click)="csvReader.value = null"
                  id="csvFileUpload"
                  (change)="bulkCouponUpdate($event)"
                  accept=".csv"
                />
                <div *ngIf="errorMessage" class="text-danger">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="col-md-5 text-right">
                <a
                  class="btn btn-info"
                  href="/assets/bulk_update.csv"
                  download="sample.csv"
                  ><em class="pe-7s-download"></em> Download Sample Csv</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="records.length > 0">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">Valid Data</h5>
            <table class="mb-0 table table-hover table-responsive" aria-labelledby="Error">
              <thead>
                <tr>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Coupon Name</th>
                  <th scope="col">Total Limit</th>
                  <th scope="col">User Limit</th>
                  <th scope="col">Validity From</th>
                  <th scope="col">Validity To</th>
                  <th scope="col">Price Slabs</th>
                  <th scope="col">Discount Amount For Repeat User</th>
                  <th scope="col">Discount Type</th>
                  <th scope="col">Different Discount For Discounted Product?</th>
                  <th scope="col">Discount Amount For Discounted Product For Repeat User</th>
                  <th scope="col">Cutoff For Different Discount For Repeat User</th>
                  <th scope="col">Different Discount For New User?</th>
                  <th scope="col">Discount Amount For New User</th>
                  <th scope="col">Discount Amount For Discounted Product For New User</th>
                  <th scope="col">Cutoff For Different Discount For New User</th>
                  <th scope="col">Max discount Amount</th>
                  <th scope="col">Max Amount of Discount for New User</th>
                  <th scope="col">Websites</th>
                  <th scope="col">is Gift Voucher</th>
                  <th scope="col">Status</th>
                  <th scope="col">Influencer Category</th>
                  <th scope="col">Influencer Partner</th>
                  <th scope="col">Partner email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let record of records">
                  <td>{{ record?.coupon_code }}</td>
                  <td>{{ record?.coupon_name }}</td>
                  <td>{{ record?.uses_per_coupon }}</td>
                  <td>{{ record?.uses_per_customer }}</td>
                  <td>{{ record?.valid_from }} - {{ record?.valid_from_time }}
                  </td>
                  <td>{{ record?.valid_to }} - {{ record?.valid_to_time }}</td>
                  <td>{{ record?.price_slabs }}</td>
                  <td>
                    {{ record?.discount_amount }}
                    <p class="error text-danger">
                      {{
                        record?.discount_amount_exceed !== undefined
                          ? record?.discount_amount_exceed
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.discount_type }}</td>
                  <td>{{ record?.different_discount_needed_for_special_discount }}</td>
                  <td>{{ record?.discount_for_discounted_product }}</td>
                  <td>{{ record?.cutoff_for_diff_discount }}</td>
                  <td>{{ record?.different_discount_needed_for_new_user }}</td>
                  <td>
                    {{ record?.discount_amount_new_user }}
                    <p class="error text-danger">
                      {{
                        record?.discount_amount_new_user_error !== undefined
                          ? record?.discount_amount_new_user_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.discount_for_discounted_product_new_user }}</td>
                  <td>{{ record?.cutoff_for_diff_discount_new_user }}</td>
                  <td>{{ record?.discount_limit }}</td>
                  <td>{{ record?.discount_limit_new_user }}</td>
                  <td>{{ record?.website_id }}</td>
                  <td>{{ record?.is_gift_voucher }}</td>
                  <td>{{ record?.status }}</td>
                  <td>{{ record?.influencer_category }}</td>
                  <td>{{ record?.influencer_partner }}</td>
                  <td>{{ record?.partner_email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block text-right card-footer">
            <button
              type="submit"
              class="btn btn-primary btn-lg"
              (click)="updateBulk()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="error_records.length > 0">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">InValid Data</h5>
            <table class="mb-0 table table-hover table-responsive" aria-labelledby="Import Data">
              <thead>
                <tr>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Coupon Name</th>
                  <th scope="col">Total Limit</th>
                  <th scope="col">User Limit</th>
                  <th scope="col">Validity From</th>
                  <th scope="col">Validity To</th>
                  <th scope="col">Price Slabs</th>
                  <th scope="col">Discount Amount For Repeat User</th>
                  <th scope="col">Discount Type</th>
                  <th scope="col">Different Discount For Discounted Product?</th>
                  <th scope="col">Discount Amount For Discounted Product For Repeat User</th>
                  <th scope="col">Cutoff For Different Discount For Repeat User</th>
                  <th scope="col">Different Discount For New User?</th>
                  <th scope="col">Discount Amount For New User</th>
                  <th scope="col">Discount Amount For Discounted Product For New User</th>
                  <th scope="col">Cutoff For Different Discount For New User</th>
                  <th scope="col">Max discount Amount</th>
                  <th scope="col">Max Amount of Discount for New User</th>
                  <th scope="col">Websites</th>
                  <th scope="col">is Gift Voucher</th>
                  <th scope="col">Status</th>
                  <th scope="col">Influencer Category</th>
                  <th scope="col">Influencer Partner</th>
                  <th scope="col">Partner email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let record of error_records">
                  <td>
                    {{ record?.coupon_code }}
                    <p class="error text-danger">
                      {{
                        record?.coupon_code_error !== undefined
                          ? record?.coupon_code_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.coupon_name }}
                  </td>
                  <td>
                    {{ record?.uses_per_coupon }}
                    <p class="error text-danger">
                      {{
                        record?.uses_per_coupon_error !== undefined
                          ? record?.uses_per_coupon_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.uses_per_customer }}
                    <p class="error text-danger">
                      {{
                        record?.uses_per_customer_error !== undefined
                          ? record?.uses_per_customer_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.valid_from }}  {{ record?.valid_from_time }}
                    <p class="error text-danger">
                      {{
                        record?.valid_from_error !== undefined
                          ? record?.valid_from_error + "."
                          : ""
                      }}
                      {{
                        record?.valid_from_time_error !== undefined
                          ? record?.valid_from_time_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.valid_to }}  {{ record?.valid_to_time }}
                    <p class="error text-danger">
                      {{
                        record?.valid_to_error !== undefined
                          ? record?.valid_to_error + "."
                          : ""
                      }}
                      {{
                        record?.valid_to_time_error !== undefined
                          ? record?.valid_to_time_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.price_slabs }}
                    <p class="error text-danger">
                      {{
                        record?.price_slabs_error !== undefined
                          ? record?.price_slabs_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.discount_amount }}
                    <p class="error text-danger">
                      {{
                        record?.discount_amount_error !== undefined
                          ? record?.discount_amount_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.discount_type }}
                    <p class="error text-danger">
                      {{
                        record?.discount_type_error !== undefined
                          ? record?.discount_type_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.different_discount_needed_for_special_discount }}
                    <p class="error text-danger">
                      {{
                        record?.different_discount_needed_for_special_discount_error !== undefined
                          ? record?.different_discount_needed_for_special_discount_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.discount_for_discounted_product }}
                    <p class="error text-danger">
                      {{
                        record?.discount_for_discounted_product_error !==
                        undefined
                          ? record?.discount_for_discounted_product_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.cutoff_for_diff_discount }}
                    <p class="error text-danger">
                      {{
                        record?.cutoff_for_diff_discount_error !== undefined
                          ? record?.cutoff_for_diff_discount_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.different_discount_needed_for_new_user }}
                    <p class="error text-danger">
                      {{
                        record?.different_discount_needed_for_new_user_error !== undefined
                          ? record?.different_discount_needed_for_new_user_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.discount_amount_new_user }}
                    <p class="error text-danger">
                      {{
                        record?.discount_amount_new_user_error !== undefined
                          ? record?.discount_amount_new_user_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.discount_for_discounted_product_new_user }}
                    <p class="error text-danger">
                      {{
                        record?.discount_for_discounted_product_new_user_error !==
                        undefined
                          ? record?.discount_for_discounted_product_new_user_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.cutoff_for_diff_discount_new_user }}
                    <p class="error text-danger">
                      {{
                        record?.cutoff_for_diff_discount_new_user_error !== undefined
                          ? record?.cutoff_for_diff_discount_new_user_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.discount_limit }}
                    <p class="error text-danger">
                      {{
                        record?.discount_limit_error !== undefined
                          ? record?.discount_limit_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.discount_limit_new_user }}
                    <p class="error text-danger">
                      {{
                        record?.discount_limit_new_user_error !== undefined
                          ? record?.discount_limit_new_user_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.website_id }}
                    <p class="error text-danger">
                      {{
                        record?.website_id_error !== undefined
                          ? record?.website_id_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.is_gift_voucher }}
                    <p class="error text-danger">
                      {{
                        record?.is_gift_voucher_error !== undefined
                          ? record?.is_gift_voucher_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.status }}
                    <p class="error text-danger">
                      {{
                        record?.status_error !== undefined
                          ? record?.status_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>{{ record?.influencer_category }}
                    <p class="error text-danger">
                      {{
                        record?.influencer_category_error !== undefined
                          ? record?.influencer_category_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.influencer_partner }}
                    <p class="error text-danger">
                      {{
                        record?.influencer_partner_error !== undefined
                          ? record?.influencer_partner_error
                          : ""
                      }}
                    </p>
                  </td>
                  <td>
                    {{ record?.partner_email }}
                    <p class="error text-danger">
                      {{
                        record?.partner_email_error !== undefined
                          ? record?.partner_email_error
                          : ""
                      }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
