<div class="app-container">
  <div class="h-100">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <div class="mx-auto app-login-box col-md-8">
          <div class="app-logo-inverse mx-auto mb-3"></div>
          <div class="modal-dialog w-100 mx-auto">
            <div class="modal-content">
              <div class="modal-body">
                <div class="h5 modal-title text-center">
                  <h4 class="mt-2">
                    <div>Enter OTP sent to your registered email ID</div>
                  </h4>
                </div>
                <div class="form-row">
                  <div class="col-md-12">
                    <div class="position-relative form-group">
                      <input name="otp" placeholder="Enter OTP" type="text" class="form-control" #otp
                        required>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer clearfix">
  
                <div class="float-left">
                  <button class="btn btn-primary"
                  (click)="authService.verifyOTP(otp.value)">
                 Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>