import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  locations = [];
  constructor(
    public authService: AuthService,
    private router: Router,
    public apiService: ApiService
  ) {
    if (!this.apiService.location) {
      // const locations = this.apiService.locations;
      console.log(this.apiService.locations[0].value);
      this.apiService.location = this.apiService.locations[0].value;
    }
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['dashboard']);
    }
    localStorage.removeItem('item');
  }

  changeLocation(location) {
    this.apiService.location = location;
  }
}
