<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>
          {{ addEdit }} Coupon{{ duplicate ? "(" + originalCoupon + ")" : "" }}
          <div class="page-title-subheading"></div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['/coupon/']">Coupon</a>
            </li>
            <li class="active breadcrumb-item" aria-current="page">
              {{ addEdit }} Coupon
            </li>
          </ol>
        </nav>
        <br />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <form [formGroup]="couponForm" (ngSubmit)="onSubmit(couponForm.value)" (keydown.enter)="$event.preventDefault()"
        autocomplete="off" novalidate>
        <div class="main-card mb-3 card">
          <div class="card-header">
            Coupon Information
            <div class="btn-actions-pane-right" *ngIf="auto_gen_key">
              <a href="javascript:void(0)" class="btn btn-info" (click)="downloadAllRelated(auto_gen_key)">
                <span class="btn-icon-wrapper pr-2 opacity-7">
                  <em class="pe-7s-download"></em>
                </span>
                Export all related
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-9">
                <div class="position-relative row form-group">
                  <label for="coupon_type" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Coupon Type<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                      <li class="nav-item">
                        <a role="tab" class="nav-link {{
                            formValue?.coupon_trade_type?.value == 0
                              ? 'active'
                              : ''
                          }}" id="tab-0" data-toggle="tab" href="#" aria-selected="true" (click)="getTradeType(0)">
                          <span>Trade</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a role="tab" class="nav-link  {{
                            formValue?.coupon_trade_type?.value == 1
                              ? 'active'
                              : ''
                          }}" id="tab-1" data-toggle="tab" href="#" aria-selected="false" (click)="getTradeType(1)">
                          <span>Promotion</span>
                        </a>
                      </li>
                    </ul>
                    <span *ngIf="
                        isValid && formValue.coupon_trade_type.errors?.required
                      ">
                      <div class="error text-danger">
                        Please select coupon type.
                      </div>
                    </span>
                  </div>
                </div>
                <div class="position-relative row form-group">
                  <label for="coupon_type" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Rule Type<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <select class="form-control" #typeCoupon formControlName="coupon_type"
                      (change)="getCouponType(typeCoupon.value)" [value]="selected_coupon_type"
                      [(ngModel)]="selected_coupon_type">
                      <option *ngFor="let ruleType of couponRuleType | keyvalue" [value]="ruleType.key">
                        {{ ruleType.value }}
                      </option>
                    </select>
                    <span *ngIf="isValid && formValue.coupon_type.errors?.required">
                      <div class="error text-danger">
                        Please select rule type.
                      </div>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="status" class="col-md-3 col-sm-12 col-xs-12 col-form-label">
                    Visibility<span class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <label class="switch">
                      <input type="checkbox" formControlName="is_visible" data-toggle="toggle" data-size="normal" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <ng-container *ngIf="couponType == 0">
                  <div class="form-group row">
                    <label for="status" class="col-md-3 col-sm-12 col-xs-12 col-form-label">
                      Is gift Voucher<span class="text-danger">*</span></label>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                      <label class="switch">
                        <input type="checkbox" formControlName="is_gift_voucher" data-toggle="toggle"
                          data-size="normal" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group row">
                  <label for="coupon_name" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Coupon Name<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <input type="text" formControlName="coupon_name" placeholder="Enter Coupon Name" name="coupon_name"
                      class="form-control" />
                    <span *ngIf="isValid && formValue.coupon_name.errors?.required">
                      <div class="error text-danger">
                        This field is required!!!
                      </div>
                    </span>
                  </div>
                </div>
                <div class="form-group row" *ngIf="couponType == 1">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label"></label>
                  <div class="col-md-9">
                    <div class="position-relative form-check form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="coupon" name="coupon" [value]="0"
                          (click)="changeValidationOfCouponCode(0, true)" />
                        Specific Coupon Code
                      </label>
                    </div>
                    <div class="position-relative form-check form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="coupon" name="coupon" [value]="1"
                          (click)="changeValidationOfCouponCode(1, true)" />
                        No Coupon Code
                      </label>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="couponType == 0 || formValue.coupon.value == 0">
                  <div class="form-group row">
                    <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Coupon Code<span
                        class="text-danger">*</span></label>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                      <input type="text" formControlName="coupon_code" placeholder="Enter Coupon Code"
                        name="coupon_code" class="form-control input-upper" />
                      <span *ngIf="
                          isValid && formValue.coupon_code.errors?.required
                        ">
                        <div class="error text-danger">{{ coupon_text }}</div>
                      </span>
                      <span *ngIf="isValid && formValue.coupon_code.errors?.pattern">
                        <div class="error text-danger">
                          Please enter only alphanumeric values.
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="coupon_id == undefined && couponType != 2">
                    <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label"></label>
                    <div class="col-md-9">
                      <div class="position-relative form-check form-check-inline">
                        <label class="form-check-label">
                          <input type="checkbox" #checkboxes class="form-check-input" name="auto_generate" [value]="1"
                            (change)="changeBundleType($event)" />
                          Use Auto Generation
                        </label>
                      </div>
                      <div class="position-relative form-check form-check-inline">
                        <label class="form-check-label">
                          <input type="checkbox" #checkboxes class="form-check-input" name="auto_generate_csv"
                            [value]="2" (change)="changeBundleType($event)" />
                          Upload Csv
                        </label>
                      </div>
                      <span>
                        <div class="">
                          If you select and save the rule you will be able to
                          generate multiple coupon codes.
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="auto_gen_key">
                    <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label"></label>
                    <div class="col-md-9">
                      <input type="checkbox" formControlName="update_all_related" />
                      Update all related coupons
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="couponType == 2">
                  <div class="form-group row">
                    <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Bank Offer Type<span
                        class="text-danger">*</span></label>
                    <div class="col-md-9">
                      <div class="position-relative form-check form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" formControlName="bank_offer_type"
                            name="bank_offer_type" [value]="1" (click)="changeOfferType(1)" />
                          Card Bin
                        </label>
                      </div>
                      <div class="position-relative form-check form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" formControlName="bank_offer_type"
                            name="bank_offer_type" [value]="2" (click)="changeOfferType(2)" />
                          Address Mapper
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="showCardBin">
                    <label for="card_bin" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Card Bin<span
                        class="text-danger">*</span></label>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                      <div class="editor-tag" (click)="focusTagInput()">
                        <span class="tag-item" *ngFor="let card_bin of card_bins">
                          {{ card_bin }}
                          <span class="remove-tag" (click)="removeTag(card_bin)">×</span>
                        </span>
                        <input type="text" #tagInput formControlName="card_bin"
                          placeholder="Enter card bin with comma separated" name="card_bin" (keyup)="onKeyUp($event)"
                          (keypress)="numberOnly($event)" class="form-control input-tag" />
                      </div>
                      <span *ngIf="
                          formValue.coupon_type.value == 2 &&
                          isValid &&
                          card_bins.length == 0
                        ">
                        <div class="error text-danger">
                          Please enter card bin
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="coupon_image" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Coupon Image</label>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                      <input formControlName="coupon_image" type="file" (change)="onFileChange($event)" />
                      <div class="imagePreview mt-10" *ngIf="coupon_image && coupon_image !== ''">
                        <img [src]="coupon_image" id="coupon_image" [alt]="" width="100" height="100" />
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="couponType == 1 && formValue.coupon.value == 1 && (discountTypeValues[0] == 3 || discountTypeValues[0]== 4 || discountTypeValues[0] == 5)">
                  <div class="position-relative row form-group">
                    <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Price consideration for calculation<span
                        class="text-danger">*</span></label>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                      <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                        <li class="nav-item">
                          <a role="tab" class="nav-link {{
                                    formValue?.onOsp?.value == false
                                      ? 'active'
                                      : ''
                                  }}" id="tab-0" data-toggle="tab" href="#" aria-selected="true"
                            (click)="getPriceType(false)">
                            <span>RRP</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a role="tab" class="nav-link  {{
                                    formValue?.onOsp?.value == true
                                      ? 'active'
                                      : ''
                                  }}" id="tab-1" data-toggle="tab" href="#" aria-selected="false"
                            (click)="getPriceType(true)">
                            <span>OSP</span>
                          </a>
                        </li>
                      </ul>
                      <span *ngIf="
                              isValid &&  formValue?.onOsp.errors?.required
                              ">
                        <div class="error text-danger">
                          Please select Price consideration.
                        </div>
                      </span>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Websites<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <select class="form-control" multiple [(ngModel)]="websiteSelect"
                      [ngModelOptions]="{ standalone: true }" (change)="getStores()">
                      <option *ngFor="let website of websites | keyvalue" [value]="website.key">
                        {{ website.value }}
                      </option>
                    </select>
                    <span *ngIf="isValid && formValue.website_id.errors?.required">
                      <div class="error text-danger">
                        Please select a website.
                      </div>
                    </span>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label"
                    style="align-self: center">From<span class="text-danger">*</span></label>
                  <div class="col-md-5 col-sm-12 col-xs-12" style="align-self: center">
                    <input type="text" formControlName="valid_from" placeholder="Enter Start Date" name="valid_from"
                      class="form-control" bsDatepicker [bsConfig]="{
                        isAnimated: true,
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false,
                        containerClass: 'theme-dark-blue',
                        adaptivePosition: true
                      }" [minDate]="minDate" [bsValue]="valid_from" (bsValueChange)="onValueChange($event)" />
                    <span *ngIf="isValid && formValue.valid_from.errors?.required">
                      <div class="error text-danger">
                        Please enter start date.
                      </div>
                    </span>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12">
                    <select class="form-control" #validFromTime formControlName="valid_from_time" name="valid_from_time"
                      [value]="select_valid_from_time" (change)="setSelectTime(true, validFromTime.value)">
                      <option *ngFor="let time of timeListFrom" [value]="time.value">
                        {{ time.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label"
                    style="align-self: center">To<span class="text-danger">*</span></label>
                  <div class="col-md-5 col-sm-12 col-xs-12" style="align-self: center">
                    <input type="text" formControlName="valid_to" placeholder="Enter Expiry Date" name="valid_to"
                      class="form-control" bsDatepicker [bsConfig]="{
                        isAnimated: true,
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false,
                        containerClass: 'theme-dark-blue',
                        adaptivePosition: true
                      }" [minDate]="expMinDate" [bsValue]="valid_to" />
                    <span *ngIf="isValid && formValue.valid_to.errors?.required">
                      <div class="error text-danger">
                        Please enter expiry date.
                      </div>
                    </span>
                    <span *ngIf="
                        isValid && formValue.valid_to.errors?.lessthanstart
                      ">
                      <div class="error text-danger">
                        Expiry date should greater than start date.
                      </div>
                    </span>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12">
                    <select class="form-control" #validToTime formControlName="valid_to_time" name="valid_to_time"
                      [attr.disabled]="disable_valid_to_time" [value]="select_valid_to_time"
                      (change)="setSelectTime(false, validToTime.value)">
                      <option *ngFor="let time of timeListTo" [value]="time.value">
                        {{ time.text }}
                      </option>
                    </select>
                    <!-- <timepicker
                      formControlName="valid_from_time"
                      name="valid_from_time"
                      [minuteStep]="30"
                      [showMeridian]="true"
                    ></timepicker> -->
                  </div>
                </div>
                <div class="form-group row">
                  <label for="status" class="col-md-3 col-sm-12 col-xs-12 col-form-label">
                    Active<span class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <label class="switch">
                      <input type="checkbox" formControlName="status" checked="" data-toggle="toggle"
                        data-size="normal" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Uses per Coupon<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <input type="text" formControlName="total_redemption_limit" placeholder="Enter Redemption Limit"
                      name="total_redemption_limit" class="form-control" (keypress)="numberOnly($event)" />

                    <span *ngIf="
                        isValid &&
                        formValue.total_redemption_limit.errors?.required
                      ">
                      <div class="error text-danger">
                        Please enter total coupon usage limit.
                      </div>
                    </span>
                    <span *ngIf="
                        isValid &&
                        formValue.total_redemption_limit.errors?.pattern
                      ">
                      <div class="error text-danger">
                        Please enter only alphanumeric values.
                      </div>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Uses per Customer<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <input type="text" formControlName="redemption_per_user" placeholder="Enter User Redemption Limit"
                      name="redemption_per_user" class="form-control" (keypress)="numberOnly($event)" />
                    <span *ngIf="
                        isValid &&
                        formValue.redemption_per_user.errors?.required
                      ">
                      <div class="error text-danger">
                        Please enter user redemption limit for coupon.
                      </div>
                    </span>
                    <span *ngIf="
                        isValid && formValue.redemption_per_user.errors?.pattern
                      ">
                      <div class="error text-danger">
                        Please enter only alphanumeric values.
                      </div>
                    </span>
                    <span *ngIf="
                        isValid &&
                        formValue.redemption_per_user.errors?.lessthantotal
                      ">
                      <div class="error text-danger">
                        User redemption limit should less than total limit.
                      </div>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="priority" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Priority<span
                      class="text-danger">*</span></label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <input type="text" formControlName="priority" placeholder="Enter priority level" name="priority"
                      class="form-control" (keypress)="numberOnly($event)" [attr.disabled]="formValue.priority.value==2 && couponType == 0 ? '' : null"/>
                    <span *ngIf="isValid && formValue.priority.errors?.required">
                      <div class="error text-danger">
                        Please enter priority level.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.priority.errors?.pattern">
                      <div class="error text-danger">
                        Please enter only alphanumeric values.
                      </div>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-4 col-sm-4 col-xs-4 offset-md-3">
                    <h5 class="card-title">Offer Message</h5>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="offer_message" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Default</label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <textarea formControlName="offer_msg" placeholder="Enter Default Offer Message" name="offer_msg"
                      class="form-control"></textarea>
                  </div>
                </div>
                <div *ngIf="websiteSelect.length > 0">
                  <div class="form-group row" *ngFor="let website of selected_website">
                    <label for="Websites" class="col-md-3 col-sm-12 col-xs-12 col-form-label">{{ websites[website]
                      }}</label>
                    <div class="form-row col-md-9">
                      <ng-container *ngFor="let store of storeIds">
                        <div class="col-md-6" *ngIf="website == store.website_id">
                          <div class="position-relative form-group" dir="{{ store.langcode == 'ar' ? 'rtl' : '' }}">
                            <label for="exampleEmail11" class="">{{
                              store.language
                              }}</label>
                            <textarea placeholder="Enter Offer Message in {{
                                store.language
                              }}" class="form-control" [(ngModel)]="offer_message[store.id]"
                              [ngModelOptions]="{ standalone: true }"></textarea>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-4 col-sm-4 col-xs-4 offset-md-3">
                    <h5 class="card-title">Description</h5>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="description" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Default</label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <textarea formControlName="description" placeholder="Enter Default Coupon Description"
                      name="description" class="form-control"></textarea>
                  </div>
                </div>
                <div *ngIf="websiteSelect.length > 0">
                  <div class="form-group row" *ngFor="let website of selected_website">
                    <label for="Websites" class="col-md-3 col-sm-12 col-xs-12 col-form-label">{{ websites[website]
                      }}</label>
                    <div class="form-row col-md-9">
                      <ng-container *ngFor="let store of storeIds">
                        <div class="col-md-6" *ngIf="website == store.website_id">
                          <div class="position-relative form-group" dir="{{ store.langcode == 'ar' ? 'rtl' : '' }}">
                            <label for="exampleEmail11" class="">{{
                              store.language
                              }}</label>
                            <textarea placeholder="Enter Coupon Description in {{
                                store.language
                              }}" class="form-control" [(ngModel)]="descriptions[store.id]"
                              [ngModelOptions]="{ standalone: true }"></textarea>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-4 col-sm-4 col-xs-4 offset-md-3">
                    <h5 class="card-title">Terms and Conditions</h5>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="description" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Default</label>
                  <div class="col-md-9 col-sm-12 col-xs-12">
                    <textarea formControlName="terms" placeholder="Enter Default Terms and conditions" name="terms"
                      class="form-control"></textarea>
                  </div>
                </div>
                <div *ngIf="websiteSelect.length > 0">
                  <div class="form-group row" *ngFor="let website of selected_website">
                    <label for="Websites" class="col-md-3 col-sm-12 col-xs-12 col-form-label">{{ websites[website]
                      }}</label>
                    <div class="form-row col-md-9">
                      <ng-container *ngFor="let store of storeIds">
                        <div class="col-md-6" *ngIf="website == store.website_id">
                          <div class="position-relative form-group" dir="{{ store.langcode == 'ar' ? 'rtl' : '' }}">
                            <label for="exampleEmail11" class="">{{
                              store.language
                              }}</label>
                            <textarea placeholder="Enter Terms and conditions {{
                                store.language
                              }}" class="form-control" [(ngModel)]="terms_conditions[store.id]"
                              [ngModelOptions]="{ standalone: true }"></textarea>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-card mb-3 card" *ngIf="couponType != 0">
          <div class="card-header">Conditions</div>
          <app-condition-form [coupon_conditions]="coupon_conditions"
            (couponConditions)="getConditions($event)"></app-condition-form>
        </div>
        <div class="main-card mb-3 card" *ngIf="formValue.auto_generate.value && formValue.coupon.value == 0">
          <div class="card-header">Manage Coupon Codes</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-9" *ngIf="uploadCsv">
                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Import Coupons <span
                      class="text-danger">*</span></label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="file" #csvReader name="Upload CSV" id="csvFileUpload" (change)="uploadCoupons($event)"
                      accept=".csv" />
                      <div *ngIf="errorMessage" class="text-danger">
                        {{ errorMessage }}
                      </div>
                    <span *ngIf="
                        isValid && formValue.coupon_bundle.errors?.required
                      ">
                      <div class="error text-danger">
                        Please upload coupons.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.coupon_bundle.errors?.onlycsv">
                      <div class="error text-danger">
                        Please import valid .csv file.
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-9" *ngIf="!uploadCsv">
                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Code Length <span
                      class="text-danger">*</span></label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="text" formControlName="code_length" placeholder="Enter Coupon Length"
                      name="code_length" class="form-control" (keyup)="getCouponCount()" />
                    <span>
                      <div>Excluding prefix and suffix.</div>
                    </span>
                    <span *ngIf="isValid && formValue.code_length.errors?.required">
                      <div class="error text-danger">
                        Please enter coupon length.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.code_length.errors?.pattern">
                      <div class="error text-danger">
                        Please enter only alphanumeric values.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.code_length.errors?.min">
                      <div class="error text-danger">
                        Please enter non zero values.
                      </div>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Code Format</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <select formControlName="code_format" class="form-control" (change)="getCouponCount()">
                      <option *ngFor="let format of code_format" [value]="format.value">
                        {{ format.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Code Prefix</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="text" formControlName="code_prefix" placeholder="Enter Coupon Code Prefix"
                      name="code_prefix" class="form-control" (keyup)="getCouponCount()" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Code Suffix</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="text" formControlName="code_suffix" placeholder="Enter Coupon Code Suffix"
                      name="code_suffix" class="form-control" (keyup)="getCouponCount()" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Coupon Qty <span
                      class="text-danger">*</span></label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="text" formControlName="coupon_qty" placeholder="Enter Coupon Qty" name="coupon_qty"
                      class="form-control" (keyup)="checkCouponCount()" />
                    <span *ngIf="no_of_coupon_can_create > 0">
                      <div>
                        Maximum
                        <strong>{{ no_of_coupon_can_create }}</strong> coupon
                        can be created.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.coupon_qty.errors?.required">
                      <div class="error text-danger">
                        Please enter coupon quantity.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.coupon_qty.errors?.pattern">
                      <div class="error text-danger">
                        Please enter only numeric values.
                      </div>
                    </span>
                    <span *ngIf="isValid && formValue.coupon_qty.errors?.min">
                      <div class="error text-danger">
                        Please enter non zero values.
                      </div>
                    </span>
                    <span *ngIf="
                        no_of_coupon_can_create > 0 &&
                        formValue.coupon_qty.errors?.should_less_than
                      ">
                      <div class="error text-danger">
                        Coupon quantity should be less than or equal
                        {{ no_of_coupon_can_create }}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-influencer-section [isValid]="isValid" [influencer_info]="influencer_info"
          (influencerConditions)="getInfluencerConditions($event)"></app-influencer-section>
        <div class="main-card mb-3 card">
          <div class="card-header">
            Error Message Settings
            <div class="btn-actions-pane-right">
              <button class="btn btn-primary" type="button" (click)="addErrorMessage()">
                <em class="fa fa-plus fa-w-20"></em> New Code
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="main-card mb-3 card" *ngFor="let error of error_messages; let i = index">
              <div class="card-body">
                <h5 class="card-title text-right">
                  <a href="javascript:void(0)" title="Remove" (click)="removeErrorMessage(i)"><em
                      class="pe-7s-trash"></em>&nbsp;Remove</a>
                </h5>
                <div class="position-relative row form-group">
                  <div class="col-md-3">
                    <div class="position-relative form-group">
                      <label for="code" class="">Code</label>
                      <select class="form-control" [(ngModel)]="error.code" [ngModelOptions]="{ standalone: true }">
                        <option value="">Select Error Code</option>
                        <option *ngFor="let global_message of global_error_message" [value]="global_message.code">
                          {{ global_message.code }} -
                          {{ global_message.msg.default }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div *ngIf="websiteSelect.length > 0">
                      <div class="form-group row" *ngFor="let website of selected_website">
                        <label for="Websites" class="col-md-3 col-sm-12 col-xs-12 col-form-label">{{ websites[website]
                          }}</label>
                        <div class="form-row col-md-9">
                          <ng-container *ngFor="let store of storeIds">
                            <div class="col-md-6" *ngIf="website == store.website_id">
                              <div class="position-relative form-group" dir="{{ store.langcode == 'ar' ? 'rtl' : '' }}">
                                <label for="exampleEmail11" class="">{{
                                  store.language
                                  }}</label>
                                <input type="text" placeholder="Enter Message in {{
                                    store.language
                                  }}" class="form-control" [(ngModel)]="error.msg[store.id]"
                                  [ngModelOptions]="{ standalone: true }" />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-card mb-3 card">
          <div class="card-header mb-3">
            Rules
            <div class="btn-actions-pane-right">
              <button class="btn btn-primary" type="button" (click)="addRule()" *ngIf="couponType == 0">
                <span class="btn-icon-wrapper pr-2 opacity-7">
                  <em class="fa fa-plus fa-w-20"></em>
                </span>
                Add Rule
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12" *ngIf="ruleData.controls.length > 0">
                <div class="card mb-3" *ngFor="let rule of ruleData.controls; let i = index" [formGroup]="rule">
                  <div class="card-header">
                    <div class="btn-actions-pane-right">
                      <a type="button" *ngIf="i != 0" (click)="removeRule(i)">
                        <em class="pe-7s-trash"></em>&nbsp;Remove
                      </a>
                    </div>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-md-12 ml-4">
                        <div class="form-group row mt-2">
                          <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Subtotal Min Val
                            <span class="text-danger">*</span></label>
                          <div class="col-md-5 col-sm-12 col-xs-12">
                            <input type="text" formControlName="bag_min_value"
                              placeholder="Enter Minimum Subtotal Value" name="bag_min_value" class="form-control"
                              (change)="checkPrice(i)" />
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_min_value
                                  .errors?.required
                              ">
                              <div class="error text-danger">
                                This is required
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_min_value
                                  .errors?.pattern
                              ">
                              <div class="error text-danger">
                                Only Numeric!!!
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_min_value
                                  .errors?.greaterThanPrevMaxVal
                              ">
                              <div class="error text-danger">
                                Enter greater value than previous maximum value
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Subtotal Max Val
                            <span class="text-danger">*</span></label>
                          <div class="col-md-5 col-sm-12 col-xs-12">
                            <input type="text" formControlName="bag_max_value"
                              placeholder="Enter Maximum Subtotal Value" name="bag_max_value" class="form-control"
                              (change)="checkPrice(i)" />
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_max_value
                                  .errors?.required
                              ">
                              <div class="error text-danger">
                                This is required
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_max_value
                                  .errors?.pattern
                              ">
                              <div class="error text-danger">
                                Only Numeric!!!
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_max_value
                                  .errors?.greaterThanMinVal
                              ">
                              <div class="error text-danger">
                                Enter greater value than minimum value
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].bag_max_value
                                  .errors?.shouldNotZeroForaddingMore
                              ">
                              <div class="error text-danger">
                                Value should be greater than zero for adding
                                more rules!!
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount Type
                            <span class="text-danger">*</span></label>
                          <div class="col-md-5 col-sm-12 col-xs-12">
                            <select class="form-control" #typeSelect formControlName="discount_type"
                              (change)="changeDiscountType(i, typeSelect.value)">
                              <option *ngFor="let types of discountTypes" [value]="types.value">
                                {{ types.text }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <ng-container *ngIf="couponType == 0 || formValue.coupon.value == 0">
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Different Discount For New User?
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <label class="switch">
                                <input type="checkbox" formControlName="different_discount_needed_for_new_user"
                                  data-toggle="toggle" data-size="normal" />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </ng-container>
                        <div class="form-group row">
                          <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount Amount
                            {{
                            discountTypeValues[i] == 3 ||
                            discountTypeValues[i] == 1 ||
                            discountTypeValues[i] == 4
                            ? "(In %)"
                            : ""
                            }}
                            {{
                            ruleData.controls[i]["controls"]
                            .different_discount_needed_for_new_user.value ==
                            1
                            ? "For Repeat User"
                            : ""
                            }}<span class="text-danger">*</span></label>
                          <div class="col-md-5 col-sm-12 col-xs-12">
                            <input type="text" formControlName="discount_value" placeholder="Enter Discount Value"
                              name="discount_value" class="form-control" />
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].discount_value
                                  .errors?.required
                              ">
                              <div class="error text-danger">
                                This is required
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].discount_value
                                  .errors?.pattern
                              ">
                              <div class="error text-danger">
                                Only Numeric!!!
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].discount_value
                                  .errors?.lessThanHundred
                              ">
                              <div class="error text-danger">
                                For Percent type discount value should be less
                                than or equal to 100
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row" *ngIf="
                            ruleData.controls[i]['controls']
                              .different_discount_needed_for_new_user.value ==
                              1 &&
                            (couponType == 0 || formValue.coupon.value == 0)
                          ">
                          <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount Amount
                            {{
                            discountTypeValues[i] == 3 ||
                            discountTypeValues[i] == 1 ||
                            discountTypeValues[i] == 4
                            ? "(In %) for New User"
                            : "for New User"
                            }}<span class="text-danger">*</span></label>
                          <div class="col-md-5 col-sm-12 col-xs-12">
                            <input type="text" formControlName="discount_value_new_user"
                              placeholder="Enter Discount Value New User" name="discount_value_new_user"
                              class="form-control" />
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls']
                                  .discount_value_new_user.errors?.required
                              ">
                              <div class="error text-danger">
                                This is required
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls']
                                  .discount_value_new_user.errors?.pattern
                              ">
                              <div class="error text-danger">
                                Only Numeric!!!
                              </div>
                            </span>
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls']
                                  .discount_value_new_user.errors
                                  ?.lessThanHundred
                              ">
                              <div class="error text-danger">
                                For Percent type discount value should be less
                                than or equal to 100
                              </div>
                            </span>
                          </div>
                        </div>
                        <ng-container *ngIf="discountTypeValues[i] == 1">
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Apply Different Discount For
                              Discounted Product
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <label class="switch">
                                <input type="checkbox" formControlName="different_discount_needed_for_special_discount"
                                  data-toggle="toggle" data-size="normal" />
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div class="form-group row" *ngIf="
                              discountTypeValues[i] == 1 &&
                              ruleData.controls[i]['controls']
                                .different_discount_needed_for_special_discount
                                .value == 1
                            ">
                            <div *ngIf="
                                ruleData.controls[i]['controls']
                                  .different_discount_needed_for_new_user
                                  .value == 1
                              " class="col-md-12 col-sm-12 col-xs-12 col-form-label" style="font-weight: bold">
                              For Repeat User
                            </div>
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount Threhold(In %)
                              <em class="fa fa-info-circle"
                                title="This is the discount threshold for identifying if an item is discounted. eg if discount threshold is 10%, items>10% discount will be considered as discounted for this rule"></em>
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="cutoff_for_discount_for_discounted_product"
                                placeholder="Enter Discount Value" name="cutoff_for_discount_for_discounted_product"
                                class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    .cutoff_for_discount_for_discounted_product
                                    .errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="form-group row" *ngIf="
                              discountTypeValues[i] == 1 &&
                              ruleData.controls[i]['controls']
                                .different_discount_needed_for_special_discount
                                .value == 1
                            ">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount For Discounted
                              Product(In %)
                              <em class="fa fa-info-circle"
                                title="Discount which will be applied on all products which have existing discount>discount threshold."></em>
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="discount_for_discounted_product"
                                placeholder="Enter Discount Value" name="discount_for_discounted_product"
                                class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    .discount_for_discounted_product.errors
                                    ?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.discount_for_discounted_product?.errors
                                    ?.lessthanMainDiscount
                                ">
                                <div class="error text-danger">
                                  Value should be less than the Discount Amount
                                </div>
                              </span>
                            </div>
                          </div>
                        </ng-container>
                        <div class="form-group row">
                          <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Max Amount of Discount
                            {{
                            ruleData.controls[i]["controls"]
                            .different_discount_needed_for_new_user.value ==
                            1
                            ? "For Repeat User"
                            : ""
                            }}
                            <span class="text-danger">*</span></label>
                          <div class="col-md-5 col-sm-12 col-xs-12">
                            <input type="text" formControlName="discount_limit" placeholder="Enter Discount Lmit"
                              name="discount_limit" class="form-control" />
                            <span *ngIf="
                                isValid &&
                                ruleData.controls[i]['controls'].discount_limit
                                  .errors?.pattern
                              ">
                              <div class="error text-danger">
                                Only Numeric!!!
                              </div>
                            </span>
                          </div>
                        </div>
                        <ng-container *ngIf="
                            discountTypeValues[i] == 1 &&
                            (couponType == 0 || formValue.coupon.value == 0)
                          ">
                          <div class="form-group row" *ngIf="
                              discountTypeValues[i] == 1 &&
                              ruleData.controls[i]['controls']
                                .different_discount_needed_for_new_user.value ==
                                1 &&
                              ruleData.controls[i]['controls']
                                .different_discount_needed_for_special_discount
                                .value == 1
                            ">
                            <div class="col-md-12 col-sm-12 col-xs-12 col-form-label" style="font-weight: bold">
                              For New User
                            </div>
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount Threhold(In %)
                              <em class="fa fa-info-circle"
                                title="This is the discount threshold for identifying if an item is discounted. eg if discount threshold is 10%, items>10% discount will be considered as discounted for this rule"></em>
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="cutoff_for_discount_for_discounted_product_new_user"
                                placeholder="Enter Discount Value for new user"
                                name="cutoff_for_discount_for_discounted_product_new_user" class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    .cutoff_for_discount_for_discounted_product_new_user
                                    .errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="form-group row" *ngIf="
                              discountTypeValues[i] == 1 &&
                              ruleData.controls[i]['controls']
                                .different_discount_needed_for_new_user.value ==
                                1 &&
                              ruleData.controls[i]['controls']
                                .different_discount_needed_for_special_discount
                                .value == 1
                            ">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount For Discounted
                              Product(In %)
                              <em class="fa fa-info-circle"
                                title="Discount which will be applied on all products which have existing discount>discount threshold."></em>
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="discount_for_discounted_product_new_user"
                                placeholder="Enter Discount Value for new user"
                                name="discount_for_discounted_product_new_user" class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    .discount_for_discounted_product_new_user
                                    .errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.discount_for_discounted_product_new_user
                                    ?.errors?.lessthanMainDiscountNewUser
                                ">
                                <div class="error text-danger">
                                  Value should be less than the Discount Amount
                                </div>
                              </span>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="
                            ruleData.controls[i]['controls']
                              .different_discount_needed_for_new_user.value ==
                              1 &&
                            (couponType == 0 || formValue.coupon.value == 0)
                          ">
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Max Amount of Discount for New
                              User
                              <span class="text-danger">*</span></label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="discount_limit_new_user"
                                placeholder="Enter Discount Lmit for New User" name="discount_limit_new_user"
                                class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    .discount_limit_new_user.errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="
                            discountTypeValues[i] == 3 ||
                            discountTypeValues[i] == 5
                          ">
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Max Qty Discount is Applied To
                              (number of
                              pairs)</label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="max_qty_discount" name="max_qty_discount"
                                class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.max_qty_discount.errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Number of X Products
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="buy_qty_for_discount" name="buy_qty_for_discount"
                                class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.buy_qty_for_discount.errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.buy_qty_for_discount.errors
                                    ?.greaterThanZero
                                ">
                                <div class="error text-danger">
                                  Value should be greater than zero!!!
                                </div>
                              </span>
                              <span>
                                <div class="">
                                  For the rule Buy 5 Get 2, It is 5
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Number of Y Products
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="get_qty_for_discount" name="get_qty_for_discount"
                                class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.get_qty_for_discount.errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    ?.get_qty_for_discount.errors
                                    ?.greaterThanZero
                                ">
                                <div class="error text-danger">
                                  Value should be greater than zero!!!
                                </div>
                              </span>
                              <span>
                                <div class="">
                                  For the rule Buy 5 Get 2, It is 2
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">At first apply discount to
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <select class="form-control" formControlName="at_first_discount_applied_to">
                                <option [value]="0">Cheapest Products</option>
                                <option [value]="1">
                                  Most Expensive Products
                                </option>
                              </select>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="
                            discountTypeValues[i] == 2 ||
                            discountTypeValues[i] == 4
                          ">
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Discount Qty Step (Buy N)
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <input type="text" formControlName="discount_qty_step" placeholder="Enter Discount Lmit"
                                name="discount_qty_step" class="form-control" />
                              <span *ngIf="
                                  isValid &&
                                  ruleData.controls[i]['controls']
                                    .discount_qty_step.errors?.pattern
                                ">
                                <div class="error text-danger">
                                  Only Numeric!!!
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">At first apply discount to
                            </label>
                            <div class="col-md-5 col-sm-12 col-xs-12">
                              <select class="form-control" formControlName="at_first_discount_applied_to">
                                <option [value]="0">Cheapest Products</option>
                                <option [value]="1">
                                  Most Expensive Products
                                </option>
                              </select>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-block text-right card-footer">
            <button type="submit" class="btn btn-primary btn-lg" [disabled]="processed">
              {{ button_name }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>