import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { User } from "../model/user";
import { NgxSpinnerService } from "ngx-spinner";
import {AuthService} from '../auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users: User[] = [];
  totalItems: number = 0;
  itemsPerPage: number = 20;
  config = {
    itemsPerPage: this.itemsPerPage,
    currentPage: 0,
    totalItems: this.totalItems
  };
  idToken = "";
  isWrite : boolean = false;
  public filters:any = {};
  public user_id;
  public reqData:any = {};
  public validators = [];
  public userForm: FormGroup;
  public isValid: boolean = false;
  public button_name: string = 'Save';
  public processed: boolean = false;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {}


  async ngOnInit(){
    this.userForm = this.formBuilder.group({
      user_mail: ['', Validators.required],
      user_id: [''],
    });
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    let page = 1;
    if(this.filters['page'] !== undefined){
      page = parseInt(this.filters['page']);
    }
    this.apiService.getAllUserData(this.reqData,this.filters,this.idToken)
    .subscribe((data: User[])=>{
      this.loader.hide();
      if(data['code'] == 200 || data['code'] == 202){
        console.log(data);
        this.isWrite =  data['isWrite'];
        this.users = data['data'];
        this.totalItems = data['total'];
        this.config = {
          itemsPerPage: this.itemsPerPage,
          currentPage: page,
          totalItems: this.totalItems
        };
      }
    });
  }
  getFilteredValue(){
    this.filters['page'] = 1;
    this.ngOnInit();
  }
  clearFilter(){
    this.filters = {};
    this.ngOnInit();
  }
  pageChanged(event){
    this.config.currentPage = event;
    this.filters['page'] = event;
    this.ngOnInit();
  }
  getDate(value:any){
    return this.apiService.getDate(value);
  }
  async onSubmit() {
  console.log("res:", this.userForm.getRawValue());
    const udata = this.userForm.getRawValue();
    this.isValid = true;
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
        this.apiService
        .createUser(udata, userToken)
        .subscribe(async (data) => {
          if (data.code == 200) {
            this.toastrService.success(data.message);
            this.loader.hide();
            this.modalService.dismissAll();
            this.router.navigate(['users']);
          }
          else{
            this.modalService.dismissAll();
            this.router.navigate(['users']);
            this.toastrService.error(data.message);
          }
        });
  }
  openModal(targetModal, user) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });
    if(user == {}){
      this.userForm.patchValue({
        user_mail: '',
        user_id:''
       });
    }else{
    this.userForm.patchValue({
      user_mail: user?.user_mail,
      user_id: user?._id
     });
    }
    }
  get formValue() {
    return this.userForm?.controls;
  }
  deleteUser(user): void {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Approver?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.loader.show();
        this.apiService
          .deleteUser(user._id, userToken)
          .subscribe((data: User) => {
            if (data['code'] == 200) {
              let index = this.users.findIndex((c) => c._id == user._id);
              this.users.splice(index, 1);
              this.loader.hide();
              this.toastrService.success(data['message']);
            } else {
              this.loader.hide();
              this.toastrService.error(data['message']);
            }
          });
      }
    });
  }
}
