<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"> </em>
        </div>
        <div>
          Bulk Coupon Generate Request Lists
          <div class="page-title-subheading">All Bulk Coupon Request</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Bulk Coupon</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title text-right">
            <button type="button" class="btn btn-info" (click)="ngOnInit()">
              <span class="btn-icon-wrapper pr-2 opacity-7">
                <em class="pe-7s-download"></em>
              </span>
              Refresh</button>
          </h5>
          <table class="mb-0 table table-hover" aria-labelledby="Coupon">
            <thead>
              <tr>
                <th scope="col">AutoGen ID</th>
                <th scope="col">Coupon to be generated</th>
                <th scope="col">Coupon generated</th>
                <th scope="col">Retry</th>
                <th scope="col">Max retry</th>
                <th scope="col">Started At</th>
                <th scope="col">Last Updated At</th>
                <th scope="col">Current Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let coupon of coupons | paginate: config">

                <td>{{ coupon?._id }}</td>
                <td>{{ coupon.toBegeneratedCodes }}</td>
                <td>{{ coupon.generatedCodes }}</td>
                <td>{{ coupon.retry }}</td>
                <td>{{ coupon.maxRetry }}</td>
                <td>{{ coupon.created_at | date: "MMM d, y, h:mm:ss a" : 'UTC +4' }}</td>
                <td>{{ coupon.updated_at | date: "MMM d, y, h:mm:ss a" : 'UTC +4' }}</td>
                <td><span class="badge badge-primary">{{ coupon.status }}</span></td>
              </tr>
            </tbody>
          </table>
          <div class="text-right" style="margin: 20px 0" *ngIf="totalItems > itemsPerPage">
            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
