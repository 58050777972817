<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"> </em>
        </div>
        <div>
          Configuration
          <div class="page-title-subheading">Promo Configuration</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Coupon</li>
            <li class="breadcrumb-item">Configuration</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Multiple Auto Applied Coupon</h5>
          <div class="form-group row">
            <label
              for="status"
              class="col-md-3 col-sm-12 col-xs-12 col-form-label"
            >
              Enable
            </label>
            <div class="col-md-9 col-sm-12 col-xs-12">
              <label class="switch">
                <input
                  type="checkbox"
                  [(ngModel)]="multiple_auto_applied"
                  data-toggle="toggle"
                  data-size="normal"
                  (change)="changeValue(multiple_auto_applied ? 1 : 0)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div class="card-header">Global Condition Rule for Coupons</div>
        <app-condition-form [coupon_conditions]="coupon_conditions" (couponConditions)="getConditions($event)">
        </app-condition-form>
        <div class="card-header">List of coupons for which the global Condition rule does not apply</div>
        <div class="card-body mb-3">
          <div class="form-group row">
            <label for="status" class="col-md-2 col-sm-12 col-xs-12 col-form-label">Auto applied Coupons</label>
            <div class="col-md-10 col-sm-12 col-xs-12">
              <angular2-multiselect [data]="couponList" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)">
              </angular2-multiselect>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label for="status" class="col-md-2 col-sm-12 col-xs-12 col-form-label">Manual Coupons</label>
            <div class="col-md-10 col-sm-12 col-xs-12">
              <input type="text" placeholder="Enter Comma Separated Coupons" class="form-control"
                [(ngModel)]="excludeManualCoupons" [ngModelOptions]="{ standalone: true }" />
            </div>
          </div>
        </div>
        <div class="d-block text-right card-footer">
          <button
            class="btn btn-primary"
            type="button"
            (click)="saveGlobalConfig()"
          >
            Save
          </button>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Message Config</h5>
          <div class="form-group row">
            <label
              for="status"
              class="col-md-2 col-sm-12 col-xs-12 col-form-label"
            >
              Auto Coupon</label
            >
            <div class="col-md-2">
              <label class="switch">
                <input
                  type="checkbox"
                  [(ngModel)]="message_config.auto.status"
                  [ngModelOptions]="{ standalone: true }"
                  data-toggle="toggle"
                  data-size="normal"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col-md-4">
              <input
                type="text"
                placeholder="English"
                class="form-control"
                [(ngModel)]="message_config.auto.en"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="col-md-4">
              <input
                type="text"
                placeholder="Arabic"
                dir="rtl"
                class="form-control"
                [(ngModel)]="message_config.auto.ar"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="status"
              class="col-md-2 col-sm-12 col-xs-12 col-form-label"
            >
              Manual Coupon</label
            >
            <div class="col-md-2">
              <label class="switch">
                <input
                  type="checkbox"
                  [(ngModel)]="message_config.manual.status"
                  data-toggle="toggle"
                  data-size="normal"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="col-md-4">
              <input
                type="text"
                placeholder="English"
                class="form-control"
                [(ngModel)]="message_config.manual.en"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="col-md-4">
              <input
                type="text"
                placeholder="Arabic"
                dir="rtl"
                class="form-control"
                [(ngModel)]="message_config.manual.ar"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
        </div>
        <div class="d-block text-right card-footer">
          <button
            class="btn btn-primary"
            type="button"
            (click)="saveCouponTypeNameConfig()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
