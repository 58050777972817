<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>
          Approver list
          <div class="page-title-subheading">All Approver list</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Approver list</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Approver list</h5>
          <h5 class="card-title text-right"  *ngIf="
          isWrite == true
        ">
            <button
              type="button"
              class="btn btn-primary"
              (click)="openModal(userModal, {})"
            >
              <span class="btn-icon-wrapper pr-2 opacity-7">
                <em class="fa fa-plus fa-w-20"></em>
              </span>
              New Approver
            </button>
          </h5>
          <table class="mb-0 table table-hover" aria-labelledby="users">
            <thead>
              <tr>
                <th scope="col" class="width-15">Email</th>
                <th scope="col">
                  Created Date
                </th>
                <th scope="col" *ngIf=" isWrite == true ">
                 Edit
                </th>
                <th scope="col"  *ngIf=" isWrite == true ">
                  Delete
                 </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="reqData.user_mail"
                  />
                </td>
                <td>
                  <input
                    type="button"
                    class="btn"
                    value="Reset"
                    (click)="clearFilter()"
                  />
                  &nbsp;&nbsp;<input
                    type="button"
                    class="btn btn-primary"
                    value="Submit"
                    (click)="getFilteredValue()"
                  />
                </td>
              </tr>
              <tr *ngFor="let user of users | paginate: config">
                
                <td>{{ user.user_mail }}</td>
                <td>
                  {{ user.created_at }}
                </td>
                <td *ngIf="
                isWrite == true
              ">
                 <em class="fa fa-edit" style="color:rgb(17, 69, 200)"  (click)="openModal(userModal, user)"></em>
                 </td>
                 <td *ngIf="
                 isWrite == true
               ">
                  <a
                    href="javascript:void(0)"
                    title="Delete"
                    (click)="deleteUser(user)"
                    > <em class="fa fa-trash" style="color:red"></em></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          
          <div
            class="text-right"
            style="margin: 20px 0"
            *ngIf="totalItems > itemsPerPage"
          >
            <pagination-controls
              (pageChange)="pageChanged($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #userModal let-modal>
  <div class="modal-header">
   <h5 class="modal-title" id="editProfileLabel">Edit Profile</h5>
   <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
   </button>
  </div>
  
  <div class="modal-body">
   <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
     <label for="firstname" class="col-sm-4 col-form-label">Approver mail</label>
     <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="user_mail" id="user_mail">
     </div>
    </div>
    <div class="form-group row">
     <div class="col-sm-8">
      <input type="hidden" class="form-control" formControlName="user_id" id="user_id">
     </div>
    </div>
    
    <div class="modal-footer">
     <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
     <button type="submit" class="btn btn-primary">Save changes</button>
    </div>
   </form>
  </div>
 </ng-template>