<div class="main-card mb-3 card">
  <div class="card-header">
    Influencer Settings
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-3">
          <div class="body">
            <div class="row">
              <div class="col-md-12 ml-4">
                <div class="form-group row">
                  <label for="category" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Category</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <ng-select [items]="influencerConfig.category" bindLabel="value" autofocus bindValue="_id"
                      [(ngModel)]="influencer_info.influencer_category">
                    </ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Partner</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <ng-select [items]="influencerConfig.partner" bindLabel="value" autofocus bindValue="_id"
                      [(ngModel)]="influencer_info.influencer_partner">
                    </ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="coupon_code" class="col-md-3 col-sm-12 col-xs-12 col-form-label">Platform</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <ng-select [items]="influencerConfig.platform" bindLabel="value" autofocus bindValue="_id"
                      [(ngModel)]="influencer_info.influencer_platform">
                    </ng-select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Trade discount %</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="number" placeholder="Enter Trade discount Value" name="trade_discount"
                      class="form-control" [(ngModel)]="influencer_info.trade_discount"
                      [ngModelOptions]="{ standalone: true }" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label">Partner email</label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <input type="email" placeholder="Enter Partner email" name="partner_email" class="form-control"
                      [(ngModel)]="influencer_info.partner_email" [ngModelOptions]="{ standalone: true }" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-sm-12 col-xs-12 col-form-label"> Cancel status
                  </label>
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <select class="form-control" [(ngModel)]="influencer_info.cancel_status" name="cancel_status"
                      [ngModelOptions]="{ standalone: true }">
                      <option [value]="0">No</option>
                      <option [value]="1">Yes</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>