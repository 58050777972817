<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"> </em>
        </div>
        <div>
          Coupon Lists
          <div class="page-title-subheading">All coupon lists</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Coupon</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title text-right">
            <button
              type="button"
              class="btn btn-info"
              (click)="exportAllCoupons()"
            >
              <span class="btn-icon-wrapper pr-2 opacity-7">
                <em class="pe-7s-download"></em>
              </span>
              Export All</button
            >&nbsp;&nbsp;
            <button
              type="button"
              class="btn btn-danger"
              (click)="deleteBulkCoupons()"
            >
              <span class="btn-icon-wrapper pr-2 opacity-7">
                <em class="fa fa-trash fa-w-20"></em>
              </span>
              Delete Bulk
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              class="btn btn-primary"
              (click)="createCoupon()"
            >
              <span class="btn-icon-wrapper pr-2 opacity-7">
                <em class="fa fa-plus fa-w-20"></em>
              </span>
              New Coupon
            </button>
          </h5>
          <table class="mb-0 table table-hover" aria-labelledby="Coupon">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Coupon Name</th>
                <th scope="col">Coupon Code</th>
                <th scope="col">Coupon Type</th>
                <th scope="col">Rule Type</th>
                <th scope="col">Status</th>
                <th scope="col">Total Redemption</th>
                <th scope="col">Total Limit</th>
                <th scope="col">User Limit</th>
                <th scope="col">Validity</th>
                <th scope="col">Duplicate</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    [(ngModel)]="masterSelected"
                    name="list_name"
                    (change)="checkUncheckAll()"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="filtersReqData.coupon_name"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="filtersReqData.coupon_code"
                  />
                </td>
                <td>
                  <select
                    class="form-control"
                    [(ngModel)]="filtersReqData.coupon_trade_type"
                  >
                    <option [value]=""></option>
                    <option [value]="0">Trade</option>
                    <option [value]="1">Promotion</option>
                  </select>
                </td>
                <td>
                  <select
                    class="form-control"
                    [(ngModel)]="filtersReqData.coupon_type"
                  >
                    <option [value]=""></option>
                    <option
                      *ngFor="
                        let ruleType of getAllCouponRuleTypes() | keyvalue
                      "
                      [value]="ruleType.key"
                    >
                      {{ ruleType.value }}
                    </option>
                  </select>
                </td>
                <td>
                  <select class="form-control" [(ngModel)]="filtersReqData.status">
                    <option [value]=""></option>
                    <option [value]="0">Inactive</option>
                    <option [value]="1">Active</option>
                  </select>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <input
                    type="button"
                    class="btn"
                    value="Reset"
                    (click)="clearFilter()"
                  />
                </td>
                <td>
                  <input
                    type="button"
                    class="btn btn-primary"
                    value="Submit"
                    (click)="getFilteredValue()"
                  />
                </td>
              </tr>
              <tr *ngFor="let coupon of coupons | paginate: config">
                <td>
                  <input
                    type="checkbox"
                    [(ngModel)]="coupon.isSelected"
                    name="list_name"
                    value="{{ coupon._id }}"
                    (change)="isAllSelected()"
                  />
                </td>
                <td>{{ coupon?.coupon_name }}</td>
                <th scope="row">{{ coupon.coupon_code }}</th>
                <th scope="row">
                  {{ coupon?.coupon_trade_type === 1 ? "Promotion" : "Trade" }}
                </th>
                <td>
                  {{ getCouponRuleType(coupon?.coupon_type) }}
                  {{
                    coupon?.coupon_type == 2
                      ? coupon?.bank_offer_type == 2
                        ? "(Address Mapper)"
                        : "(Card Bin)"
                      : ""
                  }}
                </td>
                <td>{{ coupon.status == 0 ? "Inactive" : "Active" }}</td>
                <td>{{ coupon.total_redemptions }}</td>
                <td>{{ coupon.total_redemption_limit }}</td>
                <td>{{ coupon.redemption_per_user }}</td>
                <td>{{ getDate(coupon.valid_to) | date: "MMM d, y" }}</td>
                <td>
                  <a
                    href="javascript:void(0)"
                    title="Edit"
                    (click)="duplicateCoupon(coupon._id)"
                    >Duplicate</a
                  >
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    title="Edit"
                    (click)="editCoupon(coupon._id)"
                    >Edit</a
                  >
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    title="Delete"
                    (click)="deleteCoupon(coupon)"
                    >Delete</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="text-right"
            style="margin: 20px 0"
            *ngIf="totalItems > itemsPerPage"
          >
            <pagination-controls
              (pageChange)="pageChanged($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
