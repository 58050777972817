<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <em class="pe-7s-drawer icon-gradient bg-happy-itmeo">
                    </em>
                </div>
                <div> Error Message Settings
                    <div class="page-title-subheading">Redemption/Validate API Eroor Message Setup 
                    </div>
                </div>
            </div>
            <div class="page-title-actions">
                <nav class="" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['']">Home</a></li>
                        <li class="breadcrumb-item">Settings</li>
                    </ol>
                </nav>
            </div>    
        </div>
    </div>            
    <div class="row">
        <div class="col-lg-12">
            <form (keydown.enter)="$event.preventDefault()" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
                <div class="main-card mb-3 card">
                    <div class="card-header">
                        Redemption/Validate API Error Message Settings 
                    </div>
                    <div class="card-body">
                        <div class="main-card mb-3 card" *ngFor="let sform of settingsForm; let i = index">
                            <div class="card-body">
                                <div class="position-relative row form-group">
                                    <div class="col-md-3">
                                        <div class="position-relative form-group">
                                            <label for="code" class="">Code</label>
                                            <input name="code" placeholder="Enter Http Code" type="text" class="form-control" [(ngModel)]="sform.code" [ngModelOptions]="{standalone: true}" [readonly]="sform.readonly" />
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="position-relative form-group">
                                                    <label for="code" class="">Default</label>
                                                    <textarea placeholder="Enter Default Message" class="form-control" [(ngModel)]="sform.msg.default" [ngModelOptions]="{standalone: true}"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="websites.length > 0">
                                            <div class="form-group row" *ngFor="let website of websites">
                                                <label for="Websites"
                                                    class="col-md-3 col-sm-12 col-xs-12 col-form-label">{{website.title}}</label>
                                                <div class="form-row col-md-9">
                                                    <ng-container *ngFor="let store of storeIds">
                                                        <div class="col-md-6" *ngIf="website.id == store.website_id">
                                                            <div class="position-relative form-group" dir="{{store.langcode == 'ar' ? 'rtl': ''}}">
                                                                <label for="exampleEmail11" class="">{{store.language}}</label>
                                                                <textarea placeholder="Enter Message in {{store.language}}"
                                                                    class="form-control" [(ngModel)]="sform.msg[store.id]" [ngModelOptions]="{standalone: true}"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-primary" type="button" (click)="addSetting()"><em class="fa fa-plus fa-w-20"></em> New Code</button>
                        </div>
                    </div>
                    <div class="d-block text-right card-footer" >
                        <button type="submit" class="btn btn-primary btn-lg" >Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
    
