import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Influencer } from '../model/influencer';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['./influencer.component.css'],
})
export class InfluencerComponent implements OnInit {
  influencers: Influencer[] = [];
  totalItems: number = 0;
  itemsPerPage: number = 20;
  config = {
    itemsPerPage: this.itemsPerPage,
    currentPage: 0,
    totalItems: this.totalItems,
  };
  idToken = '';
  isWrite: boolean = false;
  public filters: any = {};
  public influencer_id;
  public reqData: any = {};
  public validators = [];
  public influencerForm: FormGroup;
  public isValid: boolean = false;
  public button_name: string = 'Save';
  public processed: boolean = false;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.influencerForm = this.formBuilder.group({
      type: [0, Validators.required],
      value: ['', Validators.pattern('^[^,]+$')],
      influencer_id: [''],
    });
    this.loader.show();
    this.idToken = await this.authService.GetToken();
    let page = 1;
    if (this.filters['page'] !== undefined) {
      page = parseInt(this.filters['page']);
    }
    this.apiService
      .getAllInfluencerData(this.reqData, this.filters, this.idToken)
      .subscribe((data: Influencer[]) => {
        this.loader.hide();
        if (data['code'] == 200 || data['code'] == 202) {
          console.log(data);
          this.isWrite = data['isWrite'];
          this.influencers = data['data'];
          this.totalItems = data['total'];
          this.config = {
            itemsPerPage: this.itemsPerPage,
            currentPage: page,
            totalItems: this.totalItems,
          };
        }
      });
  }
  getFilteredValue() {
    this.filters['page'] = 1;
    this.ngOnInit();
  }
  clearFilter() {
    this.filters = {};
    this.ngOnInit();
  }
  pageChanged(event) {
    this.config.currentPage = event;
    this.filters['page'] = event;
    this.ngOnInit();
  }
  getDate(value: any) {
    return this.apiService.getDate(value);
  }
  async onSubmit() {
    const influencerdata = this.influencerForm.getRawValue();
    if (influencerdata.type === '' || influencerdata.value === '') {
      this.toastrService.error('Type or Value can not be blank');
    } else if (influencerdata.value.indexOf(',') > -1) {
      this.toastrService.error('Value cannot contain comma');
    } else {
      influencerdata.type = Number(influencerdata.type);
      this.isValid = true;
    }

    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    if (this.isValid) {
      this.apiService
        .createInfluencerOptions(influencerdata, userToken)
        .subscribe(async (data) => {
          console.log('gfeg', data);
          if (data.code == 200) {
            this.toastrService.success(data.message);
            this.loader.hide();
            this.modalService.dismissAll();
            this.router.navigate(['influencer']);
          } else {
            this.modalService.dismissAll();
            this.router.navigate(['influencer']);
            this.toastrService.error(data.message);
          }
        });
    }
  }
  openModal(targetModal, influencerData) {
    this.modalService.open(targetModal, {
      backdrop: 'static',
    });
    if (Object.keys(influencerData).length === 0) {
      this.influencerForm.patchValue({
        type: '',
        value: '',
        influencer_id: '',
      });
    } else {
      this.influencerForm.patchValue({
        type: influencerData.type,
        value: influencerData.value,
        influencer_id: influencerData._id,
      });
    }
  }
  get formValue() {
    return this.influencerForm?.controls;
  }
  deleteInfluencerOptions(influencerData): void {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this influencer option?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.loader.show();
        this.apiService
          .deleteInfluencerOptions(influencerData._id, userToken)
          .subscribe((data: Influencer) => {
            if (data['code'] == 200) {
              let index = this.influencers.findIndex(
                (c) => c._id == influencerData._id
              );
              this.influencers.splice(index, 1);
              this.loader.hide();
              this.toastrService.success(data['message']);
            } else {
              this.loader.hide();
              this.toastrService.error(data['message']);
            }
          });
      }
    });
  }
}
