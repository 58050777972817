import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Coupon } from '../model/coupon';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  public dashboardData: any = {
    total_active_coupon: 0,
    total_coupon: 0,
    total_redemption: 0,
    promo_threshold: 0,
    threshold_reached: false,
    coupon: [],
    redemption: [],
    warn_dis_value_coupon: [],
  };
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private loader: NgxSpinnerService
  ) {}

  async ngOnInit() {
    this.loader.show();
    let token = await this.authService.GetToken();
    this.apiService.getDashboardData(token).subscribe((data: Coupon) => {
      if (data.code == 200) {
        this.dashboardData = data['data'];
        if (this.dashboardData.threshold_reached) {
          Swal.fire({
            title: 'Promo Alert?',
            html: `Promo coupon total: <b>${this.dashboardData.total_coupon}.</b>
              </br> Is reaching threshold limit <b>${this.dashboardData.promo_threshold}</b>. 
              </br>Please remove the unused coupons`,
            icon: 'warning',
          });
        }
      }
      this.loader.hide();
    });
  }
  getDate(value: any) {
    return this.apiService.getDate(value);
  }
}
