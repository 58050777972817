<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>
          Redeem History
          <div class="page-title-subheading">All redeem history</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Redeem History</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Redeem History</h5>
          <table class="mb-0 table table-hover" aria-labelledby="redeemHistory">
            <thead>
              <tr>
                <th scope="col" class="width-15">Coupon Code</th>
                <th scope="col">Customer</th>
                <th scope="col">Order Id</th>
                <th scope="col">Status</th>
                <th scope="col">Tracking ID</th>
                <th scope="col">
                  Date (UAE Time Stamp)<em
                    class="fa fa-info-circle"
                    title="Correct Timestamp after 24 Sept"
                  ></em>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="reqData.coupon_code"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="reqData.user_id"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="reqData.order_id"
                  />
                </td>
                <td>
                  <select
                    class="form-control"
                    [(ngModel)]="reqData.redeem_status"
                  >
                    <option [value]=""></option>
                    <option [value]="0">Failure</option>
                    <option [value]="1">Success</option>
                  </select>
                </td>
                <td></td>
                <td>
                  <input
                    type="button"
                    class="btn"
                    value="Reset"
                    (click)="clearFilter()"
                  />
                  &nbsp;&nbsp;<input
                    type="button"
                    class="btn btn-primary"
                    value="Submit"
                    (click)="getFilteredValue()"
                  />
                </td>
              </tr>
              <tr *ngFor="let redeem of redeems | paginate: config">
                <th scope="row">{{ redeem.coupon_code }}</th>
                <td>{{ redeem.user_id }}</td>
                <th scope="row">{{ redeem.order_id }}</th>
                <td>
                  <span>{{
                    redeem.redeem_status == 1 ? "Success" : "Failure"
                  }}</span>
                </td>
                <th scope="row">{{ redeem.tracking_id }}</th>
                <td>
                  {{ redeem.redeem_date }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="text-right"
            style="margin: 20px 0"
            *ngIf="totalItems > itemsPerPage"
          >
            <pagination-controls
              (pageChange)="pageChanged($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
