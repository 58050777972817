import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private router: Router,
    public apiService: ApiService
  ) {
    // if (!this.apiService.location) {
    //   // const locations = this.apiService.locations;
    //   console.log(this.apiService.locations[0].value);
    //   this.apiService.location = this.apiService.locations[0].value;
    // }
  }

  ngOnInit(): void {
    
    const userVal = this.authService.getItemData();
    let loggedInUser = this.authService.getJsonCookie('styli_sso_user');
    if (userVal===null && loggedInUser===null) {
      this.router.navigate(['login']);
    }
    if (this.authService.isLoggedIn) {
      this.router.navigate(['dashboard']);
    }
  }

}
