<div class="card-body">
    <div
      class="row"
      *ngFor="let coupon_cond of coupon_conditions; let k = index"
    >
      <div class="col-md-12" *ngIf="coupon_cond.conditions.length > 0">
        <div class="card mt-10">
          <div class="card-header">{{ coupon_cond.clause_text }}:</div>
          <div class="row card-body">
            <div
              class="col-sm-12"
              *ngFor="
                let condition of coupon_cond.conditions;
                let i = index
              "
            >
              <div class="form-group row">
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    [(ngModel)]="condition.entity"
                    name="entity_{{ condition.id }}"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option
                      *ngFor="let entity of entities"
                      [value]="entity.value"
                    >
                      {{ entity.text }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-2">
                  <select
                    class="form-control"
                    [(ngModel)]="condition.conjuction"
                    name="conjuction_{{ condition.id }}_{{ k }}"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option
                      *ngFor="let conjuction of conjuctions"
                      [value]="conjuction"
                    >
                      {{ conjuction }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="condition.attributes"
                    [ngModelOptions]="{ standalone: true }"
                    name="attributes_{{ condition.id }}_{{ k }}"
                    (focus)="
                      showCatLists(k, i, condition.attributes, template)
                    "
                    *ngIf="
                      coupon_conditions[k].conditions[i].entity ==
                      'categoryId'
                    "
                  />
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="condition.attributes"
                    [ngModelOptions]="{ standalone: true }"
                    name="attributes_{{ condition.id }}_{{ k }}"
                    *ngIf="
                      coupon_conditions[k].conditions[i].entity !=
                      'categoryId'
                    "
                  />
                </div>

                <div class="col-sm-2">
                  <a
                    href="javascript:void(0);"
                    (click)="removeCondition(i, k)"
                    ><em class="pe-7s-trash"></em>&nbsp;Remove</a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-12 text-center">
              <a
                href="javascript:void(0);"
                (click)="addCondition(k)"
                class="btn btn-link"
                >--or--</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #template>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Choose Category</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="modalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body choose_cat_modal">
        <div id="treeparent">
          <ejs-treeview
            #tree
            id="treeelement"
            [fields]="field"
            [showCheckBox]="showCheckBox"
            (nodeChecked)="nodeChecked($event)"
            [checkedNodes]="checkedNodes"
          >
          </ejs-treeview>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="modalRef.hide(); getCheckedNodes(tree)"
        >
          Choose
        </button>
      </div>
    </ng-template>
    <div class="row mt-20">
      <div class="col-sm-12 text-center">
        <a
          href="javascript:void(0);"
          (click)="addNewClause('must')"
          class="mb-2 mr-2 btn-transition btn btn-outline-primary"
          >Must</a
        >&nbsp;
        <a
          href="javascript:void(0);"
          (click)="addNewClause('must_not')"
          class="mb-2 mr-2 btn-transition btn btn-outline-primary"
          >Must not</a
        >
      </div>
    </div>
  </div>