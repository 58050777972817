import { Injectable, NgZone, Inject } from '@angular/core';
import { User } from './user';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  // AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, interval } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  userToken: any;
  itemData: any;
  currentURL: any;
  private authServiceUrl;
  private apiNjServer;
  private domainName: string;
  constructor(
    @Inject('environment')
    environment,
    private httpClient: HttpClient,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private toastrService: ToastrService,
    private loader: NgxSpinnerService
  ) {
    this.apiNjServer = environment.apiNjServer.toString().split('//')[1];
    this.authServiceUrl = environment.authServiceUrl;
    const subdomain = window.location.hostname;
    const subdomainParts = subdomain.split('.');
    this.domainName = subdomainParts.slice(-2).join('.');
    console.log(this.domainName);
    let loggedInUser = this.getJsonCookie('styli_sso_user');
    let currentUrl: any = window.location.pathname
    if (!loggedInUser && currentUrl !== '/verify-otp') {
      this.router.navigate(['login']);
    } else {
      // Call the regenerateToken method initially
      this.regenerateToken();

      // Set up a 10-minute interval to call regenerateToken
      interval(10 * 60 * 1000).subscribe(() => {
        this.regenerateToken();
      });
    }
  }

  //regenerate-token
  async regenerateToken() {
    const loggedInUser = this.getJsonCookie('styli_sso_user');
    if (loggedInUser) {
      const { refreshToken, token } = loggedInUser;
      this.httpClient
        .post(
          this.authServiceUrl + '/regenerate-token',
          {
            refreshToken,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .subscribe(
          (result: any) => {
            if (result.status) {
              this.userData = result.data;
              this.setJsonCookie('styli_sso_user', this.userData, 0, this.domainName);
              let userSavedData = this.getJsonCookie('styli_sso_user');
              if (!this.validAccount(userSavedData.email)) {
                this.SignOut();
                this.toastrService.error('Only stylishop user can access this');
              } else {
                localStorage.setItem('userToken', userSavedData?.token);
              }
            } else {
              this.SignOut();
              this.getJsonCookie('styli_sso_user');
            }
          },
          (error) => {
            console.log('error', error.error);
            this.SignOut();
            this.loader.hide();
            this.getJsonCookie('styli_sso_user');
          }
        );
    }
  }
  // Sign in with email/password
  async SignIn(email, password) {
    this.loader.show();
    this.httpClient
      .post(
        this.authServiceUrl + '/login',
        {
          email,
          password,
          domain: this.apiNjServer
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .subscribe((loginUser: any) => {
        console.log('loginUser', loginUser);
        if (loginUser?.status) {
          const verifyOtpStatus = loginUser?.data?.message;
          if (!verifyOtpStatus) {
            this.setCurrentUser(loginUser.data);
            this.router.navigate(['dashboard']);
            this.loader.hide();
          } else {
            localStorage.setItem('loginSession', JSON.stringify(email));
            this.router.navigate(['verify-otp']);
            this.loader.hide();
          }
        } else {
          this.loader.hide();
          this.removeFromCookie();
          this.toastrService.error('Invalid login details');
        }
      });
  }

  async verifyOTP(otp: string) {
    const emailData = this.getEmail();
    console.log(emailData);

    this.loader.show();
    this.httpClient
      .post(
        this.authServiceUrl + '/verify-otp',
        {
          email: emailData,
          otp,
          domain: this.apiNjServer
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .subscribe((verifyOtp: any) => {
        console.log('verifyOtp', verifyOtp);
        if (verifyOtp?.status === true) {
          const userVal = verifyOtp?.data?.userData;
          localStorage.setItem('loginSession', 'true');
          this.SetUserData(userVal);
          this.setJsonCookie('styli_sso_user', userVal, 0, this.domainName);
          localStorage.setItem('userToken', verifyOtp?.data?.userData?.token.toString());
          this.loader.hide();
          location.reload();
          localStorage.removeItem('loginSession');
        } else {
          console.log("Error message:", verifyOtp?.data?.message);
          this.toastrService.error(verifyOtp?.data?.message || 'An error occurred');
          this.removeFromCookie();
          localStorage.removeItem('userToken');
          this.loader.hide();
          location.reload();
        }
      }, (error) => {
        console.error('HTTP error:', error?.error?.data?.message);
        this.toastrService.error(error?.error?.data?.message);
        this.loader.hide();
      });
  }



  get isLoggedIn(): boolean {
    const user = this.getJsonCookie('styli_sso_user');
    return user !== null ? true : false;
  }

  validAccount(userEmail) {
    return (
      userEmail.split('@')[1] == 'stylishop.com' ||
      userEmail.split('@')[1] == 'landmarkgroup.in' ||
      userEmail.split('@')[1] == 'landmarkgroup.com'
    );
  }
  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user?.providerData?.[0]?.displayName,
      name: user?.providerData?.[0]?.displayName,
      photoURL: user?.providerData?.[0]?.photoURL,
      emailVerified: user.emailVerified,
      token: user?.stsTokenManager?.accessToken,
    };
    return userData;
  }

  // Sign out
  SignOut() {
    const loggedInUser = this.getJsonCookie('styli_sso_user');
    const { uuid, token } = loggedInUser;
    this.httpClient
      .post(
        this.authServiceUrl + '/logout',
        {
          uuid,
        },
        {
          headers: this.reqHeader(token),
        }
      )
      .subscribe((result: any) => {
        console.log('logoutStatus', result);
        if (result?.status) {
          this.removeFromCookie();
          localStorage.removeItem('userToken');
          this.router.navigate(['login']);
        }
      });
  }
  GetToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const user = this.getJsonCookie('styli_sso_user');
      if (user) {
        let token = user?.token;
        localStorage.setItem('userToken', token);
        resolve(token);
      }
    });
  }
  reqHeader(token = '') {
    const user = this.getJsonCookie('styli_sso_user');
    token = user?.token || token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token.trim(),
    });
    return header;
  }
  setCurrentUser(user) {
    const cookieName = 'styli_sso_user';
    try {
      if (user) {
        this.setJsonCookie(cookieName, user, 0, this.domainName);
      } else {
        localStorage.removeItem('styli_sso_user');
        this.removeFromCookie();
        document.cookie = `${cookieName}=; domain=${this.domainName}; path=/;`;
      }
    } catch (error) {
      console.log('>>>>: setCurrentUser -> error', error);
    }
  }

  setJsonCookie(cookieName, jsonObject, expirationDays, domain) {
    const jsonString = JSON.stringify(jsonObject);

    let cookieString = `${cookieName}=${encodeURIComponent(jsonString)}; path=/;`;

    if (expirationDays) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + expirationDays);
      cookieString += `; expires=${expirationDate.toUTCString()}`;
    }

    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    document.cookie = cookieString;
  }

  removeFromCookie() {
    const cookieName = 'styli_sso_user';
    document.cookie = `${cookieName}=; domain=${this.domainName}; path=/;`;
  }

  getJsonCookie(cookieName) {
    const cookieValue = this.getCookie(cookieName, this.domainName);
    if (cookieValue && cookieValue !== 'undefined') {
      try {
        // Parse the JSON value from the cookie
        return JSON.parse(decodeURIComponent(cookieValue));
      } catch (error) {
        // Handle parsing errors if necessary
        console.error('Error parsing JSON from cookie:', error);
      }
    }

    return null; // Return null if the cookie or JSON value doesn't exist
  }

  getEmail() {
    const itemData = localStorage.getItem('loginSession');
    return JSON.parse(itemData);
  }

  getItemData() {
    const itemData = localStorage.getItem('loginSession');
    return JSON.parse(itemData);
  }

  getToken() {
    const itemData = localStorage.getItem('item');
    const token = (JSON.parse(itemData)).token;
    return token;
  }

  getCookie(cookieName, domain) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // Check if the cookie starts with the specified name
      if (cookie.indexOf(cookieName + '=') === 0) {
        // Check if the cookie domain matches the specified domain
        if (domain && cookie.indexOf('domain=' + domain) === -1) {
          return cookie.substring(cookieName.length + 1);
        }
      }
    }
    return null;
  }
}
