<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>
          <div class="page-title-subheading"></div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['/coupon/']"
                >Approve Coupon</a
              >
            </li>
            <li class="active breadcrumb-item" aria-current="page">
              Approve Coupon
            </li>
          </ol>
        </nav>
        <br />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      
    </div>
  </div>
</div>
