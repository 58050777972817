<div class="app-container" *ngIf="!authService.isLoggedIn">
  <div class="h-100">
    <div class="d-flex h-100 justify-content-center align-items-center">
      <div class="mx-auto app-login-box col-md-8">
        <div class="app-logo-inverse mx-auto mb-3"></div>
        <div class="modal-dialog w-100 mx-auto">
          <div class="modal-content">
            <div class="modal-body">
              <div class="h5 modal-title text-center">
                <h4 class="mt-2">
                  <div>Sign in</div>
                </h4>
              </div>
              <div class="form-row">
                <div class="col-md-12">
                  <div class="position-relative form-group">
                    <input name="email" placeholder="Email here..." type="email" class="form-control" #userName
                      required>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="position-relative form-group">
                    <input name="password" placeholder="Password here..." type="password" class="form-control"
                      #userPassword required>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="position-relative form-group">
                    <select class="form-control" name="region" #regionName required
                      (change)="changeLocation(regionName.value)">
                      <option *ngFor="let location of this.apiService.locations" [value]="location.value"
                        [selected]="this.apiService.location === location.value">
                        {{location.label}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div class="modal-footer clearfix">

              <div class="float-left">
                <button class="btn btn-primary"
                  (click)="authService.SignIn(userName.value, userPassword.value)">Login</button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center text-black opacity-8 mt-3">Copyright © Styli</div>
      </div>
    </div>
  </div>
</div>