<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>Free Shipping Config</div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Free Shipping Config</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <table *ngIf="records; else elseBlock" class="mb-0 table table-hover rounded" aria-labelledby="influencer"
            style="border: black solid; border-radius: 1rem">
            <thead style="background-color: #c1bebb">
              <tr>
                <th>Configuration</th>
                <th>Country</th>
                <th>Threshold</th>
                <th>Status</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody *ngIf="adminConfigForm" [formGroup]="adminConfigForm">
              <tr *ngFor="let children of records; let i = index">
                <ng-container [formGroupName]="'group' + i">
                  <td><b>Free Shipping for all users </b></td>
                  <td>
                    <b>{{ children.websiteCode?.toUpperCase() }}</b>
                  </td>
                  <td class="w-12rem">
                    <input formControlName="threshold" type="number" placeholder="Null" class="form-control w-50"
                      (keydown)="handleKeydown($event, i)" />
                    <div class="text-warning">In Local Currency</div>
                  </td>
                  <td>
                    <label class="form-check-label ml-4 mb-2" (click)="changeStatusType(i, '0')">
                      <input type="radio" class="form-check-input" [checked]="children.statusType === '0'"
                        (change)="onConfigChange(i)" />
                      Normal
                    </label>
                    <label class="form-check-label ml-5 mb-2" (click)="changeStatusType(i, '1')">
                      <input type="radio" class="form-check-input" [checked]="children.statusType === '1'"
                        (change)="onConfigChange(i)" />
                      Schedule
                    </label>
                    <div *ngIf="children.statusType === '1'" class="d-flex flex-column gap-half">
                      <div class="d-flex gap-half">
                        <div style="width: 140px; padding-top: 10px;">Start Time:</div>
                        <input placeholder="Enter Start Date" formControlName="startDate" class="form-control pointer"
                          bsDatepicker [bsConfig]="{
                            dateInputFormat: 'Do MMMM YYYY',
                            showWeekNumbers: false,
                            containerClass: 'theme-dark-blue',
                            minDate: today
                          }" (bsValueChange)="onStartDateChange(i)" />
                        <select class="custom-select pointer w-50" #validFromTime formControlName="startTime"
                          name="valid_from_time" (change)="onConfigChange(i)">
                          <option *ngFor="let time of timeListFrom" [value]="time.value">
                            {{ time.text }}
                          </option>
                        </select>
                      </div>
                      <div class="d-flex gap-half">
                        <div style="width: 140px; padding-top: 10px;">End Time: </div>
                        <input placeholder="Enter Expiry Date" class="form-control pointer" formControlName="expiryDate"
                          bsDatepicker [bsConfig]="{
                            dateInputFormat: 'Do MMMM YYYY',
                            minDate: minDates[i],
                            showWeekNumbers: false,
                            containerClass: 'theme-dark-blue'
                          }" (bsValueChange)="onEndDateChange(i)" />
                        <select class="custom-select pointer w-50" #validToTime formControlName="expiryTime"
                          name="valid_to_time" (change)="onConfigChange(i)">
                          <option *ngFor="let time of timeListTo" [value]="time.value">
                            {{ time.text }}
                          </option>
                        </select>
                      </div>
                      <label class="text-warning">In Local Time of: {{ children.websiteCode?.toUpperCase() }}</label>
                    </div>
                    <div *ngIf="children.statusType === '0'">
                      <label class="switch">
                        <input type="checkbox" data-toggle="toggle" data-size="normal"
                          formControlName="freeShippingEnabled" (change)="onConfigChange(i)" />
                        <span class="slider round"></span>
                      </label>
                      <div class="text-warning">Will reset the scheduler time and impact immediately</div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column gap-half">
                      <input type="button" class="btn btn-primary" [value]="'Update'" [disabled]="!formValueChanged[i]"
                        (click)="updateChanges(i)" />
                      <!-- <div>
                        <b [ngStyle]="{
                            color: adminConfigForm.get([
                              'group' + i,
                              'freeShippingEnabled'
                            ]).value
                              ? 'green'
                              : 'red'
                          }"><strong>Status: {{
                            adminConfigForm.get([
                            "group" + i,
                            "freeShippingEnabledLive"
                            ]).value
                            ? "Active"
                            : "Inactive"
                            }}</strong></b>
                      </div> -->
                    </div>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <!-- This will be shown if condition is false -->
  <p>Empty Data Or Api Issue</p>
</ng-template>