import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Coupon } from './../model/coupon';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css'],
})
export class CouponComponent implements OnInit {
  totalItems: number = 0;
  itemsPerPage: number = 20;
  idToken = '';
  config = {
    itemsPerPage: this.itemsPerPage,
    currentPage: 0,
    totalItems: this.totalItems,
  };
  coupons: Coupon[] = [];
  public filters: any = {};
  public filtersReqData: any = {};
  masterSelected: boolean;
  checkedList = [];
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private loader: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}

  async ngOnInit() {
    this.loader.show();
    let page = 1;
    if (this.filters['page'] !== undefined) {
      page = parseInt(this.filters['page']);
    }
    this.idToken = await this.authService.GetToken();
    this.apiService
      .getAllCoupons(this.filtersReqData,this.filters,this.idToken)
      .subscribe((data: Coupon[]) => {
        let allcoupon = [];
        data['data'].forEach((cdata) => {
          let sdata = cdata;
          sdata['isSelected'] = false;
          allcoupon.push(sdata);
        });
        this.coupons = allcoupon;
        this.loader.hide();
        this.totalItems = data['total'];
        this.config = {
          itemsPerPage: this.itemsPerPage,
          currentPage: page,
          totalItems: this.totalItems,
        };
      });
  }
  getDate(value: any) {
    return this.apiService.getDate(value);
  }
  getFilteredValue() {
    this.filters['page'] = 1;
    this.ngOnInit();
  }
  clearFilter() {
    this.filters = {};
    this.ngOnInit();
  }
  pageChanged(event) {
    this.config.currentPage = event;
    this.filters['page'] = event;
    this.ngOnInit();
  }
  createCoupon(): void {
    this.router.navigate(['coupon/create']);
  }
  editCoupon(coupon_id): void {
    this.router.navigate(['coupon/edit/' + coupon_id]);
  }
  duplicateCoupon(coupon_id): void {
    this.router.navigate(['coupon/duplicate/' + coupon_id]);
  }
  deleteCoupon(coupon): void {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this coupon?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.loader.show();
        this.apiService
          .deleteCoupon(coupon._id, userToken)
          .subscribe((data: Coupon) => {
            if (data.code == 200) {
              let index = this.coupons.findIndex((c) => c._id == coupon._id);
              this.coupons.splice(index, 1);
              this.loader.hide();
              this.toastrService.success(data.message);
            } else {
              this.loader.hide();
              this.toastrService.error(data.message);
            }
          });
      }
    });
  }
  exportAllCoupons() {
    let userToken =
      localStorage.getItem('userToken').trim() != ''
        ? localStorage.getItem('userToken').trim()
        : this.idToken;
    let couponData = [];
    this.loader.show();
    this.apiService.exportCoupon(userToken).subscribe((data: Coupon) => {
      if (data.code == 200) {
        couponData = data.data;
        this.apiService.downloadFile(couponData, 'coupon_data', [
          'coupon_name',
          'coupon_code',
          'coupon_type',
          'coupon_rule_type',
          'visibility',
          'website',
          'priority',
          'created_at',
          'status',
          'valid_from',
          'valid_to',
          'valid_from_time',
          'valid_to_time',
          'uses_per_coupon',
          'uses_per_customer',
          'total_redemptions',
          'discount_details',
          'price_slabs',
          'discount_type',
          'discount_amount',
          'different_discount_for_discounted_product',
          'cuttoff_for_different_discount',
          'discount_for_discounted_product',
          'discount_limit',
          'discount_details_new_user',
          'discount_amount_new_user',
          'different_discount_for_new_user',
          'cuttoff_for_different_discount_new_user',
          'discount_for_discounted_product_new_user',
          'discount_limit_new_user'
        ]);
        this.loader.hide();
      } else {
        this.loader.hide();
        this.toastrService.error(data.message);
      }
    });
  }
  getCouponRuleType(type = 0) {
    const ruleTypes = this.apiService.couponRuleType;
    return ruleTypes[type];
  }
  getAllCouponRuleTypes() {
    return this.apiService.couponRuleType;
  }
  checkUncheckAll() {
    for (let i = 0; i < this.coupons.length; i++) {
      this.coupons[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.coupons.every(function (item: any) {
      return item.isSelected == true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.coupons.length; i++) {
      if (this.coupons[i].isSelected)
        this.checkedList.push(this.coupons[i]._id);
    }
  }
  deleteBulkCoupons() {
    if (this.checkedList.length > 0) {
      let userToken =
        localStorage.getItem('userToken').trim() != ''
          ? localStorage.getItem('userToken').trim()
          : this.idToken;
      let postdata = {
        checkedList: this.checkedList,
      };
      Swal.fire({
        title: 'Are you sure?',
        text:
          'Are you sure you want to delete these ' +
          this.checkedList.length +
          ' coupons?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.loader.show();
          this.apiService
            .deleteBulkCoupon(postdata, userToken)
            .subscribe((data) => {
              if (data.code == 200) {
                this.checkedList.forEach((deletedData) => {
                  let index = this.coupons.findIndex(
                    (c) => c._id == deletedData
                  );
                  this.coupons.splice(index, 1);
                });
                this.masterSelected = false;
                this.toastrService.success(data.message);
                this.ngOnInit();
              } else if (data.code == 206) {
                this.toastrService.error(data.message);
              } else {
                this.toastrService.error(data.message);
              }
              this.loader.hide();
            });
        }
      });
    } else {
      this.toastrService.error('No coupon is selected!!!');
    }
  }

}
