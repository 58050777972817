<div class="app-main__inner">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <em class="pe-7s-drawer icon-gradient bg-happy-itmeo"></em>
        </div>
        <div>
          Activity Log
          <div class="page-title-subheading">Coupon all activity logs</div>
        </div>
      </div>
      <div class="page-title-actions">
        <nav class="" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" [routerLink]="['']">Home</a>
            </li>
            <li class="breadcrumb-item">Activity Log</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-body">
          <h5 class="card-title">Activity Log</h5>
          <table class="mb-0 table table-hover" aria-labelledby="import">
            <thead>
              <tr>
                <th scope="col">Coupon</th>
                <th scope="col">Method</th>
                <th scope="col">Changed By</th>
                <th scope="col">
                  Changed On (UAE Time Stamp)<em
                    class="fa fa-info-circle"
                    title="Correct Timestamp after 24 Sept"
                  ></em>
                </th>
                <th scope="col">Bulk Action</th>
                <th scope="col" class="width-20">Changed Data</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="filtersReqData.coupon"
                  />
                </td>
                <td>
                  <select
                    class="form-control"
                    [(ngModel)]="filtersReqData.method"
                  >
                    <option [value]=""></option>
                    <option *ngFor="let method of methods" [value]="method">
                      {{ method }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="filtersReqData.user_email"
                  />
                </td>
                <td></td>
                <td></td>
                <td class="width-20 text-right">
                  <input
                    type="button"
                    class="btn"
                    value="Reset"
                    (click)="clearFilter()"
                  />
                  &nbsp;<input
                    type="button"
                    class="btn btn-primary"
                    value="Submit"
                    (click)="getFilteredValue()"
                  />
                </td>
              </tr>
              <tr *ngFor="let log of logs | paginate: config">
                <th scope="row">{{ log.coupon }}</th>
                <td>{{ log?.method }}</td>
                <td>{{ log?.user_email }}</td>
                <td>
                  {{ log.created_at }}
                </td>
                <td>{{ log?.bulk ? "Yes" : "No" }}</td>
                <ng-container *ngIf="log?.method == 'Update'">
                  <td [innerHTML]="log.diff"></td>
                </ng-container>
                <ng-container *ngIf="log?.method != 'Update'">
                  <td></td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <div
            class="text-right"
            style="margin: 20px 0"
            *ngIf="totalItems > itemsPerPage"
          >
            <pagination-controls
              (pageChange)="pageChanged($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
