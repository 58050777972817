import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Coupon } from './../model/coupon';
import { NgxSpinnerService } from 'ngx-spinner';
import { create, formatters } from 'jsondiffpatch';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.css'],
})
export class ActivitylogComponent implements OnInit {
  logs: Coupon[] = [];
  difftest = '';
  public filters: any = [];
  public filtersReqData:any = {};
  totalItems: number = 0;
  itemsPerPage: number = 20;
  config = {
    itemsPerPage: this.itemsPerPage,
    currentPage: 0,
    totalItems: this.totalItems,
  };
  methods: any = ['Create', 'Update', 'Delete','Approve'];
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private loader: NgxSpinnerService
  ) {}

  async ngOnInit() {
    create({
      objectHash: function (obj) {
        return obj.name;
      },
    });
    this.loader.show();
    let page = 1;
    if (this.filters['page'] !== undefined) {
      page = parseInt(this.filters['page']);
    }
    let token = await this.authService.GetToken();
    this.apiService
      .getActivityLogs(this.filtersReqData,this.filters, token)
      .subscribe((data: Coupon[]) => {
        let activityLogs = [];
        let i = 0;
        let logdata = data['data'];
        logdata.forEach((x) => {
          // console.log(x);
          activityLogs[i] = x;
          activityLogs[i]['diff'] = '';

          if (x.method == 'Update') {
            if(x.diff_data != '')
            {
              activityLogs[i]['diff'] =  JSON.stringify(x.diff_data, null, '\t');
            }
        }

          i++;
        });
        this.logs = activityLogs;
        this.totalItems = data['total'];
        this.config = {
          itemsPerPage: this.itemsPerPage,
          currentPage: page,
          totalItems: this.totalItems,
        };
        this.loader.hide();
      });
  }
  getDate(value: any) {
    return this.apiService.getDate(value);
  }
  pageChanged(event) {
    this.config.currentPage = event;
    this.filters['page'] = event;
    this.ngOnInit();
  }
  getFilteredValue() {
    this.filters['page'] = 1;
    this.ngOnInit();
  }
  clearFilter() {
    this.filters = {};
    this.ngOnInit();
  }
}
